// TODO: This component is meant for a demo on 08/10/2020. Remove after demo
import React, { Fragment, useState } from 'react'
import logo from '../assets/medtronic-demo-logo.png'
import productImg from '../assets/medtronic-demo-product.jpg'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import ServiceForm from './MedtronicsDemoServiceForm'
import RegisterForm from './MedtronicsDemoRegisterForm'

const serialNumber = '11041532'
export const medtronicBlue = '#004b87'
export const green = '#639e4d'
const verticalMarginTop = '20px'
export const fontSize = '13px'
const dividerStyle = { margin: '10px auto 10px auto', width: '90%', display: 'flex' }
const buttonPadding = '6px'

const Banner = () => (
  <div style={{ backgroundColor: medtronicBlue, width: '100%', height: '75px' }}>
    <img src={logo} alt='Medtronic Logo' />
  </div>
)

const MedtronicsDemo = ({ data = {} }) => {
  const [showServiceForm, setShowServiceForm] = useState(false)
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const { asset = {} } = data
  const { product = {}, assetId } = asset
  const { propertiesMap = {} } = product
  const modelNumber = propertiesMap['Catalog #'] || '4096600'

  const handleSnackBarClose = () => {
    setSnackbarOpen(false)
    setSnackbarMsg('')
  }

  const handleSnackBarOpen = (open, msg = '') => {
    setSnackbarMsg(msg)
    setSnackbarOpen(open)
  }

  return (
    <Fragment>
      <Banner />
      {showServiceForm && (
        <ServiceForm
          assetId={assetId}
          serial={serialNumber}
          model={modelNumber}
          setShowForm={setShowServiceForm}
          setSnackbarOpen={handleSnackBarOpen}
        />
      )}
      {showRegisterForm && (
        <RegisterForm
          assetId={assetId}
          serial={serialNumber}
          model={modelNumber}
          setShowForm={setShowRegisterForm}
          setSnackbarOpen={handleSnackBarOpen}
        />
      )}
      {!showServiceForm && !showRegisterForm && (
        <div style={{ width: '95%', margin: '20px auto auto auto', fontSize }}>
          <img
            src={productImg}
            alt='Ventilator'
            style={{
              borderRadius: '10px',
              boxShadow: '1px 1px 8px 0px',
              width: '80%',
              margin: 'auto',
              display: 'flex',
            }}
          />
          <div style={{ textAlign: 'center', marginTop: verticalMarginTop }}>
            <Button
              variant='contained'
              style={{
                backgroundColor: medtronicBlue,
                color: '#fff',
                fontSize,
                marginRight: '10px',
                padding: buttonPadding,
              }}
              onClick={() => setShowServiceForm(true)}
            >
              Request Service
            </Button>
            <Button
              variant='contained'
              style={{ backgroundColor: green, color: '#fff', fontSize, padding: buttonPadding }}
              onClick={() => setShowRegisterForm(true)}
            >
              Register Product
            </Button>
          </div>
          <Grid container spacing={3} style={{ marginTop: verticalMarginTop }}>
            <Grid item xs={3}>
              <span style={{ float: 'right' }}>
                <strong>Name</strong>
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>{product.name}</span>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <span style={{ float: 'right' }}>
                <strong>Model #</strong>
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>{modelNumber}</span>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <span style={{ float: 'right' }}>
                <strong>Serial #</strong>
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>{serialNumber}</span>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />
        </div>
      )}
      {snackbarOpen && (
        <Alert
          style={{ width: '80%', margin: '5px auto 10px auto', display: 'flex' }}
          variant='filled'
          severity='success'
          onClose={handleSnackBarClose}
        >
          {snackbarMsg}
        </Alert>
      )}
    </Fragment>
  )
}

export default MedtronicsDemo
