import CustomForm from '../Tools/FormBuilder/CustomForm'
import VideoPlaylist from '../Tools/lib/VideoPlaylist'
import PictureViewer from '../Tools/lib/PictureViewer'
import ActionPage from '../Tools/ActionPageBuilder/ActionPage'

export default {
  CustomForm,
  VideoPlaylist,
  PictureList: PictureViewer,
  PageBuilder: ActionPage,
}
