import React from 'react'
import PropTypes from 'prop-types'
import RegistrationForm from '../../components/RegistrationForm'
import ConnectPage from '../../components/ConnectPageV1'
import extractAssetData from '../../utils/extractAssetData'
import { registerAsset } from '../../api'
import Instructions from './Instructions'
import { useMediaQuery } from 'react-responsive'
import _capitalize from 'lodash/capitalize'

const Jabra = ({ data, isValid }) => {
  const {
    name: assetName,
    data: assetData,
    assetId,
    location: { latitude, longitude },
  } = extractAssetData({
    data,
    keys: ['Serial #', 'Item #', 'Model #', 'Dimensions', 'Weight'],
  })

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const props = {
    forms: {
      registration: {
        Form: RegistrationForm,
        onSubmit: ({ name, email }) => {
          const propertiesMap = {
            'Scan Date/Time': new Date().toString(),
            'Scan Lat/Long': `${latitude}, ${longitude}`,
            'Product Name': assetName,
          }
          Object.keys(assetData).forEach(key => {
            const { name, description } = assetData[key]
            propertiesMap[_capitalize(name)] = description
          })
          // TODO: determine if we should remove this
          // createAssetAction({ assetId, action: 'Product Registered', latitude, longitude })
          const dataToRegister = { name, email, assetId, propertiesMap }
          console.log('Registration Submitted:', dataToRegister)
          return registerAsset(dataToRegister)
        },
        description: `We have all the information we need about your ${assetName}. To complete your registration, just submit your name and email and you're all set.`,
      },
    },
    modals: {
      instructions: {
        footer: null,
        content: <Instructions />,
        bodyStyle: { height: '702px', padding: '0' },
        width: isMobile ? '100%' : '520px',
      },
    },
    logo: {
      img: 'jabra-logo.svg',
      backgroundColor: 'rgb(255 209 0)',
      height: '65px',
    },
    image: {
      img: 'jabra-product.jpg',
      height: '150px',
      width: '132px',
    },
    name: assetName,
    buttons: {
      first: {
        name: 'Setup Instructions',
        key: 'instructions',
        type: 'modal',
      },
      second: {
        name: 'Registration',
        key: 'registration',
        type: 'form',
      },
    },
    details: assetData,
    // TODO: add this?
    // isValid,
  }
  return <ConnectPage {...props} />
}

Jabra.propTypes = {
  data: PropTypes.object.isRequired,
}

Jabra.defaultProps = {
  data: {},
}

export default Jabra
