import React from 'react'
import FontPicker from './FontPicker'
import PropTypes from 'prop-types'
import { generateRandomString } from './utils'
import { Divider, Button, Tooltip } from 'antd'
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'

const iconStyle = {
  float: 'left',
  fontSize: '1.5em',
  marginTop: '5px',
  marginLeft: '20px',
}

const SortableItem = SortableElement(({ value }) => <li style={{ listStyle: 'none' }}>{value}</li>)
const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      ...iconStyle,
      cursor: 'move',
      color: '#999',
    }}
  />
))
const SortableList = SortableContainer(({ items }) => (
  <ul style={{ padding: 0, listStyleType: 'none' }}>
    {items.map((value, index) => (
      <SortableItem key={`item-${value}`} index={index} value={value} />
    ))}
  </ul>
))

const AdditionalTextBuilder = ({ data, setData, marginBottom }) => {
  const addText = () =>
    setData([
      ...data,
      {
        key: generateRandomString(),
        inputValue: '',
        fontSizeValue: 14,
        fontWeightValue: 400,
        fontColorValue: 'inherit',
        fontFamilyValue: 'Raleway',
      },
    ])
  const removeText = key => setData(data.filter(item => item.key !== key))
  const setItem = (key, field, value) =>
    setData(
      data.map(item => {
        if (item.key === key) {
          return {
            ...item,
            [field]: value,
          }
        }
        return item
      })
    )

  return (
    <>
      <SortableList
        onSortEnd={({ oldIndex, newIndex }) => setData(arrayMove(data, oldIndex, newIndex))}
        useDragHandle={true}
        items={data.map(({ key, inputValue, fontSizeValue, fontWeightValue, fontColorValue, fontFamilyValue }) => (
          <div key={key}>
            <FontPicker
              style={{ float: 'left', width: '88%' }}
              marginBottom={marginBottom}
              inputValue={inputValue}
              inputOnChange={val => setItem(key, 'inputValue', val)}
              fontSizeValue={fontSizeValue}
              fontSizeOnChange={val => setItem(key, 'fontSizeValue', val)}
              fontWeightValue={fontWeightValue}
              fontWeightOnChange={val => setItem(key, 'fontWeightValue', val)}
              fontColorValue={fontColorValue}
              fontColorOnChange={val => setItem(key, 'fontColorValue', val)}
              fontFamilyValue={fontFamilyValue}
              fontFamilyOnChange={val => setItem(key, 'fontFamilyValue', val)}
            />
            <Tooltip title='Remove Item'>
              <DeleteOutlined
                style={{
                  ...iconStyle,
                  color: '#b5b5b5',
                  cursor: 'pointer',
                }}
                onClick={() => removeText(key)}
              />
            </Tooltip>
            <DragHandle />
            <Divider />
          </div>
        ))}
      />
      <Button onClick={addText}>+ Add Text</Button>
    </>
  )
}

AdditionalTextBuilder.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  marginBottom: PropTypes.string.isRequired,
}

AdditionalTextBuilder.defaultProps = {
  data: [],
  setData: data => data,
  marginBottom: '10px',
}

export default AdditionalTextBuilder
