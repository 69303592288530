import React, { useEffect, Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CenterContainer from '../../components/CenterContainer'
import ProductLogo from '../../components/ProductLogo'
import ProductButtons from '../../components/ProductButtons'
import { useHistory } from 'react-router-dom'
import { resetInstructions } from '../../store/instructions'
import { Modal } from 'antd'
import manualMap from './manualMap'
import Manual from './Manual'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import VideoPlaylist from '../../components/VideoPlaylist'

const Instructions = ({ assetId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { logo, video, manual, support, loaded } = useSelector(({ instructions }) => instructions)
  const pages = _get(manualMap[_get(manual, 'mapName')], 'pages', [])
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loaded && assetId) {
      history.push(`/consumer/?assetid=${assetId}`)
    }
    return () => dispatch(resetInstructions())
  }, []) // eslint-disable-line

  return (
    <Fragment>
      <Modal
        visible={modalOpen}
        centered
        footer={null}
        title={'Product Manual'}
        bodyStyle={{ padding: '0' }}
        onCancel={() => setModalOpen(false)}
      >
        <Manual pages={pages} />
      </Modal>
      <CenterContainer>
        {logo && (
          <ProductLogo backgroundColor={logo.backgroundColor} width={logo.width} height={logo.height} img={logo.img} />
        )}
        {video && <VideoPlaylist data={video.data} color={video.color} />}
        {manual && (
          <ProductButtons
            postIt={manual.postIt}
            color={manual.color}
            name={{ first: manual.btnText, second: support.btnText }}
            onClick={{ first: () => setModalOpen(true), second: () => window.open(support.link, '_self') }}
          />
        )}
      </CenterContainer>
    </Fragment>
  )
}

Instructions.propTypes = {
  assetId: PropTypes.string.isRequired,
}

Instructions.defaultProps = {
  assetId: '',
}

export default Instructions
