import React from 'react'
import PropTypes from 'prop-types'
import config from '../config'

const ProductName = ({
  name,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  backgroundColor,
  backgroundPercent,
  verticalSpacing,
  fontFamily,
}) => (
  <div
    style={{
      textAlign: 'center',
      color,
      fontSize,
      lineHeight,
      fontWeight,
      margin: `${verticalSpacing} auto auto auto`,
      borderRadius: '25px',
      width: '95%',
      backgroundColor: `rgb(${backgroundColor}${backgroundPercent < 100 ? ` / ${backgroundPercent}%` : ''})`,
      fontFamily,
    }}
  >
    {name}
  </div>
)

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  backgroundPercent: PropTypes.number.isRequired,
  fontSize: PropTypes.string.isRequired,
  lineHeight: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string.isRequired,
  fontFamily: PropTypes.string.isRequired,
}

ProductName.defaultProps = {
  name: '',
  backgroundColor: '',
  backgroundPercent: 100,
  fontSize: '1.4em',
  lineHeight: '1.7em',
  fontWeight: '400',
  verticalSpacing: config.verticalSpacing,
  color: 'inherit',
  fontFamily: 'Raleway',
}

export default ProductName
