import MedtronicsDemo from '../../temp/MedtronicsDemo'
import Jabra from '../../connect-pages/Jabra'
import Evenflo from '../../connect-pages/Evenflo'
import BlueDiamond from '../../connect-pages/BlueDiamond'
import BD from '../../connect-pages/BD'
import Philips from '../../connect-pages/Philips'
import ResMed from '../../connect-pages/ResMed'
import ThreeM from '../../connect-pages/ThreeM'
import LannisterBeer from '../../connect-pages/brewery/Lannister'
import Greyjoy from '../../connect-pages/brewery/Greyjoy'

const namePageMap = {
  jabraevolve75: Jabra,
  puritanbennett: MedtronicsDemo,
  evenfloeverykid: Evenflo,
  smokehousenut: BlueDiamond,
  particulaterespirator: ThreeM,
  resmedairsense: ResMed,
  philipsrespironics: Philips,
  veritorsystem: BD,
  lannistergold: LannisterBeer,
  greyjoybeer: Greyjoy,
}

export default (name = '') => {
  const nameAbbr = name.toLowerCase().replace(/\s/g, '')
  let page = null
  for (const key in namePageMap) {
    if (nameAbbr.includes(key)) {
      page = namePageMap[key]
      break
    }
  }
  return page
}
