import React from 'react'
import Image from '../lib/Image'
import { ButtonComp } from '../templates/Primary1'
import PropTypes from 'prop-types'

const ActionPage = ({ items, verticalSpacing, isPreview, setAction, productId, batchId, preventAction }) => {
  return items.map((item, i) => {
    const key = item?.key
    const type = item?.type
    if (type === 'text') {
      return (
        <div
          key={key}
          style={{
            textAlign: 'center',
            marginTop: verticalSpacing,
            fontSize: item?.fontSizeValue,
            fontWeight: item?.fontWeightValue,
            color: item?.fontColorValue,
            fontFamily: item?.fontFamilyValue,
          }}
        >
          {item?.inputValue}
        </div>
      )
    }

    if (type === 'image') {
      return (
        <Image
          url={item?.url}
          name={`Image-${i}`}
          width={item?.width}
          height={item?.height}
          marginTop={verticalSpacing}
        />
      )
    }

    if (type === 'buttons') {
      return (
        <ButtonComp
          buttons={item?.buttons || []}
          isPreview={isPreview}
          setAction={setAction}
          productId={productId}
          verticalSpacing={verticalSpacing}
          batchId={batchId}
          preventAction={preventAction}
        />
      )
    }

    return null
  })
}

ActionPage.propTypes = {
  items: PropTypes.array.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isPreview: PropTypes.bool.isRequired,
  setAction: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  batchId: PropTypes.string.isRequired,
  preventAction: PropTypes.bool.isRequired,
}

ActionPage.defaultProps = {
  items: [],
  verticalSpacing: '10px',
  isPreview: false,
  setAction: () => {},
  productId: '',
  batchId: '',
  preventAction: false,
}

export default ActionPage
