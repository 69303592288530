export default (queryStr = '') => {
  const queryStrArr = queryStr.toLowerCase().replace('?', '').split('&')
  const searchParams = {}
  for (const str of queryStrArr) {
    const strArr = str.split('=')
    const key = strArr[0]
    if (!key) continue
    const value = strArr[1]
    searchParams[key] = value
  }
  return searchParams
}
