import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { authenticateToken } from '../../store/buildTool'
import { Input, Button, message, Spin } from 'antd'

const Login = () => {
  const [value, setValue] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    setPageLoading(true)
    dispatch(authenticateToken(value)).finally(() => setPageLoading(false))
  }, []) // eslint-disable-line

  return (
    <div style={{ width: '500px', margin: '20% auto auto auto', textAlign: 'center' }}>
      {pageLoading ? (
        <Spin size='large' style={{ marginTop: '40%' }} />
      ) : (
        <Fragment>
          <h2>Login Token</h2>
          <Input value={value} onChange={({ target }) => setValue(target.value)} />
          <Button
            loading={buttonLoading}
            style={{ marginTop: '20px' }}
            type='primary'
            onClick={() => {
              if (!value) return message.warning('Please enter a token')
              setButtonLoading(true)
              return dispatch(authenticateToken(value)).finally(() => setButtonLoading(false))
            }}
          >
            Submit
          </Button>
        </Fragment>
      )}
    </div>
  )
}

export default Login
