import React, { useEffect } from 'react'
import { Input, Button, Divider } from 'antd'
import useSetState from '../../../hooks/useSetState'
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import _isEmpty from 'lodash/isEmpty'

export const generateInitialState = () => ({ url: '', name: '', key: Math.random().toString() })

const Item = ({ initialState, onDelete, onChange, keyValue: key }) => {
  const [state, setState] = useSetState(initialState)

  useEffect(() => {
    onChange(state, key)
  }, [state]) // eslint-disable-line

  const { url, name } = state
  return (
    <div style={{ marginBottom: '10px' }}>
      <Input
        style={{ width: '47%' }}
        prefix={<LinkOutlined />}
        value={url}
        onChange={({ target }) => setState({ url: target.value })}
        placeholder='Video URL'
      />
      <Input
        style={{ width: '47%', marginLeft: '10px' }}
        value={name}
        onChange={({ target }) => setState({ name: target.value })}
        placeholder='Video Name'
      />
      <DeleteOutlined
        style={{
          float: 'right',
          color: '#b5b5b5',
          fontSize: '1.5em',
          marginTop: '5px',
          marginLeft: '5px',
          cursor: 'pointer',
        }}
        onClick={() => onDelete(key)}
      />
    </div>
  )
}

const VideoPlaylist = ({ playlist, setPlaylist, pageProps, setPageProps }) => {
  useEffect(() => {
    if (_isEmpty(playlist)) {
      setPlaylist([generateInitialState()])
    }
  }, []) // eslint-disable-line

  return (
    <div>
      <div>
        <span>Selected Video Color</span>
        <Input
          value={pageProps.selectedColor}
          onChange={({ target }) => setPageProps({ selectedColor: target.value })}
        />
      </div>
      <Divider />
      {playlist.map(item => (
        <Item
          initialState={item}
          key={item.key}
          keyValue={item.key}
          onChange={(item, key) =>
            setPlaylist(
              playlist.map(pl => {
                if (key === pl.key) {
                  return item
                }
                return pl
              })
            )
          }
          onDelete={key => setPlaylist(playlist.filter(val => val.key !== key))}
        />
      ))}
      <Button style={{ marginTop: '10px' }} onClick={() => setPlaylist([...playlist, generateInitialState()])}>
        + Video
      </Button>
    </div>
  )
}

export default VideoPlaylist
