import { message } from 'antd'
import axios from 'axios'
import config from '../config'
import _get from 'lodash/get'
import {
  fetchBuildConfigs as fetchBuildConfigsAPI,
  createBuildConfig as createBuildConfigAPI,
  updateBuildConfig as updateBuildConfigAPI,
  deleteBuildConfig as deleteBuildConfigAPI,
} from '../api'

const buildTokenKey = 'buildToken'

// ACTION TYPES
const mod = action => `@TOOLS/${action}`
const SET_BUILD_AUTH = mod('SET_BUILD_AUTH')
const SET_CONFIGS_LOADING = mod('SET_CONFIGS_LOADING')
const SET_CONFIGS_LIST = mod('SET_CONFIGS_LIST')
const SET_BUTTON_CLICK_KEY = mod('SET_BUTTON_CLICK_KEY')
const SET_PRODUCT_ID = mod('SET_PRODUCT_ID')
const SET_BATCH_ID = mod('SET_BATCH_ID')
const SET_CONNECT_PAGE_ID = mod('SET_CONNECT_PAGE_ID')

export const authenticateToken = (token = '') => async dispatch => {
  try {
    token = token || localStorage.getItem(buildTokenKey)
    if (!token) return
    const res = await axios({
      method: 'GET',
      url: `${config.apiUrl}/organizations`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    })
    const isSuccessful = _get(res, 'success') === true
    if (!isSuccessful) return message.error('Invalid Token')
    message.success('Successfully logged in!')
    localStorage.setItem(buildTokenKey, token)
    return dispatch({ type: SET_BUILD_AUTH, isAuthenticated: true })
  } catch (err) {
    console.error('Error with build token authentication:', err.toString())
    message.error('Invalid Token')
    return
  }
}

export const fetchBuildConfigs = () => async dispatch => {
  try {
    dispatch({ type: SET_CONFIGS_LOADING, loading: true })
    const data = await fetchBuildConfigsAPI()
    dispatch({ type: SET_CONFIGS_LIST, data })
  } catch (err) {
    message.error(err)
  } finally {
    return dispatch({ type: SET_CONFIGS_LOADING, loading: false })
  }
}

export const createBuildConfig = config => async dispatch => {
  try {
    dispatch({ type: SET_CONFIGS_LOADING, loading: true })
    await createBuildConfigAPI(config)
    const data = await fetchBuildConfigsAPI()
    dispatch({ type: SET_CONFIGS_LIST, data })
    const pageName = _get(config, 'connectPageName')
    message.success(`Successfully saved page${pageName ? ` - ${pageName}` : '!'}`)
  } catch (err) {
    message.error(err)
  } finally {
    return dispatch({ type: SET_CONFIGS_LOADING, loading: false })
  }
}

export const updateBuildConfig = config => async dispatch => {
  try {
    dispatch({ type: SET_CONFIGS_LOADING, loading: true })
    await updateBuildConfigAPI(config)
    const data = await fetchBuildConfigsAPI()
    dispatch({ type: SET_CONFIGS_LIST, data })
    const pageName = _get(config, 'connectPageName')
    message.success(`Successfully saved page${pageName ? ` - ${pageName}` : '!'}`)
  } catch (err) {
    message.error(err)
  } finally {
    return dispatch({ type: SET_CONFIGS_LOADING, loading: false })
  }
}

export const deleteBuildConfig = id => async dispatch => {
  try {
    dispatch({ type: SET_CONFIGS_LOADING, loading: true })
    await deleteBuildConfigAPI(id)
    const data = await fetchBuildConfigsAPI()
    dispatch({ type: SET_CONFIGS_LIST, data })
    message.success('Successfully deleted page!')
  } catch (err) {
    message.error(err)
  } finally {
    return dispatch({ type: SET_CONFIGS_LOADING, loading: false })
  }
}

export const setButtonClickKey = key => dispatch => dispatch({ type: SET_BUTTON_CLICK_KEY, key })
export const setProductId = productId => dispatch => dispatch({ type: SET_PRODUCT_ID, productId })
export const setBatchId = batchId => dispatch => dispatch({ type: SET_BATCH_ID, batchId })
export const setConnectPageId = connectPageId => dispatch => dispatch({ type: SET_CONNECT_PAGE_ID, connectPageId })

const initialState = {
  isAuthenticated: false,
  configs: [],
  configsLoading: false,
  buttonClickKey: '',
  productId: '',
  batchId: undefined,
  connectPageId: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUILD_AUTH:
      return { ...state, isAuthenticated: action.isAuthenticated }
    case SET_CONFIGS_LOADING:
      return { ...state, configsLoading: action.loading }
    case SET_CONFIGS_LIST:
      return { ...state, configs: action.data }
    case SET_BUTTON_CLICK_KEY:
      return { ...state, buttonClickKey: action.key }
    case SET_PRODUCT_ID:
      return { ...state, productId: action.productId }
    case SET_BATCH_ID:
      return { ...state, batchId: action.batchId }
    case SET_CONNECT_PAGE_ID:
      return { ...state, connectPageId: action.connectPageId }
    default:
      return state
  }
}
