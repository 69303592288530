import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAsset } from '../../store/asset'
import { LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import _get from 'lodash/get'
import CenterContainer from '../../components/CenterContainer'
import determinePage from './determinePage'
import PropTypes from 'prop-types'
import determineTemplatePage from './determineTemplatePage'
import NotFound from '../NotFound'
import determineTemplateConfig from '../../utils/determineTemplateConfig'

const Consumer = ({ isValid, assetId }) => {
  const { err, loading: assetLoading, loaded: assetLoaded, data } = useSelector(({ asset }) => asset)
  const productData = _get(data, 'asset.product', {})
  const productName = productData.name
  const configListLoading = useSelector(state => _get(state, 'buildTool.configsLoading', false))
  const configList = useSelector(state => _get(state, 'buildTool.configs', []))
  const configData = determineTemplateConfig({ configList, assetData: data })
  const dispatch = useDispatch()

  console.log('Rendering page for product:', productName)
  const ConnectPage = determinePage(productName)
  const TemplatePage = determineTemplatePage(configData)
  const RenderedPage = ConnectPage || TemplatePage || NotFound

  useEffect(() => {
    if (assetId) {
      dispatch(fetchAsset(assetId))
    }
  }, [assetId]) // eslint-disable-line

  return (
    <div>
      {err && <Alert severity='error'>{err}</Alert>}
      {(assetLoading || configListLoading) && <LinearProgress />}
      {assetLoaded && !configListLoading && (
        <CenterContainer>
          <RenderedPage data={data} isValid={isValid} productData={productData} formData={configData} />
        </CenterContainer>
      )}
    </div>
  )
}

Consumer.propTypes = {
  isValid: PropTypes.bool.isRequired,
  assetId: PropTypes.string.isRequired,
}

Consumer.defaultProps = {
  isValid: false,
  assetId: '',
}

export default Consumer
