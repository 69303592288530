export const templateIds = {
  primary1: 'Product',
}

export default [
  {
    id: templateIds.primary1,
    verticalSpacing: 10,
    productImageUrl: 'https://techcrunch.com/wp-content/uploads/2020/03/PB560.jpg',
    productImageWidth: 180,
    productImageHeight: 138,
    productBannerUrl: 'https://cdn.websurg.com/web/img/industries/a_medtronic.svg',
    productBannerWidth: 200,
    productBannerHeight: 60,
    enableProductName: true,
    productNameFontSize: 18,
    productNameFontWeight: 400,
    productNameColor: 'inherit',
    productNameFontFamily: 'Raleway',
    enablePCA: true,
    pcaFontSize: 14,
    pcaFontWeight: 400,
    pcaColor: 'inherit',
    pcaFontFamily: 'Raleway',
    pcaValidMessage:
      "The ✅ message tells you that no counterfeit products bearing the same code have been detected. Your product's information should match what is shown below",
    pcaInvalidMessage: 'Warning: Potential problem noted - Compare data below with product',
    additionalTextData: [],
    selectedData: [],
    dataFontSize: 12,
    buttons: [],
    buttonFlip: false,
    keepBannerAR: false,
    keepProductAR: false,
  },
]
