import React, { Fragment } from 'react'
import Logo from './Logo'
import { Form, Input, message, Row } from 'antd'
import PostItButton from '../../components/PostItButton'
import { registerAsset } from '../../api'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import extractAssetData from '../../utils/extractAssetData'
import { dataKeys } from './'

const verticalSpacing = '25px'
const blue = '#0071b9'
const yellow = '#fbd476'

const RegisterForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const data = useSelector(({ asset: { data } }) => data)

  const handleSubmit = async () => {
    await form.validateFields()
    const { name, email } = form.getFieldsValue()
    const {
      name: assetName,
      data: assetData,
      assetId,
      location: { latitude, longitude },
    } = extractAssetData({ data, keys: dataKeys })

    const propertiesMap = {
      'Scan Date/Time': new Date().toString(),
      'Scan Lat/Long': `${latitude}, ${longitude}`,
      'Product Name': assetName,
    }

    Object.keys(assetData).forEach(key => {
      const { name, description } = assetData[key]
      propertiesMap[name] = description
    })

    const dataToRegister = { name, email, assetId, propertiesMap }
    registerAsset(dataToRegister)

    message.success({
      content: 'Successfully Registered!',
      duration: 4,
      style: { marginTop: '87vh' },
    })
    return history.goBack()
  }

  return (
    <Fragment>
      <Logo />
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: verticalSpacing, color: blue }}>
          {'Register Product'}
        </div>
        <div style={{ marginTop: verticalSpacing, padding: '5px', color: blue }}>
          {
            "We have captured your product's model and serial numbers, and you may register your product by entering your name and email below"
          }
        </div>
      </div>
      <Form form={form} style={{ margin: `${verticalSpacing} auto 0 auto`, width: '95%' }}>
        <Form.Item name='name' rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input placeholder='Name' size='large' />
        </Form.Item>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'Please enter a valid email address', type: 'email' }]}
        >
          <Input placeholder='Email' size='large' />
        </Form.Item>
      </Form>
      <Row justify='center' style={{ marginTop: '20px' }}>
        <PostItButton backgroundColor={yellow} onClick={handleSubmit}>
          <span style={{ fontSize: '1.3em', margin: 'auto' }}>{'Submit'}</span>
        </PostItButton>
      </Row>
    </Fragment>
  )
}

export default RegisterForm
