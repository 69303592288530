import React from 'react'
import ProductLogo from '../../components/ProductLogo'

const Logo = () => (
  <div style={{ marginTop: '5px' }}>
    <ProductLogo img='resmed-logo.png' height='75px' width='' />
  </div>
)

export default Logo
