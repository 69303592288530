import React, { useEffect } from 'react'
import CenterContainer from '../../components/CenterContainer'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import pageMap from './pageMap'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import NotFound from '../NotFound'
import { videoPropsInitialState } from '../Tools/VideoBuilder'
import { picturePropsInitialState } from '../Tools/PictureBuilder'
import { buttonActions } from '../Tools/lib/ButtonBuilder'
import { flattenButtons } from '../Tools/lib/utils'
import { fetchAsset } from '../../store/asset'
import { Spin } from 'antd'
import extractSearchParams from '../../utils/extractSearchParams'

const ToolsPage = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (_isEmpty(configs)) {
      dispatch(fetchAsset(assetId))
    }
  }, []) // eslint-disable-line

  const { id = '' } = useParams()
  const history = useHistory()
  const searchParams = extractSearchParams(history.location.search)
  const connectPageId = _get(searchParams, 'connectpageid', '')
  const assetId = _get(searchParams, 'assetid', '')
  const assetData = useSelector(state => _get(state, 'asset.data.asset', {}))
  const productData = _get(assetData, 'product', {})
  const buildToolState = useSelector(state => _get(state, 'buildTool', {}))
  const { buttonClickKey = '', configs = [], configsLoading } = buildToolState
  const configData = _find(configs, { id: connectPageId }) || {}
  const buttons = flattenButtons(configData)
  let foundBtn = buttons.find(btn => btn.key === buttonClickKey)

  const Component = pageMap[id] || NotFound
  let additionalProps = {}
  if (id === buttonActions.CustomForm) {
    additionalProps = _get(foundBtn, 'customForm', {})
  }
  if (id === buttonActions.VideoPlaylist) {
    additionalProps = {
      playlist: _get(foundBtn, 'videoPlaylist', []),
      videoProps: _get(foundBtn, 'videoProps', videoPropsInitialState),
    }
  }
  if (id === buttonActions.PictureList) {
    additionalProps = {
      pictureList: _get(foundBtn, 'pictureList', []),
      pageProps: _get(foundBtn, 'pictureProps', picturePropsInitialState),
    }
  }
  if (id === buttonActions.PageBuilder) {
    if (_get(foundBtn, 'action') !== buttonActions.PageBuilder) {
      foundBtn = _find(buttons, { action: buttonActions.PageBuilder })
    }
    additionalProps = {
      items: _get(foundBtn, 'customPageItems', []),
      verticalSpacing: _get(configData, 'verticalSpacing', 10),
      productId: _get(configData, 'productId', ''),
      batchId: _get(configData, 'batchId', ''),
    }
  }

  const componentProps = {
    ...props,
    goBack: history.goBack,
    isPreview: false,
    buttonClickKey,
    configData,
    assetData,
    productData,
    ...additionalProps,
  }

  return configsLoading ? (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Spin size='large' />
    </div>
  ) : (
    <CenterContainer>{Component && <Component {...componentProps} />}</CenterContainer>
  )
}

export default ToolsPage
