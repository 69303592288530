import React from 'react'
import { Divider, Row } from 'antd'
import Logo from './Logo'
import Celiac from '../../assets/bd-images/celiac.jpg'
import GlutenFree from '../../assets/bd-images/glutenFree.png'
import Kosher from '../../assets/bd-images/kosher.png'
import NonGMO from '../../assets/bd-images/nonGmo.jpg'

const orange = 'rgb(246, 139, 26)'
const bold = { fontWeight: 'bold', float: 'left' }
const titleInfo = { float: 'left', marginLeft: '5px' }
const rightInfo = { float: 'right' }
const info = { float: 'left', marginLeft: '16px' }

const boldDivider = { margin: '8px 0', border: '2px solid' }
const regDivider = { margin: '2px 0', border: '1px solid rgba(0, 0, 0, 0.3)' }

const btmImgStyle = { height: '12vh', width: '25%' }

const Nutrition = () => (
  <div>
    <Logo />
    <div style={{ textAlign: 'center', fontSize: '2em', margin: '10px 0', color: orange }}>Nutrition Information</div>
    <div style={{ margin: '5px' }}>
      <span style={bold}>{'Calories'}</span>
      <span style={rightInfo}>{'130'}</span>
      <Divider style={boldDivider} />

      <span style={bold}>{'Total Fat'}</span>
      <span style={titleInfo}>{'3g'}</span>
      <span style={rightInfo}>{'4%'}</span>
      <Divider style={regDivider} />
      <span style={info}>{'Saturated Fat'}</span>
      <span style={titleInfo}>{'0g'}</span>
      <span style={rightInfo}>{'4%'}</span>
      <Divider style={regDivider} />
      <span style={info}>{'Trans Fat'}</span>
      <span style={titleInfo}>{'0g'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Total Carbohydrate'}</span>
      <span style={titleInfo}>{'23g'}</span>
      <span style={rightInfo}>{'8%'}</span>
      <Divider style={regDivider} />
      <span style={info}>{'Dietary Fiber'}</span>
      <span style={titleInfo}>{'1g'}</span>
      <span style={rightInfo}>{'4%'}</span>
      <Divider style={regDivider} />
      <span style={info}>{'Sugars'}</span>
      <span style={titleInfo}>{'0g'}</span>
      <Divider style={regDivider} />
      <span style={info}>{'Added Sugars'}</span>
      <span style={titleInfo}>{'0g'}</span>
      <span style={rightInfo}>{'0%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Cholesterol'}</span>
      <span style={titleInfo}>{'0mg'}</span>
      <span style={rightInfo}>{'0%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Sodium'}</span>
      <span style={titleInfo}>{'160mg'}</span>
      <span style={rightInfo}>{'7%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Protein'}</span>
      <span style={titleInfo}>{'3g'}</span>
      <span style={rightInfo}>{'0%'}</span>
      <Divider style={{ ...boldDivider, margin: '4px 0' }} />

      <span style={bold}>{'Vitamin D'}</span>
      <span style={titleInfo}>{'0mcg'}</span>
      <span style={rightInfo}>{'0%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Calcium'}</span>
      <span style={titleInfo}>{'15mg'}</span>
      <span style={rightInfo}>{'2%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Iron'}</span>
      <span style={titleInfo}>{'0mg'}</span>
      <span style={rightInfo}>{'0%'}</span>
      <Divider style={regDivider} />

      <span style={bold}>{'Potassium'}</span>
      <span style={titleInfo}>{'47mg'}</span>
      <span style={rightInfo}>{'2%'}</span>
    </div>
    <br />
    <div style={{ color: orange, textAlign: 'center', marginTop: '10px' }}>
      <span style={{ fontWeight: 'bold' }}>Ingredients</span>
      <br />
      <span>
        Rice Flour, Almonds, Potato Starch, Safflower Oil, Seasoning (Sea Salt, Precooked Rice Flower, Torula Yeast,
        Natural Smoke Flavor, Dehydrated Onion, Citic Acid, Spice Extractives)
      </span>
    </div>
    <Row justify='space-between' style={{ margin: '10px 5px 0 5px' }}>
      <img src={Celiac} alt='Celiac Foundation' style={btmImgStyle} />
      <img src={GlutenFree} alt='Gluten Free' style={btmImgStyle} />
      <img src={Kosher} alt='Kosher' style={btmImgStyle} />
      <img src={NonGMO} alt='Non GMO' style={btmImgStyle} />
    </Row>
  </div>
)

export default Nutrition
