import React, { useRef } from 'react'
import { Row, Col, Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import './Manual.css'

const Manual = ({ pages = [] }) => {
  const carouselEl = useRef(null)

  return (
    <Row justify='space-around' align='middle'>
      <Col flex={'5%'}>
        <LeftOutlined onClick={() => carouselEl.current.prev()} />
      </Col>
      <Col flex={'90%'} style={{ maxWidth: '90%' }}>
        <Carousel ref={carouselEl} dots={false}>
          {pages.map((src, i) => (
            <img key={i} src={src} alt={`page-${i}`} />
          ))}
        </Carousel>
      </Col>
      <Col flex={'5%'}>
        <RightOutlined onClick={() => carouselEl.current.next()} />
      </Col>
    </Row>
  )
}

export default Manual
