import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { fontSize, green } from './MedtronicsDemo'
import validateEmail from '../utils/validateEmail'
import { createAssetAction } from '../api'

const inputStyle = { width: '100%', margin: '20px auto auto auto' }

const RegisterForm = ({ assetId, serial, model, setShowForm, setSnackbarOpen }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({ name: false, email: false })

  useEffect(() => {
    setSnackbarOpen(false)
    return () => {
      setEmail('')
      setName('')
    }
  }, []) // eslint-disable-line

  const handleSubmit = () => {
    const formErrors = {
      name: name.trim().length === 0,
      email: !validateEmail(email),
    }
    setErrors(formErrors)
    const hasErrors = formErrors.name || formErrors.email
    if (!hasErrors) {
      createAssetAction({
        assetId,
        propertiesMap: { serial, model, name, email },
        action: 'Product Registered',
      }).then(res => console.log('Created action event:', res))

      setSnackbarOpen(true, 'Successfully registered product!')
      setShowForm(false)
      setTimeout(() => setSnackbarOpen(false), 5000)
    }
  }

  return (
    <div style={{ width: '95%', margin: '20px auto auto auto' }}>
      <ArrowBackIcon style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={() => setShowForm(false)} />
      <Card>
        <CardContent>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Register Product</span>
          <Divider style={{ margin: '10px 0' }} />
          <span>
            We have captured your product's model and serial numbers, and you may register your product by entering your
            name and email below
          </span>
        </CardContent>
      </Card>
      <form
        onKeyDown={({ keyCode }) => {
          if (keyCode === 13) {
            handleSubmit()
          }
        }}
      >
        <TextField
          id='outlined-basic'
          label='Name'
          variant='outlined'
          style={inputStyle}
          error={errors.name}
          helperText={errors.name ? 'Please enter a name' : null}
          value={name}
          onChange={({ target: { value } }) => {
            if (errors.name && value) {
              setErrors({ ...errors, name: false })
            }
            setName(value)
          }}
        />
        <TextField
          id='outlined-basic'
          label='Email'
          variant='outlined'
          style={inputStyle}
          error={errors.email}
          helperText={errors.email ? 'Please enter a valid email address' : null}
          value={email}
          onChange={({ target: { value } }) => {
            if (errors.email && value) {
              setErrors({ ...errors, email: !validateEmail(value) })
            }
            setEmail(value)
          }}
        />
        <Button
          variant='contained'
          size='large'
          style={{
            backgroundColor: green,
            color: '#fff',
            fontSize,
            margin: '20px auto auto auto',
            width: '100%',
            display: 'flex',
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

export default RegisterForm
