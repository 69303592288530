import React, { useMemo } from 'react'
import PostItButton from '../../../components/PostItButton'
import { Row, Form, Input, Checkbox, Select, message } from 'antd'
import _camelCase from 'lodash/camelCase'
import _get from 'lodash/get'
import ProductLogo from '../../../components/ProductLogo'
import PropTypes from 'prop-types'
import './Form.css'

const verticalSpacing = '20px'

const Field = ({ type, placeholder, options, value, onChange }) => {
  switch (type) {
    case 'input':
      return <Input placeholder={placeholder} value={value} onChange={({ target }) => onChange(target.value)} />
    case 'checkbox':
      return <Checkbox checked={!!value} onChange={({ target }) => onChange(target.checked)} />
    case 'select':
      return (
        <Select placeholder={placeholder} value={value} onChange={onChange}>
          {options.map((opt, i) => (
            <Select.Option value={opt} key={i}>
              {opt}
            </Select.Option>
          ))}
        </Select>
      )
    default:
      return null
  }
}

const CustomForm = ({
  isPreview = true,
  items,
  text,
  textProps,
  title,
  titleProps,
  button,
  buttonProps,
  labelProps,
  banner,
  assetData,
  productData,
  goBack,
}) => {
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    await form.validateFields()
    const formValues = form.getFieldValue()
    if (isPreview) {
      console.log('Submitted Values:', formValues)
    } else {
      const assetId = _get(assetData, 'assetId', '')
      const latitude = _get(assetData, 'latitude', '')
      const longitude = _get(assetData, 'longitude', '')

      const propertiesMap = {
        'Scan Date/Time': new Date().toString(),
        'Scan Lat/Long': `${latitude}, ${longitude}`,
        'Product Name': _get(productData, 'name', ''),
      }

      const dataToRegister = { ...formValues, assetId, propertiesMap }
      console.log('Submitted Values:', dataToRegister)
    }
    message.success({
      content: 'Successfully Sent!',
      duration: 4,
      style: { marginTop: '87vh' },
    })
    return goBack()
  }

  const determinErrorMsg = (label = '', type) => {
    const lowerCaseLabel = label.trim().toLowerCase()
    const vowels = ['a', 'e', 'i', 'o', 'u']
    const aWord = vowels.includes(lowerCaseLabel[0]) ? 'an' : 'a'
    const verb = type === 'select' ? 'select' : 'enter'
    return `Please ${verb} ${aWord} ${label}`
  }

  const initalValues = useMemo(
    () =>
      items.reduce((acc, { type, label }) => {
        const fieldName = _camelCase(label)
        if (type === 'checkbox') {
          acc[fieldName] = false
        }
        return acc
      }, {}),
    [items]
  )

  return (
    <div>
      {banner && <ProductLogo width={`${banner.width}px`} height={`${banner.height}px`} url={banner.url} />}
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            fontSize: titleProps.fontSize,
            fontWeight: titleProps.fontWeight,
            marginTop: verticalSpacing,
            color: titleProps.fontColor,
            fontFamily: titleProps.fontFamily,
          }}
        >
          {title}
        </div>
        <div
          style={{
            marginTop: verticalSpacing,
            padding: '5px',
            fontSize: textProps.fontSize,
            fontWeight: textProps.fontWeight,
            color: textProps.fontColor,
          }}
        >
          {text}
        </div>
      </div>
      <Form
        initialValues={initalValues}
        form={form}
        style={{ margin: `${verticalSpacing} auto 0 auto`, width: '95%' }}
        layout='vertical'
      >
        {items.map(({ type, label, placeholder, options }, i) => {
          const fieldName = _camelCase(label)
          return (
            <Form.Item
              name={fieldName}
              label={
                <span
                  style={{
                    color: labelProps.fontColor,
                    fontSize: labelProps.fontSize,
                    fontWeight: labelProps.fontWeight,
                    fontFamily: labelProps.fontFamily,
                  }}
                >
                  {label}
                </span>
              }
              rules={[{ required: true, message: determinErrorMsg(label, type) }]}
              key={i}
              colon={false}
              required={false}
            >
              <Field
                value={form.getFieldValue(fieldName)}
                onChange={value => form.setFieldsValue({ [fieldName]: value })}
                type={type}
                placeholder={placeholder}
                options={options}
              />
            </Form.Item>
          )
        })}
      </Form>
      <Row justify='center' style={{ marginTop: '20px' }}>
        <PostItButton
          backgroundColor={buttonProps.backgroundColor}
          width={`${buttonProps.width}px`}
          height={`${buttonProps.height}px`}
          color={buttonProps.fontColor}
          fontSize={`${buttonProps.fontSize}px`}
          fontWeight={buttonProps.fontWeight}
          fontFamily={buttonProps.fontFamily}
          boxShadowBlur={buttonProps.boxShadowBlur}
          boxShadowSpread={buttonProps.boxShadowSpread}
          borderRadius={buttonProps.borderRadius}
          onClick={handleSubmit}
        >
          <div style={{ margin: 'auto' }}>{button}</div>
        </PostItButton>
      </Row>
    </div>
  )
}

CustomForm.propTypes = {
  isPreview: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  textProps: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  titleProps: PropTypes.object.isRequired,
  button: PropTypes.string.isRequired,
  buttonProps: PropTypes.object.isRequired,
  labelProps: PropTypes.object.isRequired,
  banner: PropTypes.object,
  assetData: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
}

CustomForm.defaultProps = {
  isPreview: false,
  items: [],
  text: '',
  textProps: {},
  title: '',
  titleProps: {},
  button: '',
  buttonProps: {},
  labelProps: {},
  assetData: {},
  goBack: () => console.log('missing goBack function'),
}

export default CustomForm
