import React from 'react'
import { Input, InputNumber } from 'antd'
import FontWeightSelect from '../lib/FontWeightSelect'

const marginBottom = '10px'
const marginLeft = '5px'
const marginRight = '10px'

const ButtonBuilder = ({ button, buttonProps, setState }) => {
  const { backgroundColor, fontColor, width, height, fontSize, fontWeight } = buttonProps
  return (
    <div>
      <span>Button Text</span>
      <Input value={button} onChange={({ target }) => setState({ button: target.value })} />
      <span>Background Color</span>
      <Input
        style={{ marginBottom }}
        value={backgroundColor}
        onChange={({ target }) => setState({ buttonProps: { ...buttonProps, backgroundColor: target.value } })}
      />
      <span>Font Color</span>
      <Input
        style={{ marginBottom }}
        value={fontColor}
        onChange={({ target }) => setState({ buttonProps: { ...buttonProps, fontColor: target.value } })}
      />
      <div>
        <span>Width</span>
        <InputNumber
          style={{ marginLeft, marginRight }}
          value={width}
          onChange={width => setState({ buttonProps: { ...buttonProps, width } })}
        />
        <span>Height</span>
        <InputNumber
          style={{ marginLeft, marginRight }}
          value={height}
          onChange={height => setState({ buttonProps: { ...buttonProps, height } })}
        />
        <span>Font Size</span>
        <InputNumber
          style={{ marginLeft, marginRight }}
          value={fontSize}
          onChange={fontSize => setState({ buttonProps: { ...buttonProps, fontSize } })}
        />
        <span>Font Weight</span>
        <FontWeightSelect
          value={fontWeight}
          onChange={fontWeight => setState({ buttonProps: { ...buttonProps, fontWeight } })}
          style={{ marginLeft }}
        />
      </div>
    </div>
  )
}

export default ButtonBuilder
