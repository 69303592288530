import _get from 'lodash/get'
import _find from 'lodash/find'

export const appendKeyIndexToData = (data = []) => data.map((data, index) => ({ ...data, key: data.title, index }))

export const findSelectedDataInProduct = ({ productData, selectedData = [] }) => {
  const customDataFields = _get(productData, 'propertiesMap.customDataFields', [])
  const foundSelectedData = []
  for (const data of selectedData) {
    const found = _find(customDataFields, { title: data.title })
    if (found) {
      foundSelectedData.push(found)
    }
  }
  return appendKeyIndexToData(foundSelectedData)
}

export const findAndReplaceObject = ({ data = [], obj = {}, key = '' }) => {
  const dataMap = {}
  data.forEach(o => {
    dataMap[o[key]] = o
  })
  const objKey = obj[key]
  dataMap[objKey] = obj
  return Object.values(dataMap)
}

export const generateRandomString = () => {
  const num = Math.random() * 10000000000000000
  return num.toFixed(0)
}

export const flattenButtons = data => {
  const flattenedButtons = []

  const findButtons = data => {
    const buttons = _get(data, 'buttons', [])
    for (const button of buttons) {
      flattenedButtons.push(button)
      const customPageItems = _get(button, 'customPageItems', [])
      for (const customItem of customPageItems) {
        if (_get(customItem, 'type') === 'buttons') {
          findButtons(customItem)
        }
      }
    }
  }

  findButtons(data)
  return flattenedButtons
}
