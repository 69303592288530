import React, { useState, useEffect, useMemo } from 'react'
import { List } from 'antd'
import _get from 'lodash/get'

const videoHeight = 300

const getVideoId = url => {
  if (!url) return ''
  const beSplit = url.split('.be/')
  const embedSplit = url.split('embed/')
  const querySplit = url.split('?v=')
  return beSplit[1] || embedSplit[1] || querySplit[1] || ''
}

const VideoPlaylist = ({ playlist, videoProps }) => {
  const { selectedColor } = videoProps
  const [url, setUrl] = useState('')

  const filteredPlaylist = useMemo(() => {
    if (!Array.isArray(playlist)) return []
    return playlist.filter(video => !!video.url)
  }, [playlist])

  useEffect(() => {
    setUrl(_get(filteredPlaylist, '[0].url', ''))
  }, []) // eslint-disable-line

  return (
    <div>
      <iframe
        id='videoPlaylist'
        width='100%'
        height={videoHeight}
        src={`https://www.youtube.com/embed/${getVideoId(url)}?autoplay=1`}
        frameBorder='0'
        allow='autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Playlist'
      ></iframe>
      <div
        style={{
          width: '100%',
          height: window.innerHeight ? `${window.innerHeight - videoHeight}px` : '350px',
          overflow: 'scroll',
          cursor: 'pointer',
        }}
      >
        <List
          size='small'
          itemLayout='horizontal'
          dataSource={filteredPlaylist}
          renderItem={item => {
            const isSelectedItem = url === item.url
            return (
              <List.Item>
                <List.Item.Meta
                  title={
                    <div
                      onClick={() => setUrl(item.url)}
                      style={{ lineHeight: '50px', color: isSelectedItem ? selectedColor : 'inherit' }}
                    >
                      {item.name}
                    </div>
                  }
                  avatar={
                    <img
                      onClick={() => setUrl(item.url)}
                      src={`https://img.youtube.com/vi/${getVideoId(item.url)}/mqdefault.jpg`}
                      alt='Thumbnail'
                      style={{ width: '100px' }}
                    />
                  }
                  style={{ backgroundColor: isSelectedItem ? '#f7f7f7' : 'inherit' }}
                />
              </List.Item>
            )
          }}
        />
      </div>
    </div>
  )
}

export default VideoPlaylist
