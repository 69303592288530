import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import ProductLogo from './ProductLogo'
import ProductImage from './ProductImage'
import ProductName from './ProductName'
import ProductButtons from './ProductButtons'
import ProductData from './ProductData'
import ProductForm from './ProductForm'
import ProductModal from './ProductModal'
import PCATag from './PCATag'
import _noop from 'lodash/noop'
import _isNil from 'lodash/isNil'
import { useHistory } from 'react-router-dom'

const ConnectPage = ({ forms, modals, logo, image, name, buttons, details, isValid, assetId }) => {
  const [showForm, setShowForm] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [formKey, setFormKey] = useState('')
  const [modalKey, setModalKey] = useState('')

  const history = useHistory()

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleCloseForm = () => {
    setShowForm(false)
    setFormKey('')
  }

  const determineButtonClick = btn => {
    const buttonData = buttons[btn] || {}
    const { key, type, btnClick = _noop } = buttonData
    btnClick()

    if (type === 'modal') {
      setModalKey(key)
      setModalOpen(true)
    }

    if (type === 'form') {
      setFormKey(key)
      setShowForm(true)
    }

    if (type === 'instructions') {
      history.push(`/instructions?assetId=${assetId}`)
    }
  }

  const form = forms[formKey] || { onSubmit: () => {} }
  const modal = modals[modalKey] || { onSubmit: () => {} }

  return (
    <Fragment>
      <ProductModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSubmit={values => {
          modal.onSubmit(values)
          handleCloseModal()
        }}
        title={modal.title}
        footer={modal.footer}
        content={modal.content}
        bodyStyle={modal.bodyStyle}
        width={modal.width}
      />
      <ProductLogo img={logo.img} backgroundColor={logo.backgroundColor} height={logo.height} width={logo.width} />
      {showForm ? (
        <ProductForm
          onClose={handleCloseForm}
          onSubmit={values => {
            form.onSubmit(values)
            handleCloseForm()
          }}
          Form={form.Form}
          title={form.title}
          description={form.description}
          buttonStyle={form.buttonStyle}
        />
      ) : (
        <div>
          <ProductImage img={image.img} height={image.height} width={image.width} />
          <ProductName name={name} />
          {!_isNil(isValid) && <PCATag isValid={isValid} />}
          <ProductButtons
            name={{ first: buttons.first.name, second: buttons.second.name }}
            onClick={{ first: () => determineButtonClick('first'), second: () => determineButtonClick('second') }}
            postIt={buttons.postIt}
            color={buttons.color}
          />
          <ProductData data={details} />
        </div>
      )}
    </Fragment>
  )
}

ConnectPage.propTypes = {
  forms: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  buttons: PropTypes.shape({
    first: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    second: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  details: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  assetId: PropTypes.string.isRequired,
}

ConnectPage.defaultProps = {
  forms: {},
  modals: {},
  logo: {},
  image: {},
  name: '',
  buttons: {
    first: {
      name: 'First',
      key: '',
      type: '',
    },
    second: {
      name: 'First',
      key: '',
      type: '',
    },
  },
  details: [],
  assetId: '',
}

export default ConnectPage
