import React, { useState, useRef, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import _isNil from 'lodash/isNil'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import { Input, InputNumber, Checkbox, Select } from 'antd'

const getAspectRatio = ref => {
  try {
    if (!ref.current) return 1
    const { naturalWidth, naturalHeight } = ref.current
    if (_isNil(naturalWidth) || _isNil(naturalHeight)) return 1
    const aspectRatio = naturalWidth / naturalHeight
    return !isNaN(aspectRatio) && aspectRatio > 0 ? aspectRatio : 1
  } catch {
    return 1
  }
}

const CUSTOM_URL = 'CUSTOM_URL'

const ImagePicker = ({
  images,
  url,
  urlOnChange,
  width,
  widthOnChange,
  height,
  heightOnChange,
  keepRatio,
  keepRatioOnChange,
  marginBottom,
  marginLeft,
  resetState,
}) => {
  const imageRef = useRef(null)
  const [aspectRatio, setAspectRatio] = useState(1)
  const [isCustomURL, setIsCustomURL] = useState(false)
  const [selectValue, setSelectValue] = useState(undefined)
  const [originalSelectValue, setOriginalSelectValue] = useState(undefined)
  const [hasOriginalSelectValue, setHasOriginalSelectValue] = useState(false)
  const isEmptyImages = _isEmpty(images)

  const handleMaintainHeightAR = (width, keepRatio, aspectRatio) => {
    if (!keepRatio) return
    heightOnChange(Math.round(width / aspectRatio), { keepRatio, width })
  }

  const handleSelectValue = val => {
    if (!hasOriginalSelectValue) {
      setHasOriginalSelectValue(true)
      setOriginalSelectValue(val)
    }
    setSelectValue(val)
  }

  useEffect(() => {
    if (isEmptyImages) {
      setIsCustomURL(true)
      handleSelectValue(undefined)
    } else if (selectValue === CUSTOM_URL) {
      setIsCustomURL(true)
      handleSelectValue(CUSTOM_URL)
    } else if (!url) {
      setIsCustomURL(false)
      handleSelectValue(undefined)
    } else {
      const found = _find(images, { url })
      const isCustomURL = !found
      const selectValue = isCustomURL ? CUSTOM_URL : found ? found.url : undefined
      setIsCustomURL(isCustomURL)
      handleSelectValue(selectValue)
    }
  }, [images, url, isEmptyImages, selectValue, hasOriginalSelectValue]) // eslint-disable-line

  useEffect(() => {
    setSelectValue(originalSelectValue)
  }, [resetState]) // eslint-disable-line

  return (
    <Fragment>
      {!isEmptyImages && (
        <Select
          value={selectValue}
          style={{ width: '100%', marginBottom }}
          placeholder='Select a saved image or enter a custom url'
          onChange={selectValue => {
            urlOnChange(selectValue === CUSTOM_URL ? '' : selectValue)
            setSelectValue(selectValue)
          }}
        >
          <Select.Option key={CUSTOM_URL} value={CUSTOM_URL}>
            + Custom URL
          </Select.Option>
          {images.map(image => (
            <Select.Option key={image.url} value={image.url}>
              {image.name}
            </Select.Option>
          ))}
        </Select>
      )}
      {isCustomURL && (
        <Input
          value={url}
          style={{ marginBottom }}
          onChange={({ target }) => urlOnChange(target.value)}
          placeholder='Enter a URL'
        />
      )}
      <img
        hidden
        src={url}
        alt=''
        ref={imageRef}
        onLoad={() => {
          const aspectRatio = getAspectRatio(imageRef)
          setAspectRatio(aspectRatio)
          handleMaintainHeightAR(width, keepRatio, aspectRatio)
        }}
      />
      <div style={{ marginBottom }}>
        <span>Width:</span>
        <InputNumber
          value={width}
          onChange={width => {
            widthOnChange(width)
            handleMaintainHeightAR(width, keepRatio, aspectRatio)
          }}
          style={{ marginLeft }}
        />
        <span style={{ marginLeft }}>Height:</span>
        <InputNumber value={height} onChange={heightOnChange} style={{ marginLeft }} disabled={keepRatio} />
        <span style={{ marginLeft }}>Keep aspect ratio:</span>
        <Checkbox
          style={{ marginLeft }}
          checked={keepRatio}
          onChange={({ target: { checked } }) => {
            keepRatioOnChange(checked)
            handleMaintainHeightAR(width, checked, aspectRatio)
          }}
        />
      </div>
    </Fragment>
  )
}

ImagePicker.propTypes = {
  images: PropTypes.array.isRequired,
  url: PropTypes.string,
  urlOnChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  widthOnChange: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  heightOnChange: PropTypes.func.isRequired,
  keepRatio: PropTypes.bool.isRequired,
  keepRatioOnChange: PropTypes.func.isRequired,
  marginBottom: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  resetState: PropTypes.number.isRequired,
}

ImagePicker.defaultProps = {
  images: [],
  urlOnChange: () => console.log('urlOnChange missing'),
  width: 100,
  widthOnChange: () => console.log('widthOnChange missing'),
  height: 100,
  heightOnChange: () => console.log('heightOnChange missing'),
  keepRatio: false,
  keepRatioOnChange: () => console.log('keepRatioOnChange missing'),
  marginBottom: '10px',
  marginLeft: '5px',
  resetState: 1,
}

export default ImagePicker
