import React from 'react'
import { Select } from 'antd'

const options = [300, 400, 500, 600, 700, 800]

const FontWeightSelect = ({ value, onChange, disabled = false, style = {} }) => (
  <Select onChange={onChange} value={value} disabled={disabled} style={style}>
    {options.map(fontWeight => (
      <Select.Option key={fontWeight} value={fontWeight}>
        {fontWeight}
      </Select.Option>
    ))}
  </Select>
)

export default FontWeightSelect
