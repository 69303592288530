import axios from 'axios'
import config from '../config'
import _get from 'lodash/get'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'auth-token': config.apiToken,
}
axios.interceptors.response.use(
  response => {
    const data = _get(response, 'data', {})
    const success = _get(data, 'success', false)
    if (!success) {
      const error = _get(data, 'error', 'Unknown error')
      throw new Error(error)
    }
    return data
  },
  error => {
    // Handle Error
    return Promise.reject(error)
  }
)

export default axios
