import React from 'react'
import PropTypes from 'prop-types'
import FontWeightSelect from './FontWeightSelect'
import { Input, InputNumber, Select } from 'antd'

const FontPicker = ({
  marginBottom,
  marginLeft,
  inputValue,
  inputPlaceholder,
  inputOnChange,
  fontSizeValue,
  fontSizeOnChange,
  fontWeightValue,
  fontWeightOnChange,
  fontColorValue,
  fontColorOnChange,
  fontFamilyValue,
  fontFamilyOnChange,
  disabled,
  style,
  boldLabels,
}) => (
  <div style={style}>
    {typeof inputValue === 'string' && (
      <Input
        style={{ marginBottom }}
        value={inputValue}
        onChange={({ target }) => inputOnChange(target.value)}
        placeholder={inputPlaceholder}
        disabled={disabled}
      />
    )}
    <div style={{ marginBottom }}>
      <span style={{ ...(boldLabels && { fontWeight: 'bold' }) }}>Font Size:</span>
      <InputNumber
        style={{ marginLeft }}
        value={fontSizeValue}
        onChange={fontSizeOnChange}
        disabled={disabled}
      ></InputNumber>
      <span style={{ marginLeft, ...(boldLabels && { fontWeight: 'bold' }) }}>Font Weight:</span>
      <FontWeightSelect
        value={fontWeightValue}
        onChange={fontWeightOnChange}
        style={{ marginLeft }}
        disabled={disabled}
      />
      <span style={{ marginLeft, ...(boldLabels && { fontWeight: 'bold' }) }}>Color (Hex):</span>
      <Input
        style={{ marginLeft, width: '100px' }}
        value={fontColorValue}
        onChange={({ target }) => fontColorOnChange(target.value)}
        disabled={disabled}
      />
      <span style={{ marginLeft, ...(boldLabels && { fontWeight: 'bold' }) }}>Font Family:</span>
      <Select value={fontFamilyValue} onChange={fontFamilyOnChange} style={{ marginLeft, width: '100px' }}>
        {['Raleway', 'Lato', 'Arial'].map(font => (
          <Select.Option key={font} value={font}>
            {font}
          </Select.Option>
        ))}
      </Select>
    </div>
  </div>
)

FontPicker.propTypes = {
  marginBottom: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  inputPlaceholder: PropTypes.string.isRequired,
  inputOnChange: PropTypes.func.isRequired,
  fontSizeValue: PropTypes.number.isRequired,
  fontSizeOnChange: PropTypes.func.isRequired,
  fontWeightValue: PropTypes.number.isRequired,
  fontWeightOnChange: PropTypes.func.isRequired,
  fontColorValue: PropTypes.string.isRequired,
  fontColorOnChange: PropTypes.func.isRequired,
  fontFamilyValue: PropTypes.string.isRequired,
  fontFamilyOnChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  boldLabels: PropTypes.bool.isRequired,
}

FontPicker.defaultProps = {
  marginBottom: '10px',
  marginLeft: '5px',
  inputPlaceholder: '',
  inputOnChange: () => console.log('missing input onchange'),
  fontSizeValue: 14,
  fontSizeOnChange: () => console.log('missing fontSize onchange'),
  fontWeightValue: 400,
  fontWeightOnChange: () => console.log('missing fontWeight onchange'),
  fontColorValue: 'inherit',
  fontColorOnChange: () => console.log('missing fontColor onchange'),
  fontFamilyValue: 'Raleway',
  fontFamilyOnChange: () => console.log('missing fontFamily onchange'),
  disabled: false,
  style: {},
  boldLabels: false,
}

export default FontPicker
