import React, { Fragment } from 'react'
import Logo from './Logo'
import ProductImage from '../../components/ProductImage'
import ProductData from '../../components/ProductData'
import ProductName from '../../components/ProductName'
import PostItButtons from '../../components/PostItButtons'
import extractAssetData from '../../utils/extractAssetData'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setFormMapName } from '../../store/form'

const verticalSpacing = '25px'
export const dataKeys = ['Catalog Number', 'Serial Number', 'Batch Code', 'Device Number']

const ResMed = ({ data, isValid }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { name: assetName, data: assetData } = extractAssetData({
    data,
    keys: dataKeys,
  })
  return (
    <Fragment>
      <Logo />
      <ProductImage img='resmed-product.png' height='100px' width='' verticalSpacing={verticalSpacing} />
      <ProductName verticalSpacing={verticalSpacing} name={assetName} />
      <PostItButtons
        verticalSpacing={verticalSpacing}
        fontSize='1.2em'
        buttons={[
          {
            backgroundColor: '#e41d38',
            name: 'Request Service',
            onClick: () => {
              dispatch(setFormMapName('resMedRequestServiceForm'))
              history.push('/form')
            },
          },
          {
            backgroundColor: '#009bdd',
            name: 'Register Product',
            onClick: () => {
              dispatch(setFormMapName('resMedRegisterForm'))
              history.push('/form')
            },
          },
        ]}
      />
      <ProductData verticalSpacing={verticalSpacing} data={assetData} />
    </Fragment>
  )
}

export default ResMed
