import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ url, name, height, width, marginTop, styles }) => (
  <div style={{ textAlign: 'center', marginTop, ...styles }}>
    <img src={url} alt={name} style={{ height, width }} />
  </div>
)

Image.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  styles: PropTypes.object.isRequired,
}

Image.defaultProps = {
  url: '',
  name: '',
  height: '100px',
  width: '100px',
  marginTop: 0,
  styles: {},
}

export default Image
