const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS'
const RESET_INSTRUCTIONS = 'RESET_INSTRUCTIONS'

export const setInstructions = data => dispatch => dispatch({ type: SET_INSTRUCTIONS, data })

export const resetInstructions = () => dispatch => dispatch({ type: RESET_INSTRUCTIONS })

const initialState = {
  loaded: false,
  logo: null,
  video: null,
  manual: null,
  support: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTRUCTIONS:
      return { ...state, ...action.data, loaded: true }
    case RESET_INSTRUCTIONS:
      return initialState
    default:
      return state
  }
}
