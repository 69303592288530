import React, { Fragment } from 'react'
import ProductLogo from '../../components/ProductLogo'
import ProductImage from '../../components/ProductImage'
import ProductData from '../../components/ProductData'
import ProductName from '../../components/ProductName'
import PCATag from '../../components/PCATag'
import extractAssetData from '../../utils/extractAssetData'
import './ThreeM.css'

const verticalSpacing = '10px'
const red = '#ee1b2d'
export const dataKeys = ['Part Number', '3M Product Number', 'UPC', '3M ID', 'Case Quantity', 'Active Recalls']

const ResMed = ({ data, isValid }) => {
  const { name: assetName, data: assetData } = extractAssetData({
    data,
    keys: dataKeys,
  })
  return (
    <Fragment>
      <ProductLogo img='3m-logo.png' height='60px' width='' />
      <ProductImage img='3m-product.png' height='100px' width='' verticalSpacing={verticalSpacing} />
      <ProductName verticalSpacing={verticalSpacing} name={assetName} color={red} />
      <div style={{ padding: '5px', fontWeight: '600' }}>
        <div style={{ textAlign: 'center', marginTop: verticalSpacing }}>
          {"3M uses LocatorX's product certificate authority to fight product counterfeiting."}
        </div>
        <PCATag isValid={isValid} />
      </div>
      <ProductData
        verticalSpacing={verticalSpacing}
        data={assetData}
        infoText="This specific product's information:"
        infoColor='inherit'
      />
    </Fragment>
  )
}

export default ResMed
