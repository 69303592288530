import React from 'react'
import { useSelector } from 'react-redux'
import recipesMap from './recipesMap'
import CenterContainer from '../../components/CenterContainer'

const Recipes = () => {
  const mapName = useSelector(({ recipes: { mapName } }) => mapName)
  const Component = recipesMap[mapName]
  return <CenterContainer>{Component && <Component />}</CenterContainer>
}

export default Recipes
