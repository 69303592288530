import _get from 'lodash/get'
import to from 'await-to-js'
import { fetchAsset as fetchAssetAPI, fetchBatchAssets } from '../api'
import { setConnectPageId, fetchBuildConfigs } from './buildTool'
import determineTemplateConfig from '../utils/determineTemplateConfig'

const GET_ASSET_REQUEST = 'GET_ASSET_REQUEST'
const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS'
const GET_ASSET_FAILURE = 'GET_ASSET_FAILURE'

const getBatchAsset = async batchId => {
  try {
    const batchAssets = await fetchBatchAssets(batchId)
    const asset = _get(batchAssets, 'assets', [])[0]
    if (!asset) return null
    return asset.assetId
  } catch (err) {
    return null
  }
}

export const fetchAsset = assetId => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ASSET_REQUEST })
    // ---------------------------------------
    // TODO: determine if we should keep this logic, or go back to --> data = await fetchAssetAPI(assetId)
    let data
    const [assetErr, assetRes] = await to(fetchAssetAPI(assetId))
    if (assetErr || !assetRes) {
      const batchAssetId = await getBatchAsset(assetId)
      if (!batchAssetId) throw assetErr
      data = await fetchAssetAPI(batchAssetId)
    } else {
      data = assetRes
    }
    // ---------------------------------------
    await dispatch(fetchBuildConfigs())
    const configList = _get(getState(), 'buildTool.configs', [])
    const templateConifg = determineTemplateConfig({ configList, assetData: data })
    dispatch(setConnectPageId(templateConifg.id))
    dispatch({ type: GET_ASSET_SUCCESS, data })
  } catch (err) {
    console.error(`Error fetching assetId: ${assetId} -->`, err.message || err.toString())
    dispatch({ type: GET_ASSET_FAILURE })
  }
}

const initialState = {
  loading: false,
  loaded: false,
  err: null,
  id: '',
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_REQUEST:
      return { ...state, loading: true, err: null }
    case GET_ASSET_SUCCESS:
      return { ...state, loading: false, loaded: true, data: action.data, id: _get(action.data, 'asset.assetId', '') }
    case GET_ASSET_FAILURE:
      return { ...state, loading: false, err: 'An error occurred with fetching asset details' }
    default:
      return state
  }
}
