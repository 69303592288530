import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, message } from 'antd'
import config from '../config'

const RegistrationForm = ({ onSubmit, buttonStyle, verticalSpacing }) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      style={{ marginTop: verticalSpacing }}
      onFinish={() => {
        message.success({
          content: 'Successfully Registered!',
          duration: 4,
          style: { marginTop: '87vh' },
        })
        onSubmit(form.getFieldsValue())
      }}
    >
      <Form.Item name='name' rules={[{ required: true, message: 'Please enter a name' }]}>
        <Input placeholder='Name' size='large' />
      </Form.Item>
      <Form.Item name='email' rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
        <Input placeholder='Email' size='large' />
      </Form.Item>
      <Button type='primary' htmlType='submit' style={{ width: '100%', borderRadius: '5px', ...buttonStyle }}>
        Submit
      </Button>
    </Form>
  )
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonStyle: PropTypes.object.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

RegistrationForm.defaultProps = {
  onSubmit: () => {},
  buttonStyle: {},
  verticalSpacing: config.verticalSpacing,
}

export default RegistrationForm
