const deploymentEnvironments = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const {
  REACT_APP_DEPLOYMENT_ENV = deploymentEnvironments.DEVELOPMENT,
  REACT_APP_API_URL = 'https://api-staging.locatorx.com/lx-atlas/api',
  REACT_APP_API_TOKEN = 'f209f536-72c2-4ccf-a8c6-942c0ac9bb5f',
} = process.env

// Default Config
let config = {
  apiUrl: REACT_APP_API_URL,
  apiToken: REACT_APP_API_TOKEN,
  isDev: REACT_APP_DEPLOYMENT_ENV === deploymentEnvironments.DEVELOPMENT,
  verticalSpacing: '20px',
}

// Staging Config
if (REACT_APP_DEPLOYMENT_ENV === deploymentEnvironments.STAGING) {
  config = {
    ...config,
  }
}

// Production Config
if (REACT_APP_DEPLOYMENT_ENV === deploymentEnvironments.PRODUCTION) {
  config = {
    ...config,
  }
}

export default config
