import _find from 'lodash/find'
import _get from 'lodash/get'

export default ({ data = {}, keys = [] }) => {
  const { asset = {} } = data
  const { propertiesMap: assetPropertiesMap = {}, product = {} } = asset

  // Asset
  const { customDataFields: assetCustumDataFields = [] } = assetPropertiesMap

  // Product
  const { propertiesMap: productPropertiesMap = {} } = product

  return {
    name: _get(product, 'name', ''),
    assetId: _get(asset, 'assetId', ''),
    location: {
      latitude: _get(asset, 'latitude'),
      longitude: _get(asset, 'longitude'),
    },
    data: keys.map(key => ({
      name: key,
      key,
      description:
        product[key] ||
        productPropertiesMap[key] ||
        asset[key] ||
        _get(_find(assetCustumDataFields, { title: key }), 'value') ||
        'N/A',
    })),
  }
}
