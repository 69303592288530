import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { useMediaQuery } from 'react-responsive'
import config from '../config'
import PostItButtons from '../components/PostItButtons'

const ProductButtons = ({ name, onClick, color, postIt, verticalSpacing }) => {
  const isSmall = useMediaQuery({ query: '(max-width: 300px)' })

  return postIt ? (
    <div style={{ marginTop: verticalSpacing }}>
      <PostItButtons
        buttons={[
          { backgroundColor: color.first, name: name.first, onClick: onClick.first },
          { backgroundColor: color.second, name: name.second, onClick: onClick.second },
        ]}
      />
    </div>
  ) : (
    <div style={{ textAlign: 'center', marginTop: verticalSpacing }}>
      <Button style={{ marginRight: isSmall ? '0' : '20px', borderRadius: '5px' }} onClick={onClick.first}>
        {name.first}
      </Button>
      {isSmall && <br />}
      <Button style={{ borderRadius: '5px', marginTop: isSmall ? verticalSpacing : '0' }} onClick={onClick.second}>
        {name.second}
      </Button>
    </div>
  )
}

ProductButtons.propTypes = {
  name: PropTypes.shape({
    first: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    second: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }).isRequired,
  onClick: PropTypes.shape({
    first: PropTypes.func.isRequired,
    second: PropTypes.func.isRequired,
  }).isRequired,
  color: PropTypes.shape({
    first: PropTypes.string,
    second: PropTypes.string,
  }),
  postIt: PropTypes.bool.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

ProductButtons.defaultProps = {
  name: { first: 'First', second: 'Second' },
  onClick: { first: () => console.log('First Clicked'), second: () => console.log('Second Clicked') },
  color: {},
  postIt: false,
  verticalSpacing: config.verticalSpacing,
}

export default ProductButtons
