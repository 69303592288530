import React, { useState } from 'react'
import PropTypes from 'prop-types'

const PostItButton = ({
  backgroundColor,
  width,
  height,
  padding,
  children,
  onClick,
  fontSize,
  color,
  style,
  fontWeight,
  fontFamily,
  borderRadius,
  boxShadowBlur,
  boxShadowSpread,
}) => {
  const [hover, setHover] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)
  const noShadow = boxShadowBlur === 0 && boxShadowSpread === 0
  const boxShadow =
    !mouseDown && hover
      ? `-3px 3px ${boxShadowBlur}px ${boxShadowSpread}px #969696`
      : `-3px 3px ${boxShadowBlur + 1}px ${boxShadowSpread}px #b3b3b3`

  return (
    <div
      style={{
        backgroundColor,
        width,
        height,
        ...(!noShadow && { boxShadow }),
        color,
        padding,
        margin: '5px',
        float: 'left',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize,
        fontWeight,
        fontFamily,
        borderRadius: `${borderRadius}px`,
        ...style,
      }}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
    >
      {children}
    </div>
  )
}

PostItButton.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  padding: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fontSize: PropTypes.string.isRequired,
  fontWeight: PropTypes.number.isRequired,
  fontFamily: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  borderRadius: PropTypes.number.isRequired,
  boxShadow: PropTypes.number.isRequired,
}

PostItButton.defaultProps = {
  backgroundColor: '#00b1b0',
  width: '102px',
  height: '102px',
  padding: '5px',
  fontSize: '1em',
  fontWeight: 400,
  fontFamily: 'Raleway',
  onClick: () => {},
  color: '#fff',
  borderRadius: 0,
  boxShadowBlur: 4,
  boxShadowSpread: 0,
}

export default PostItButton
