const SET_RECIPES = 'SET_RECIPES'

export const setRecipesMapName = (mapName = '') => dispatch => dispatch({ type: SET_RECIPES, mapName })

const initialState = {
  mapName: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPES:
      return { ...state, mapName: action.mapName }
    default:
      return state
  }
}
