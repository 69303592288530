import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { medtronicBlue, fontSize } from './MedtronicsDemo'
import { createAssetAction } from '../api'

const inputStyle = { width: '100%', margin: '20px auto auto auto' }

const validatePhoneNumber = number =>
  number
    .trim()
    .split('')
    .filter(char => new RegExp(/^[0-9]*$/).test(char)).length < 10

const ServiceForm = ({ assetId, serial, model, setShowForm, setSnackbarOpen }) => {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [errors, setErrors] = useState({ name: false, number: false })

  useEffect(() => {
    setSnackbarOpen(false)
    return () => {
      setNumber('')
      setName('')
    }
  }, []) // eslint-disable-line

  const handleSubmit = () => {
    const formErrors = {
      name: name.trim().length === 0,
      number: validatePhoneNumber(number),
    }
    setErrors(formErrors)
    const hasErrors = formErrors.name || formErrors.number
    if (!hasErrors) {
      createAssetAction({
        assetId,
        propertiesMap: { serial, model, name, phoneNumber: number },
        action: 'Request Service',
      }).then(res => console.log('Created action event:', res))

      setSnackbarOpen(true, 'Successfully sent service request!')
      setShowForm(false)
      setTimeout(() => setSnackbarOpen(false), 5000)
    }
  }

  return (
    <div style={{ width: '95%', margin: '20px auto auto auto' }}>
      <ArrowBackIcon style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={() => setShowForm(false)} />
      <Card>
        <CardContent>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Service Request</span>
          <Divider style={{ margin: '10px 0' }} />
          <span>
            We have all the information we need about your ventilator - now just give us your name and phone number and
            we will contact you within 30 minutes to schedule a service on your Puritan Bennett 560 Ventilator
          </span>
        </CardContent>
      </Card>
      <form
        onKeyDown={({ keyCode }) => {
          if (keyCode === 13) {
            handleSubmit()
          }
        }}
      >
        <TextField
          id='outlined-basic'
          label='Name'
          variant='outlined'
          style={inputStyle}
          error={errors.name}
          helperText={errors.name ? 'Please enter a name' : null}
          value={name}
          onChange={({ target: { value } }) => {
            if (errors.name && value) {
              setErrors({ ...errors, name: false })
            }
            setName(value)
          }}
        />
        <TextField
          id='outlined-basic'
          label='Phone Number'
          variant='outlined'
          style={inputStyle}
          error={errors.number}
          helperText={errors.number ? 'Please enter a valid phone number' : null}
          value={number}
          onChange={({ target: { value } }) => {
            if (errors.number && value) {
              setErrors({ ...errors, number: validatePhoneNumber(value) })
            }
            setNumber(value)
          }}
        />
        <Button
          variant='contained'
          size='large'
          style={{
            backgroundColor: medtronicBlue,
            color: '#fff',
            fontSize,
            margin: '20px auto auto auto',
            width: '100%',
            display: 'flex',
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

export default ServiceForm
