import React, { useState, useEffect, Fragment } from 'react'
import { Button, Radio, Spin, message, Select, Divider, Input, Form, Popconfirm, Alert, Tooltip } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import CenterContainer from '../../components/CenterContainer'
import FindProductModal from './FindProductModal'
import useSetState from '../../hooks/useSetState'
import axios from '../../api/axios'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBuildConfigs, createBuildConfig, updateBuildConfig, deleteBuildConfig } from '../../store/buildTool'
import templateInitialData from './templateInitialData'
import templateComponents from './templateComponents'
import './styles.css'

const ProductFieldError = ({ message: propsMessage, config, productId, fetchProduct }) => (
  <div>
    {propsMessage}
    <Popconfirm
      title='Are you sure?'
      onConfirm={async () => {
        try {
          await axios.put(`/products/${productId}`, config)
          fetchProduct()
        } catch (err) {
          console.error('Error updating product:', err)
          message.error('Error updating product')
        }
      }}
    >
      <span style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer', marginLeft: '10px' }}>Fix</span>
    </Popconfirm>
  </div>
)

const viewport = {
  browser: { width: 600, height: '100vh' },
  android: { width: 360, height: 750 },
  iphone: { width: 375, height: 667 },
  plus: { width: 414, height: 800 },
}

const addConfig = 'addConfig'
const inputWidth = '300px'
const marginBottom = '10px'

const FormData = ({ label, data }) => (
  <div style={{ marginBottom }}>
    <span style={{ fontWeight: 'bold' }}>{label}</span>
    <br />
    {data}
  </div>
)

const determineNewNameError = ({ configList = [], value = '' }) => {
  const isDuplicate = configList.some(t => t.name.toLowerCase().trim() === value.toLowerCase().trim())
  return isDuplicate ? 'Name is not unique. Please choose a different one.' : null
}

const determineDimensionsTooltip = (dimensions = {}) => {
  const { width, height } = dimensions
  return `${width} (width) x ${height} (height)`
}

const Preview = ({ Page, ...props }) => {
  const [radioValue, setRadioValue] = useState('browser')
  const [action, setAction] = useState(null)
  const { width, height } = viewport[radioValue] || viewport['browser']
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {action && (
          <Button onClick={() => setAction(null)} style={{ float: 'left' }} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        )}
        <Radio.Group value={radioValue} onChange={({ target }) => setRadioValue(target.value)}>
          <Tooltip title={determineDimensionsTooltip(viewport.browser)}>
            <Radio value='browser'>Browser</Radio>
          </Tooltip>
          <Tooltip title={determineDimensionsTooltip(viewport.android)}>
            <Radio value='android'>Android</Radio>
          </Tooltip>
          <Tooltip title={determineDimensionsTooltip(viewport.iphone)}>
            <Radio value='iphone'>iPhone</Radio>
          </Tooltip>
          <Tooltip title={determineDimensionsTooltip(viewport.plus)}>
            <Radio value='plus'>Android/iPhone+</Radio>
          </Tooltip>
        </Radio.Group>
      </div>
      <CenterContainer
        style={{
          border: 'solid #c4c4c4 1px',
          marginTop: '20px',
          maxWidth: width,
          maxHeight: height,
          overflow: 'scroll',
        }}
      >
        {Page && (
          <Page {...props} width={width} height={height} isPreview={true} action={action} setAction={setAction} />
        )}
      </CenterContainer>
    </div>
  )
}

const isFormDirty = (currentState, originalState) => {
  const pickData = ['formData', 'connectPageName', 'newName']
  return !_isEqual(_pick(currentState, pickData), _pick(originalState, pickData))
}

const initialState = {
  loading: false,
  productId: '',
  productData: {},
  batchId: '',
  configId: '',
  configData: {},
  templateId: templateInitialData[0].id,
  templateData: {},
  newName: '',
  newNameError: null,
  formData: {},
  pcaIsValid: true,
  connectPageName: '',
  resetState: 1,
}

// TESTING PURPOSES
// assetId: 517f5fa2-5588-494e-9437-49300564de50
// bactchId: 46e49260-adba-4572-b49c-2a70963a7ecf

const Build = () => {
  const dispatch = useDispatch()
  const configList = useSelector(state => _get(state, 'buildTool.configs', []))
  const configListLoading = useSelector(state => _get(state, 'buildTool.configsLoading', false))
  const [originalState, setOriginalState] = useState(initialState)
  const [hasNewConfigId, setHasNewConfigId] = useState(false)
  const [state, setState] = useSetState(initialState)
  const {
    loading,
    productId,
    productData,
    batchId,
    configId,
    formData,
    templateId,
    newName,
    newNameError,
    pcaIsValid,
    connectPageName,
    resetState,
  } = state

  useEffect(() => {
    dispatch(fetchBuildConfigs())
  }, []) // eslint-disable-line

  useEffect(() => {
    fetchProduct()
  }, [productId]) // eslint-disable-line

  useEffect(() => {
    setHasNewConfigId(true)
  }, [configId])

  useEffect(() => {
    // LOAD SAVED CONFIG
    if (configId && configId !== addConfig) {
      const configData = _find(configList, { id: configId }) || {}
      const productId = _get(configData, 'productId', '')
      const templateId = _get(configData, 'templateId', '')
      const templateData = _find(templateInitialData, { id: templateId }) || {}
      const connectPageName = _get(configData, 'connectPageName', '')
      setState({ configData, productId, templateId, templateData, connectPageName })
    }
  }, [configId, configList, templateInitialData]) // eslint-disable-line

  useEffect(() => {
    // LOAD NEW CONFIG
    if (configId === addConfig && templateId) {
      const templateData = _find(templateInitialData, { id: templateId }) || {}
      setState({ templateData })
    }
  }, [configId, templateId]) // eslint-disable-line

  const handleNewNameChange = ({ target: { value } }) =>
    setState({
      newName: value,
      newNameError: determineNewNameError({ configList, value: value.trim() }),
    })

  const fetchProduct = () => {
    if (productId) {
      setState({ loading: true })
      axios
        .get(`/products/${productId}`)
        .then(res => setState({ productData: _get(res, 'product', {}) }))
        .catch(err => message.error(`Unable to load product data - ${err}`))
        .finally(() => setState({ loading: false }))
    }
  }

  const hasProductData = !_isEmpty(productData)
  const newNameIsEmpty = configId === addConfig && !newName.trim()
  const formIsDirty = isFormDirty(state, originalState)
  const discardBtnDisabled = !configId || !formIsDirty
  const saveBtnDisabled = !hasProductData || discardBtnDisabled
  const deleteBtnDisabled = !configId
  const TC = templateComponents[templateId]
  const invalidUrlBase =
    hasProductData && !_get(productData, 'urlBase', '').toLowerCase().includes('https://democp.locatorx.com')
  const invalidUrlTarget = hasProductData && _get(productData, 'urlTarget', '').toLowerCase() !== 'consumer'
  const ProductSelect = () => (
    <Select
      style={{ width: inputWidth, marginBottom }}
      onChange={configId => {
        const foundConfig = _find(configList, { id: configId }) || {}
        const newState = configId === addConfig ? { configId } : { configData: foundConfig, configId }
        return setState({ ...initialState, ...newState })
      }}
      value={configId}
    >
      <Select.Option key={addConfig} value={addConfig}>
        + Add New Page
      </Select.Option>
      {configList
        .sort((a, b) => _get(a, 'name', '').localeCompare(_get(b, 'name', '')))
        .map(config => (
          <Select.Option key={config.id} value={config.id}>
            {config.name}
          </Select.Option>
        ))}
    </Select>
  )

  return (
    <div style={{ minWidth: '1460px' }}>
      {loading || configListLoading ? (
        <Spin size='large' style={{ marginLeft: '25%', marginTop: '50px' }} />
      ) : (
        <div style={{ width: '50%', float: 'left', margin: '20px 0 10px 10px' }}>
          {templateId ? (
            <FormData label={`Select${configId ? 'ed' : ' a'} Page`} data={<ProductSelect />} />
          ) : (
            <Fragment>
              <div>Select a saved page or add a new one</div>
              <ProductSelect />
            </Fragment>
          )}
          {!hasProductData && configId && (
            <Fragment>
              <div>Select a template</div>
              <Select
                style={{ width: inputWidth, marginBottom }}
                value={templateId}
                onChange={templateId => setState({ templateId })}
              >
                {templateInitialData.map(template => (
                  <Select.Option key={template.id} value={template.id}>
                    {template.id}
                  </Select.Option>
                ))}
              </Select>
            </Fragment>
          )}
          {configId && hasProductData && <FormData label='Selected Template' data={templateId} />}
          {templateId && configId && !hasProductData && (
            <FindProductModal
              onSubmit={({ productId, copyPage, batchId }) => {
                const foundCopy = configList.find(page => page.connectPageName === copyPage)
                const configData =
                  copyPage !== 'None' && foundCopy
                    ? _omit(foundCopy, ['selectedData', 'connectPageName', 'name', 'id', 'productId', 'batchId'])
                    : {}
                setState({ productId, configData, batchId })
              }}
              hasPrevProduct={hasProductData}
              configList={configList}
            />
          )}
          {hasProductData && configId === addConfig && (
            <FormData
              label='Page Name'
              data={
                <Form.Item
                  validateStatus={newNameError ? 'error' : 'success'}
                  help={newNameError || ''}
                  style={{ marginBottom: newNameError ? '0' : '-20px' }}
                >
                  <Input
                    placeholder='Enter new page name'
                    style={{ width: inputWidth }}
                    value={newName}
                    onChange={handleNewNameChange}
                  />
                </Form.Item>
              }
            />
          )}
          <div style={{ marginTop: '10px' }}>
            {hasProductData && (
              <Tooltip title={discardBtnDisabled ? 'No changes have been made' : ''}>
                <Popconfirm
                  disabled={saveBtnDisabled || newNameIsEmpty}
                  placement='topLeft'
                  onConfirm={() => {
                    if (configId === addConfig) {
                      if (newNameError) return
                      if (!newName) return setState({ newNameError: 'Please provide a page name' })
                      // Handle saving new config
                      dispatch(
                        createBuildConfig({
                          ...formData,
                          connectPageName: newName,
                          templateId,
                          productId,
                          batchId,
                        })
                      )
                    } else {
                      // Handle updating config
                      dispatch(updateBuildConfig(formData))
                    }
                    setState(initialState)
                  }}
                  title={
                    configId === addConfig
                      ? 'This will save your newly created page. Are you sure?'
                      : 'This will overwrite the page that you previously saved, are you sure?'
                  }
                >
                  <Button
                    type='primary'
                    disabled={saveBtnDisabled}
                    onClick={() => {
                      if (newNameIsEmpty) {
                        setState({ newNameError: 'Please provide a page name' })
                      }
                    }}
                  >
                    Save
                  </Button>
                </Popconfirm>
              </Tooltip>
            )}
            {configId && hasProductData && (
              <Tooltip title={discardBtnDisabled ? 'No changes have been made' : ''}>
                <Popconfirm
                  disabled={discardBtnDisabled}
                  placement='topLeft'
                  title='This will discard your current changes, are you sure?'
                  onConfirm={() => setState({ ...originalState, resetState: resetState + 1 })}
                >
                  <Button style={{ marginLeft: '10px' }} disabled={discardBtnDisabled}>
                    Discard Changes
                  </Button>
                </Popconfirm>
              </Tooltip>
            )}
            {configId && configId !== addConfig && (
              <Popconfirm
                placement='topLeft'
                title={`Are you sure you want to delete page "${connectPageName}"`}
                onConfirm={() => {
                  dispatch(deleteBuildConfig(configId))
                  return setState(initialState)
                }}
                disabled={deleteBtnDisabled}
              >
                <Button type='danger' style={{ marginLeft: '10px' }} disabled={deleteBtnDisabled}>
                  Delete
                </Button>
              </Popconfirm>
            )}
            {configId === addConfig && (
              <Popconfirm title='Are you sure you want to cancel?' onConfirm={() => setState(initialState)}>
                <Button style={{ marginLeft: hasProductData ? '10px' : '0' }}>Cancel</Button>
              </Popconfirm>
            )}
          </div>
          <Divider style={{ margin: '10px 0' }} />
          {invalidUrlTarget && (
            <Alert
              closable
              style={{ marginBottom }}
              type='warning'
              message={
                <ProductFieldError
                  message={`Warning: Invalid URL Target associated with product: ${_get(
                    productData,
                    'urlTarget',
                    ''
                  )} (should be - Consumer)`}
                  fetchProduct={fetchProduct}
                  config={{ urlTarget: 'Consumer' }}
                  productId={productId}
                />
              }
            />
          )}
          {invalidUrlBase && (
            <Alert
              closable
              style={{ marginBottom }}
              type='warning'
              message={
                <ProductFieldError
                  message={`Warning: Invalid URL Base associated with product: ${_get(
                    productData,
                    'urlBase'
                  )} (should be -
                https://democp.locatorx.com)`}
                  fetchProduct={fetchProduct}
                  config={{ urlBase: 'https://democp.locatorx.com' }}
                  productId={productId}
                />
              }
            />
          )}
          {hasProductData && TC && (
            <TC.form
              {...state}
              resetState={resetState}
              onChange={formData => {
                setState({ formData })
                if (hasNewConfigId) {
                  setHasNewConfigId(false)
                  setOriginalState({ ...state, formData })
                }
              }}
              isValid={pcaIsValid => setState({ pcaIsValid })}
            />
          )}
          {hasProductData && !TC && <Alert type='error' message='No template components' />}
        </div>
      )}
      <div style={{ float: 'right', width: '600px', margin: '10px 10px 0 0' }}>
        <Preview Page={TC ? TC.page : null} {...state} isValid={pcaIsValid} />
      </div>
    </div>
  )
}

export default Build
