import React from 'react'
import { Modal, Button, Input, Select, message, Alert } from 'antd'
import useSetState from '../../hooks/useSetState'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _noop from 'lodash/noop'
import _find from 'lodash/find'
import axios from '../../api/axios'

const determineSelectedProductError = ({ configList = [], productId = '', batchId = '' }) => {
  const foundDuplicate = _find(configList, { batchId, productId })
  return foundDuplicate
    ? `There is already a page connected with this product/batch - "${foundDuplicate.connectPageName}"`
    : null
}

const initialState = {
  visible: false,
  textId: '',
  productData: null,
  productIds: [],
  productId: '',
  batchId: '',
  loading: false,
  loaded: false,
  selectError: null,
  copyPage: 'None',
}

const FindAssetModal = ({ onSubmit, hasPrevProduct, configList }) => {
  const [
    { visible, textId, productId, batchId, productData, productIds, loading, loaded, selectError, copyPage },
    setState,
  ] = useSetState(initialState)

  const fetchData = async () => {
    try {
      setState({ loading: true })
      const productIds = []
      const [batches, assets, products] = await Promise.allSettled([
        axios.get(`/batches/${textId}`),
        axios.get(`/assets/${textId}`),
        axios.get(`/products/${textId}`),
      ])
      _get(batches, 'value.batch.topologies', []).forEach(({ productId }) => productIds.push(productId))
      const assetProductId = _get(assets, 'value.asset.productId') || _get(assets, 'value.asset.product.productId')
      const assetBatchId = _get(assets, 'value.asset.batchId') || _get(assets, 'value.asset.batch.batchId') || ''
      if (assetProductId) productIds.push(assetProductId)
      const productProductId = _get(products, 'value.product.productId')
      if (productProductId) productIds.push(productProductId)
      const productData = []
      for await (const productId of productIds) {
        const res = await axios.get(`/products/${productId}`)
        const product = _get(res, 'product')
        if (product) productData.push(product)
      }

      const productId = productIds[0] || ''
      const batchId = _get(batches, 'value.batch.batchId') || assetBatchId
      setState({
        productIds,
        productData,
        productId,
        batchId,
        loaded: true,
        selectError: determineSelectedProductError({ configList, productId, batchId }),
      })
    } catch (err) {
      message.error(`Batch not found - ${err}`)
    } finally {
      setState({ loading: false })
    }
  }

  const handleSubmit = () => {
    if (determineOkDisabled()) return
    onSubmit({ productId, copyPage, batchId })
    setState(initialState)
  }

  const hasProductData = !_isEmpty(productData)
  const hasProductIds = !_isEmpty(productIds)
  const determineOkDisabled = () => {
    if (selectError) return true
    if (textId && !hasProductIds) return false
    if (productId) return false
    return true
  }

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => setState(initialState)}
        okText={hasProductData ? 'Submit' : 'Next'}
        onOk={productId ? handleSubmit : hasProductIds ? _noop : fetchData}
        okButtonProps={{ disabled: determineOkDisabled() }}
        confirmLoading={loading}
      >
        <div style={{ fontWeight: 'bold' }}>Enter Batch, Product or Asset ID:</div>
        <Input disabled={hasProductData} value={textId} onChange={({ target }) => setState({ textId: target.value })} />
        {hasProductData && (
          <div style={{ marginTop: '10px' }}>
            <div style={{ fontWeight: 'bold' }}>Select Product:</div>
            <Select
              style={{ width: '100%' }}
              value={productId}
              onChange={productId =>
                setState({ productId, selectError: determineSelectedProductError({ configList, productId, batchId }) })
              }
            >
              {productData.map(product => (
                <Select.Option key={product.productId} value={product.productId}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {hasProductData && (
          <div style={{ marginTop: '10px' }}>
            <div style={{ fontWeight: 'bold' }}>Copy Existing Page:</div>
            <Select style={{ width: '100%' }} defaultValue='None' onChange={copyPage => setState({ copyPage })}>
              {[...configList, { name: 'None' }].map(config => (
                <Select.Option key={config.name} value={config.name}>
                  {config.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {loaded && !hasProductIds && (
          <Alert
            style={{ marginTop: '10px' }}
            message='Could not find any products associated with batch'
            type='error'
          />
        )}
        {selectError && <Alert style={{ marginTop: '10px' }} type='error' message={selectError} />}
        {hasProductData && (
          <Button style={{ marginTop: '10px' }} onClick={() => setState({ ...initialState, visible: true })}>
            Reset
          </Button>
        )}
      </Modal>
      <Button
        style={{ margin: '10px 0' }}
        type={hasPrevProduct ? 'default' : 'primary'}
        onClick={() => setState({ visible: !visible })}
      >
        {hasPrevProduct ? 'Select Different Product' : 'Select Product'}
      </Button>
    </div>
  )
}

export default FindAssetModal
