import React, { useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _startCase from 'lodash/startCase'
import { Button, Modal, InputNumber, Input, Popconfirm, Alert, Select, Tooltip } from 'antd'
import useSetState from '../../../hooks/useSetState'
import Label from './Label'
import PostItButton from '../../../components/PostItButton'
import DraggableTable from './DraggableTable'
import { findAndReplaceObject, generateRandomString } from './utils'
import FormBuilder from '../FormBuilder'
import VideoBuilder, { videoPropsInitialState } from '../VideoBuilder'
import PictureBuilder, { picturePropsInitialState } from '../PictureBuilder'
import ActionPageBuilder from '../ActionPageBuilder'
import FontPicker from './FontPicker'

const marginBottom = '10px'
const marginLeft = '5px'
const marginRight = '10px'

export const buttonActions = {
  CustomForm: 'CustomForm',
  VideoPlaylist: 'VideoPlaylist',
  PictureList: 'PictureList',
  ExternalLink: 'ExternalLink',
  PageBuilder: 'PageBuilder',
}

const ButtonActionSelect = ({ value, onChange }) => (
  <div style={{ marginBottom }}>
    <Select style={{ width: '100%' }} onChange={onChange} value={value} placeholder='Pick an action'>
      {Object.values(buttonActions).map(action => (
        <Select.Option key={action} value={action}>
          {_startCase(action)}
        </Select.Option>
      ))}
    </Select>
  </div>
)

export const initialButtonState = {
  backgroundColor: '#0071B9',
  width: 100,
  height: 100,
  color: '#fff',
  fontSize: 14,
  fontWeight: 400,
  fontFamily: 'Raleway',
  borderRadius: 0,
  boxShadowBlur: 4,
  boxShadowSpread: 0,
  name: '',
  key: '',
  action: buttonActions.RegistrationForm,
  actionProps: {},
  customForm: {},
  videoPlaylist: [],
  videoProps: videoPropsInitialState,
  pictureList: [],
  pictureProps: picturePropsInitialState,
  externalLink: '',
  customPageItems: [],
}

export const ButtonFormOnly = ({ state, setState, buildState = {} }) => {
  const {
    backgroundColor,
    width,
    height,
    color,
    fontSize,
    name,
    action,
    fontWeight = 400,
    fontFamily = 'Raleway',
    borderRadius,
    boxShadowBlur,
    boxShadowSpread,
    videoPlaylist,
    videoProps,
    pictureList,
    pictureProps,
    externalLink,
    customPageItems,
  } = state

  return (
    <>
      <Label name='Name' />
      <Input
        style={{ marginBottom }}
        value={name}
        onChange={({ target }) => setState({ name: target.value })}
        placeholder='Enter a button name'
      />
      <Label name='Action' />
      <ButtonActionSelect value={action} onChange={action => setState({ action })} />
      {action === buttonActions.CustomForm && (
        <FormBuilder
          formState={
            _isEmpty(state.customForm)
              ? {
                  buttonProps: {
                    backgroundColor,
                    fontColor: color,
                    fontSize,
                    fontWeight,
                    fontFamily,
                    borderRadius,
                    boxShadowBlur,
                    boxShadowSpread,
                    width,
                    height,
                  },
                }
              : state.customForm
          }
          onSave={customForm => setState({ customForm })}
        />
      )}
      {action === buttonActions.VideoPlaylist && (
        <VideoBuilder
          onSave={(videoPlaylist, videoProps) => setState({ videoPlaylist, videoProps })}
          playlist={videoPlaylist}
          pageProps={videoProps}
        />
      )}
      {action === buttonActions.PictureList && (
        <PictureBuilder
          onSave={(pictureList, pictureProps) => setState({ pictureList, pictureProps })}
          pictureList={pictureList}
          pageProps={pictureProps}
        />
      )}
      {action === buttonActions.ExternalLink && (
        <div>
          <Label name='External Link URL' />
          <Input value={externalLink} onChange={({ target }) => setState({ externalLink: target.value })} />
          <Alert style={{ marginTop: '10px' }} type='info' message='Link must start with - http(s)://' />
        </div>
      )}
      {action === buttonActions.PageBuilder && (
        <ActionPageBuilder
          items={customPageItems}
          setItems={customPageItems => setState({ customPageItems })}
          buildState={buildState}
        />
      )}
      <Label name='Background Color' />
      <Input
        style={{ marginBottom }}
        value={backgroundColor}
        onChange={({ target }) => setState({ backgroundColor: target.value })}
      />
      <div>
        <Label name='Width' />
        <InputNumber style={{ marginLeft, marginRight }} value={width} onChange={width => setState({ width })} />
        <Label name='Height' />
        <InputNumber style={{ marginLeft, marginRight }} value={height} onChange={height => setState({ height })} />
        <br />
        <br />
        <Label name='Border Rounding' />
        <InputNumber
          min={0}
          style={{ marginLeft, marginRight }}
          value={borderRadius}
          onChange={borderRadius => setState({ borderRadius })}
        />
        <Label name='Shadow Blur' />
        <InputNumber
          min={0}
          style={{ marginLeft, marginRight }}
          value={boxShadowBlur}
          onChange={boxShadowBlur => setState({ boxShadowBlur })}
        />
        <Label name='Shadow Spread' />
        <InputNumber
          min={0}
          style={{ marginLeft, marginRight }}
          value={boxShadowSpread}
          onChange={boxShadowSpread => setState({ boxShadowSpread })}
        />
        <br />
        <br />
        <FontPicker
          fontSizeValue={fontSize}
          fontSizeOnChange={fontSize => setState({ fontSize })}
          fontWeightValue={fontWeight}
          fontWeightOnChange={fontWeight => setState({ fontWeight })}
          fontColorValue={color}
          fontColorOnChange={color => setState({ color })}
          fontFamilyValue={fontFamily}
          fontFamilyOnChange={fontFamily => setState({ fontFamily })}
          boldLabels={true}
        />
      </div>
      <div style={{ marginTop: '40px', marginLeft: '276px' }}>
        <PostItButton
          style={{ float: '' }}
          backgroundColor={backgroundColor}
          width={`${width}px`}
          height={`${height}px`}
          color={color}
          fontSize={`${fontSize}px`}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          borderRadius={borderRadius}
          boxShadowBlur={boxShadowBlur}
          boxShadowSpread={boxShadowSpread}
        >
          <div style={{ margin: 'auto' }}>{name}</div>
        </PostItButton>
      </div>
    </>
  )
}

const ButtonForm = ({ style = {}, btnState = {}, onSubmit, onDelete, buildState }) => {
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState(null)
  const [state, setState] = useSetState({ ...initialButtonState, ...btnState })
  const { backgroundColor, name } = state
  const isNew = _isEmpty(btnState)

  const handleClose = (isDelete = false) => {
    if (isNew || isDelete) {
      setError(null)
      setState(initialButtonState)
    }
    setVisible(false)
  }

  const handleSubmit = () => {
    if (!name) {
      setError('Please enter a name')
      return
    }
    setError(null)
    // TODO: Add action props?
    onSubmit({ ...state, key: isNew ? generateRandomString() : state.key, actionProps: {} })
    handleClose()
  }

  const handleDelete = () => {
    onDelete(state.key)
    handleClose(true)
  }

  return (
    <div style={{ float: 'left', ...style }}>
      <Modal visible={visible} width='800px' onCancel={() => handleClose()} footer={null}>
        <ButtonFormOnly state={state} setState={setState} buildState={buildState} />
        <div style={{ marginTop: '40px' }}>
          <Button type='primary' onClick={handleSubmit}>
            Save
          </Button>
          {!isNew && (
            <Popconfirm onConfirm={handleDelete} title='Are you sure you want to delete this button?'>
              <Button type='danger' style={{ marginLeft: '10px' }}>
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>
        <br />
        <span>*** You have to save the entire form for this to save permanently</span>
        {error && <Alert type='warning' message={error} style={{ marginTop: '10px' }} />}
      </Modal>
      <Button
        type={isNew ? 'primary' : 'default'}
        onClick={() => setVisible(!visible)}
        style={{ backgroundColor: isNew ? '' : backgroundColor }}
      >
        {isNew ? '+ Add New Button' : name}
      </Button>
    </div>
  )
}

const SortButtons = ({ buttons, setButtons }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div style={{ float: 'left', marginLeft: '10px' }}>
      <Modal visible={visible} width='700px' onCancel={() => setVisible(false)} footer={null}>
        <DraggableTable
          data={buttons.map((btn, index) => ({ ...btn, index }))}
          setData={setButtons}
          columns={[{ title: 'Name', dataIndex: 'name' }]}
        />
      </Modal>
      <Tooltip title={_isEmpty(buttons) ? 'No buttons added' : ''}>
        <Button disabled={_isEmpty(buttons)} type='dashed' onClick={() => setVisible(!visible)}>
          Change Order
        </Button>
      </Tooltip>
    </div>
  )
}

const ButtonBuilder = ({ buttons, setButtons, buildState }) => {
  const handleSaveButton = btn => setButtons(findAndReplaceObject({ data: buttons, obj: btn, key: 'key' }))
  const handleDeleteButton = key => setButtons(buttons.filter(btn => btn.key !== key))

  return (
    <div style={{ marginTop: '5px' }}>
      <ButtonForm onSubmit={handleSaveButton} onDelete={handleDeleteButton} />
      <SortButtons buttons={buttons} setButtons={setButtons} />
      <br />
      <br />
      <div style={{ marginTop: '10px' }}>
        {buttons.map(btn => (
          <ButtonForm
            key={btn.key}
            style={{ margin: '5px' }}
            onSubmit={handleSaveButton}
            onDelete={handleDeleteButton}
            btnState={btn}
            buildState={buildState}
          />
        ))}
      </div>
    </div>
  )
}

export default ButtonBuilder
