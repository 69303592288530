import React, { Fragment } from 'react'
import { Tag } from 'antd'
import config from '../config'
import PropTypes from 'prop-types'

const gutter = '5px'

const PCATag = ({
  isValid,
  verticalSpacing,
  fontSize,
  fontWeight,
  color,
  validMessage,
  invalidMessage,
  showTag,
  fontFamily,
}) => {
  return (
    <div style={{ textAlign: 'center', marginTop: verticalSpacing }}>
      {isValid ? (
        <div style={{ fontSize, fontWeight, color, fontFamily }}>
          {showTag && (
            <Fragment>
              {'The'}
              <Tag color={'#9ec670'} style={{ marginLeft: gutter, fontSize }}>
                {'✓ Valid'}
              </Tag>
            </Fragment>
          )}
          {validMessage}
        </div>
      ) : (
        <div style={{ color: '#ff4d4f', fontWeight: 'bold' }}>{invalidMessage}</div>
      )}
    </div>
  )
}

PCATag.propTypes = {
  isValid: PropTypes.bool.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fontSize: PropTypes.string.isRequired,
  fontWeight: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  validMessage: PropTypes.string.isRequired,
  invalidMessage: PropTypes.string.isRequired,
  showTag: PropTypes.bool.isRequired,
  fontFamily: PropTypes.string.isRequired,
}

PCATag.defaultProps = {
  isValid: false,
  verticalSpacing: config.verticalSpacing,
  fontSize: '14px',
  fontWeight: '400',
  color: 'inherit',
  validMessage:
    "message tells you that no counterfeit products bearing the same code have been detected. Your product's information should match what is shown below",
  invalidMessage: 'Warning: Potential problem noted - Compare data below with product',
  showTag: true,
  fontFamily: 'Raleway',
}

export default PCATag
