import React from 'react'

const NotFound = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
      }}
    >
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <div style={{ fontSize: '5em', fontWeight: '300' }}>404</div>
        <div style={{ fontSize: '1.2em' }}>{"Sorry, this page doesn't exist 😕"}</div>
      </div>
    </div>
  )
}

export default NotFound
