import React, { Fragment } from 'react'
import { Table } from 'antd'
import greyjoyProduct from '../../../assets/greyjoy-product.jpg'

const imgHeight = '4em'

const RetailLocations = () => {
  return (
    <Fragment>
      <iframe
        src='https://www.google.com/maps/d/u/0/embed?mid=1oJsTlBa4FJH21K-khSpwu-it2vYMtE2F'
        width='100%'
        height='300'
        title='Greyjoy Retail Locations'
      ></iframe>
      <div style={{ textAlign: 'center', display: 'flex', lineHeight: imgHeight }}>
        <div style={{ color: '#214931', fontSize: '1.3em', fontWeight: '600', marginLeft: 'auto', marginRight: '5px' }}>
          Retail Locations Selling
        </div>
        <img
          src={greyjoyProduct}
          alt='Greyjoy Beer'
          style={{ height: imgHeight, float: 'left', marginRight: 'auto' }}
        />
      </div>
      <Table
        size='small'
        pagination={{ pageSize: 30, hideOnSinglePage: true }}
        columns={[
          {
            title: 'Location Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: val => <a href={`https://maps.google.com/?q=${val}`}>{val}</a>,
          },
        ]}
        dataSource={[
          {
            name: 'Local Vine Liquor Megastore',
            key: 'Local Vine Liquor Megastore',
            address: '3101 Cobb Pkwy SE, Atlanta, GA 30339',
          },
          {
            name: 'Bottle House',
            key: 'Bottle House',
            address: '3 Fairburn Rd SW, Atlanta, GA 30331',
          },
          {
            name: "Green's Liquor Store",
            key: "Green's Liquor Store",
            address: '2614 Buford Hwy NE, Atlanta, GA 30324',
          },
          {
            name: 'Tower Beer, Wine & Spirits',
            key: 'Tower Beer, Wine & Spirits',
            address: '2161 Piedmont Rd NE, Atlanta, GA 30324',
          },
          {
            name: 'Atl Liquor Store',
            key: 'Atl Liquor Store',
            address: '4790 Peachtree Industrial Blvd #110, Norcross, GA 30071',
          },
          {
            name: 'Peachtree Road Liquor Store',
            key: 'Peachtree Road Liquor Store',
            address: '1895 Peachtree Rd NW, Atlanta, GA 30309',
          },
          {
            name: "Green's Beverages - Ponce De Leon",
            key: "Green's Beverages - Ponce De Leon",
            address: '737 Ponce De Leon Ave NE, Atlanta, GA 30306',
          },
          {
            name: 'Loganville Package Store',
            key: 'Loganville Package Store',
            address: '3967 Atlanta Hwy, Loganville, GA 30052',
          },
          {
            name: "Mac's Beer & Wine",
            key: "Mac's Beer & Wine",
            address: '21 Peachtree Pl NW, Atlanta, GA 30309',
          },
          {
            name: 'Beverage World Package Store',
            key: 'Beverage World Package Store',
            address: '6731 Peachtree Industrial Blvd, Atlanta, GA 30360',
          },
          {
            name: 'Northside Drive Liquor Store',
            key: 'Northside Drive Liquor Store',
            address: '1341 Northside Dr NW, Atlanta, GA 30318',
          },
          {
            name: 'Moreland Package Store',
            key: 'Moreland Package Store',
            address: '15 Moreland Ave SE, Atlanta, GA 30316',
          },
          {
            name: 'Royal Package Liquor Store',
            key: 'Royal Package Liquor Store',
            address: '5325 Roswell Rd, Atlanta, GA 30342',
          },
          {
            name: 'Citi Wine & Spirits',
            key: 'Citi Wine & Spirits',
            address: '5861 Roswell Rd, Sandy Springs, GA 30328',
          },
          {
            name: 'The Juice Box',
            key: 'The Juice Box',
            address: '6300 Powers Ferry Rd NW #500, Atlanta, GA 30339',
          },
          {
            name: 'Total Wine & More',
            key: 'Total Wine & More',
            address: 'Perimeter Square Center, 124 Perimeter Center W, Atlanta, GA 30346',
          },
          {
            name: 'SOIREE Wine & Spirits',
            key: 'SOIREE Wine & Spirits',
            address: '1100 Abernathy Rd NE #361, Atlanta, GA 30328',
          },
          {
            name: 'Parmans Fine Wines & Spirits',
            key: 'Parmans Fine Wines & Spirits',
            address: '6623 Roswell Rd A-B, Sandy Springs, GA 30328',
          },
          {
            name: "Buckhead's Best Wine & Spirits",
            key: "Buckhead's Best Wine & Spirits",
            address: '3400 Northside Pkwy NW, Atlanta, GA 30327',
          },
          {
            name: 'Holeman & Finch Bottle Shop',
            key: 'Holeman & Finch Bottle Shop',
            address: '2357 Peachtree Rd, Atlanta, GA 30305',
          },
          {
            name: "Rocky's Package Store",
            key: "Rocky's Package Store",
            address: '2088 Briarcliff Rd NE #6, Atlanta, GA 30329',
          },
          {
            name: "Sonny's Package Store",
            key: "Sonny's Package Store",
            address: '2251 Marietta Blvd NW, Atlanta, GA 30318',
          },
          {
            name: 'Capital City Wine & Spirits',
            key: 'Capital City Wine & Spirits',
            address: '784 Collier Rd NW, Atlanta, GA 30318',
          },
        ]}
      />
    </Fragment>
  )
}

export default RetailLocations
