import React from 'react'
import { useSelector } from 'react-redux'
import formMap from './formMap'
import CenterContainer from '../../components/CenterContainer'

const Nutrition = () => {
  const mapName = useSelector(({ form: { mapName } }) => mapName)
  const Component = formMap[mapName]
  return <CenterContainer>{Component && <Component />}</CenterContainer>
}

export default Nutrition
