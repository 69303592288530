import evenflo1 from '../../assets/evenflo/page_01.png'
import evenflo2 from '../../assets/evenflo/page_02.png'
import evenflo3 from '../../assets/evenflo/page_03.png'
import evenflo4 from '../../assets/evenflo/page_04.png'
import evenflo5 from '../../assets/evenflo/page_05.png'
import evenflo6 from '../../assets/evenflo/page_06.png'
import evenflo7 from '../../assets/evenflo/page_07.png'
import evenflo8 from '../../assets/evenflo/page_08.png'
import evenflo9 from '../../assets/evenflo/page_09.png'
import evenflo10 from '../../assets/evenflo/page_10.png'

export default {
  evenflo: {
    pages: [evenflo1, evenflo2, evenflo3, evenflo4, evenflo5, evenflo6, evenflo7, evenflo8, evenflo9, evenflo10],
  },
}
