import _find from 'lodash/find'
import _get from 'lodash/get'

export default ({ configList = [], assetData = {}, shouldLog } = {}) => {
  const productId = _get(assetData, 'asset.product.productId', '')
  const batchId = _get(assetData, 'asset.batchId') || _get(assetData, 'asset.batch.batchId') || ''
  const matchingProductList = configList.filter(config => config.productId === productId)
  if (matchingProductList.length === 1 || !batchId) {
    return matchingProductList[0] || {}
  }
  return _find(matchingProductList, { batchId }) || {}
}
