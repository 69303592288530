import React from 'react'
import PostItButton from './PostItButton'

const PostItButtons = ({
  buttons = [],
  width: propsWidth,
  height: propsHeight,
  padding: propsPadding,
  verticalSpacing = '0px',
  fontSize: propsFontSize,
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: verticalSpacing,
    }}
  >
    {buttons.map(
      (
        {
          backgroundColor,
          width = propsWidth,
          height = propsHeight,
          padding = propsPadding,
          name,
          onClick,
          fontSize = propsFontSize,
        },
        i
      ) => (
        <PostItButton
          key={i}
          backgroundColor={backgroundColor}
          width={width}
          height={height}
          onClick={onClick}
          padding={padding}
          fontSize={fontSize}
        >
          {name}
        </PostItButton>
      )
    )}
  </div>
)

export default PostItButtons
