import React, { useEffect, Fragment } from 'react'
import { Button, Input, Collapse } from 'antd'
import useSetState from '../../../hooks/useSetState'
import _capitalize from 'lodash/capitalize'
import _isEmpty from 'lodash/isEmpty'
import { ArrowUpOutlined, ArrowDownOutlined, CloseCircleOutlined } from '@ant-design/icons'
import FontPicker from '../lib/FontPicker'

const AddInput = ({ onChange, index, label: propsLabel, placeholder: propsPlaceholder }) => {
  const [state, setState] = useSetState({ label: propsLabel, placeholder: propsPlaceholder, type: 'input' })
  const { label, placeholder } = state
  useEffect(() => {
    onChange(state, index)
  }, [state]) // eslint-disable-line

  return (
    <div>
      <span>Label text</span>
      <Input value={label} onChange={({ target }) => setState({ label: target.value })} />
      <span>Placeholder text</span>
      <Input value={placeholder} onChange={({ target }) => setState({ placeholder: target.value })} />
    </div>
  )
}

const AddCheckbox = ({ onChange, index, label: propsLabel }) => {
  const [state, setState] = useSetState({ label: propsLabel, type: 'checkbox' })
  const { label } = state
  useEffect(() => {
    onChange(state, index)
  }, [state]) // eslint-disable-line

  return (
    <div>
      <span>Label text</span>
      <Input value={label} onChange={({ target }) => setState({ label: target.value })} />
    </div>
  )
}

const AddSelect = ({ onChange, index, label: propsLabel, placeholder: propsPlaceholder, options: propsOptions }) => {
  const [state, setState] = useSetState({
    label: propsLabel,
    placeholder: propsPlaceholder,
    type: 'select',
    options: propsOptions,
  })
  const { label, placeholder, options } = state
  useEffect(() => {
    onChange(state, index)
  }, [state]) // eslint-disable-line

  const handleOptionChange = (opt, index) => {
    const changedOptions = [...options]
    changedOptions[index] = opt
    setState({ options: changedOptions })
  }

  const handleRemoveOption = index => setState({ options: options.filter((_, i) => i !== index) })

  return (
    <div>
      <span>Label text</span>
      <Input value={label} onChange={({ target }) => setState({ label: target.value })} />
      <span>Placeholder text</span>
      <Input
        value={placeholder}
        onChange={({ target }) => setState({ placeholder: target.value })}
        style={{ marginBottom: '10px' }}
      />
      {options.map((opt, i) => (
        <Input
          key={i}
          value={opt}
          onChange={({ target }) => handleOptionChange(target.value, i)}
          style={{ width: '350px', margin: '5px' }}
          addonAfter={<CloseCircleOutlined onClick={() => handleRemoveOption(i)} style={{ color: '#b5b5b5' }} />}
        />
      ))}
      <Button
        onClick={() => setState({ options: [...options, `Option ${options.length + 1}`] })}
        style={{ marginTop: '10px' }}
        type='link'
      >
        + Option
      </Button>
    </div>
  )
}

const moveItem = (data, from, to) => {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0]
  // insert stored item into position `to`
  data.splice(to, 0, f)
  return data
}

const ItemBuilder = ({ items, setState, labelProps }) => {
  const handleItemChange = (state, index) =>
    setState({
      items: items.map((item, i) => {
        if (i === index) {
          return state
        }
        return item
      }),
    })

  return (
    <Fragment>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <div style={{ fontWeight: 500, marginBottom: '10px' }}>Label Font</div>
        <FontPicker
          fontSizeValue={labelProps.fontSize}
          fontSizeOnChange={fontSize => setState({ labelProps: { ...labelProps, fontSize } })}
          fontWeightValue={labelProps.fontWeight}
          fontWeightOnChange={fontWeight => setState({ labelProps: { ...labelProps, fontWeight } })}
          fontColorValue={labelProps.fontColor}
          fontColorOnChange={fontColor => setState({ labelProps: { ...labelProps, fontColor } })}
          fontFamilyValue={labelProps.fontFamily}
          fontFamilyOnChange={fontFamily => setState({ labelProps: { ...labelProps, fontFamily } })}
        />
      </div>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <div style={{ fontWeight: 500, marginBottom: '10px' }}>Form Items</div>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() => setState({ items: [...items, { type: 'input', label: `Item ${items.length + 1}` }] })}
        >
          + Input
        </Button>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() =>
            setState({
              items: [...items, { type: 'select', label: `Item ${items.length + 1}`, options: ['Option 1'] }],
            })
          }
        >
          + Select
        </Button>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() =>
            setState({
              items: [...items, { type: 'checkbox', label: `Item ${items.length + 1}` }],
            })
          }
        >
          + Checkbox
        </Button>
      </div>
      {!_isEmpty(items) && (
        <Collapse>
          {items.map(({ type, label = '', placeholder = '', options = [] }, i) => {
            return (
              <Collapse.Panel
                key={i}
                header={`${_capitalize(type)} > ${label}`}
                extra={
                  <div onClick={e => e.stopPropagation()}>
                    <ArrowUpOutlined
                      onClick={() => {
                        if (i !== 0) {
                          setState({ items: moveItem(items, i, i - 1) })
                        }
                      }}
                      style={{ fontSize: '1.5em', marginRight: '10px', color: '#b5b5b5' }}
                    />
                    <ArrowDownOutlined
                      onClick={() => {
                        if (i !== items.length - 1) {
                          setState({ items: moveItem(items, i, i + 1) })
                        }
                      }}
                      style={{ fontSize: '1.5em', marginRight: '20px', color: '#b5b5b5' }}
                    />
                    <CloseCircleOutlined
                      onClick={() => setState({ items: items.filter((_, index) => index !== i) })}
                      style={{ fontSize: '1.5em', color: '#b5b5b5' }}
                    />
                  </div>
                }
              >
                {type === 'input' && (
                  <AddInput onChange={handleItemChange} index={i} label={label} placeholder={placeholder} />
                )}
                {type === 'checkbox' && <AddCheckbox onChange={handleItemChange} index={i} label={label} />}
                {type === 'select' && (
                  <AddSelect
                    onChange={handleItemChange}
                    index={i}
                    label={label}
                    placeholder={placeholder}
                    options={options}
                  />
                )}
              </Collapse.Panel>
            )
          })}
        </Collapse>
      )}
    </Fragment>
  )
}

export default ItemBuilder
