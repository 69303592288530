import React, { useEffect, useState, Fragment } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import useSetState from '../../../hooks/useSetState'
import { Input, InputNumber, Checkbox, Switch, Tooltip, Collapse } from 'antd'
import Image from '../lib/Image'
import ProductName from '../../../components/ProductName'
import ProductData from '../../../components/ProductData'
import PostItButton from '../../../components/PostItButton'
import PCATag from '../../../components/PCATag'
import FontPicker from '../lib/FontPicker'
import DataBuilder from '../lib/DataBuilder'
import ButtonBuilder, { buttonActions } from '../lib/ButtonBuilder'
import { findSelectedDataInProduct } from '../lib/utils'
import { useHistory } from 'react-router-dom'
import CustomForm from '../FormBuilder/CustomForm'
import VideoPlaylist from '../lib/VideoPlaylist'
import PictureViewer from '../lib/PictureViewer'
import { useDispatch, useSelector } from 'react-redux'
import { setButtonClickKey, setProductId, setBatchId } from '../../../store/buildTool'
import { videoPropsInitialState } from '../VideoBuilder'
import { picturePropsInitialState } from '../PictureBuilder'
import ImagePicker from '../lib/ImagePicker'
import AdditionalTextBuilder from '../lib/AdditionalTextBuilder'
import { generateRandomString, flattenButtons } from '../lib/utils'
import ActionPage from '../ActionPageBuilder/ActionPage'

const marginBottom = '8px'
const marginLeft = '5px'

const mockAssetData = {
  assetId: '123-456',
  latitude: 34.068,
  longitude: -84.196,
}

const constructInitialState = ({ templateData, configData, productData }) => {
  const hasProductImages = !_isEmpty(_get(productData, 'images'))
  const mergedState = { ...templateData, ...configData }
  const isDefaultUrl = urlField => {
    const templateUrl = _get(templateData, urlField)
    return templateUrl && _get(mergedState, urlField) === templateUrl
  }
  return {
    ...mergedState,
    ...(hasProductImages && isDefaultUrl('productBannerUrl') && { productBannerUrl: undefined }),
    ...(hasProductImages && isDefaultUrl('productImageUrl') && { productImageUrl: undefined }),
  }
}

export const Primary1Form = props => {
  const { templateData, configData, onChange, productData, resetState } = props
  const [isValid, setIsValid] = useState(true)
  const [originalState] = useState(constructInitialState({ templateData, configData, productData }))
  const [state, setState] = useSetState(constructInitialState({ templateData, configData, productData }))

  useEffect(() => {
    onChange(state)
  }, [state]) // eslint-disable-line

  useEffect(() => {
    props.isValid(isValid)
  }, [isValid]) // eslint-disable-line

  useEffect(() => {
    setState(originalState)
  }, [resetState]) // eslint-disable-line

  // TODO: remove this support function for legacy additional text
  useEffect(() => {
    if (!_isEmpty(state.addlText)) {
      setState({
        additionalTextData: [
          ...additionalTextData,
          {
            key: generateRandomString(),
            inputValue: state.addlText,
            fontSizeValue: state.addlTextFontSize,
            fontWeightValue: state.addlTextFontWeight,
            fontColorValue: state.addlTextColor,
          },
        ],
        addlText: '',
      })
    }
  }, []) // eslint-disable-line

  const {
    productBannerUrl,
    productBannerWidth,
    productBannerHeight,
    productImageUrl,
    productImageWidth,
    productImageHeight,
    productNameFontSize,
    productNameColor,
    productNameFontWeight,
    productNameFontFamily,
    enablePCA,
    pcaFontSize,
    pcaFontWeight,
    pcaColor,
    pcaFontFamily,
    pcaValidMessage,
    pcaInvalidMessage,
    buttons,
    selectedData,
    dataFontSize,
    buttonFlip,
    verticalSpacing,
    keepBannerAR,
    keepProductAR,
    additionalTextData,
    enableProductName,
    // batchId, // TODO: REMOVE THIS after back fill
  } = state

  const productImages = _get(productData, 'images', [])

  const sections = [
    {
      component: (
        <div>
          <div style={{ fontWeight: 'bold' }}>Product Banner URL</div>
          <ImagePicker
            images={productImages}
            url={productBannerUrl}
            urlOnChange={productBannerUrl => setState({ productBannerUrl })}
            width={productBannerWidth}
            widthOnChange={productBannerWidth => setState({ productBannerWidth })}
            height={productBannerHeight}
            heightOnChange={productBannerHeight => setState({ productBannerHeight })}
            keepRatio={keepBannerAR}
            keepRatioOnChange={keepBannerAR => setState({ keepBannerAR })}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            resetState={resetState}
          />
          <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Product Image URL</div>
          <ImagePicker
            images={productImages}
            url={productImageUrl}
            urlOnChange={productImageUrl => setState({ productImageUrl })}
            width={productImageWidth}
            widthOnChange={productImageWidth => setState({ productImageWidth })}
            height={productImageHeight}
            heightOnChange={productImageHeight => setState({ productImageHeight })}
            keepRatio={keepProductAR}
            keepRatioOnChange={keepProductAR => setState({ keepProductAR })}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            resetState={resetState}
          />
        </div>
      ),
      name: 'Product Images',
    },
    {
      component: (
        <Fragment>
          <span>Enable:</span>
          <Switch
            checked={enableProductName}
            onChange={enableProductName => setState({ enableProductName })}
            style={{ marginLeft, marginBottom }}
          />
          <FontPicker
            marginBottom={marginBottom}
            fontSizeValue={productNameFontSize}
            fontSizeOnChange={productNameFontSize => setState({ productNameFontSize })}
            fontWeightValue={productNameFontWeight}
            fontWeightOnChange={productNameFontWeight => setState({ productNameFontWeight })}
            fontColorValue={productNameColor}
            fontColorOnChange={productNameColor => setState({ productNameColor })}
            fontFamilyValue={productNameFontFamily}
            fontFamilyOnChange={productNameFontFamily => setState({ productNameFontFamily })}
          />
        </Fragment>
      ),
      name: 'Product Title',
    },
    {
      component: (
        <Fragment>
          <div style={{ marginBottom }}>
            <span>Enable:</span>
            <Switch checked={enablePCA} onChange={enablePCA => setState({ enablePCA })} style={{ marginLeft }} />
            <span style={{ marginLeft }}>Valid (Preview Purpose Only):</span>
            <Checkbox
              disabled={!enablePCA}
              checked={isValid}
              onChange={({ target }) => setIsValid(target.checked)}
              style={{ marginLeft }}
            />
          </div>
          <FontPicker
            marginBottom={marginBottom}
            fontSizeValue={pcaFontSize}
            fontSizeOnChange={pcaFontSize => setState({ pcaFontSize })}
            fontWeightValue={pcaFontWeight}
            fontWeightOnChange={pcaFontWeight => setState({ pcaFontWeight })}
            fontColorValue={pcaColor}
            fontColorOnChange={pcaColor => setState({ pcaColor })}
            disabled={!enablePCA}
            fontFamilyValue={pcaFontFamily}
            fontFamilyOnChange={pcaFontFamily => setState({ pcaFontFamily })}
          />
          <div style={{ marginBottom }}>
            <span>Valid Message</span>
            <Input
              value={pcaValidMessage}
              onChange={({ target }) => setState({ pcaValidMessage: target.value })}
              disabled={!enablePCA}
              style={{ marginBottom: '5px' }}
            />
            <span>Invalid Message</span>
            <Input
              value={pcaInvalidMessage}
              onChange={({ target }) => setState({ pcaInvalidMessage: target.value })}
              disabled={!enablePCA}
            />
          </div>
        </Fragment>
      ),
      name: 'PCA Message',
    },
    {
      component: (
        <AdditionalTextBuilder
          data={additionalTextData}
          setData={additionalTextData => setState({ additionalTextData })}
          marginBottom={marginBottom}
        />
      ),
      name: 'Additional Text',
    },
    {
      component: (
        <Fragment>
          <DataBuilder
            productData={productData}
            selectedRows={selectedData}
            setSelectedRows={selectedData => setState({ selectedData })}
          />
          <div style={{ marginBottom }}>
            <span>Data Font Size:</span>
            <InputNumber
              disabled={_isEmpty(selectedData)}
              value={dataFontSize}
              onChange={dataFontSize => setState({ dataFontSize })}
              style={{ marginLeft }}
            />
          </div>
        </Fragment>
      ),
      name: 'Data Table',
    },
    {
      component: (
        <div style={{ marginBottom: '100px' }}>
          <ButtonBuilder
            buttons={buttons}
            setButtons={buttons => setState({ buttons })}
            buildState={{ ...state, productImages }}
          />
        </div>
      ),
      name: 'Buttons',
    },
    {
      component: (
        <div>
          <div style={{ fontWeight: 'bold' }}>Vertical Spacing</div>
          <InputNumber
            value={verticalSpacing}
            style={{ marginBottom, marginLeft }}
            onChange={verticalSpacing => setState({ verticalSpacing })}
          />
          <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Flip table and buttons</div>
          <Tooltip title={_isEmpty(buttons) ? 'No buttons added' : _isEmpty(selectedData) ? 'No data selected' : ''}>
            <Switch
              checked={buttonFlip}
              onChange={buttonFlip => setState({ buttonFlip })}
              disabled={_isEmpty(buttons) || _isEmpty(selectedData)}
            />
          </Tooltip>
        </div>
      ),
      name: 'General Settings',
    },
  ]

  const [activeKeys, setActiveKeys] = useState([])

  return (
    <div style={{ marginBottom: '40px' }}>
      {/* TODO: REMOVE THIS after back fill */}
      {/* <Label name='Batch ID:' />
      <Input value={batchId} onChange={({ target }) => setState({ batchId: target.value })} /> */}
      <Tooltip title={_isEmpty(activeKeys) ? 'Expand All' : 'Collapse All'}>
        <Switch
          style={{ float: 'right' }}
          checked={!_isEmpty(activeKeys)}
          onClick={() => setActiveKeys(_isEmpty(activeKeys) ? sections.map((_, i) => i) : [])}
        />
      </Tooltip>
      <br />
      <br />
      <Collapse activeKey={activeKeys} onChange={setActiveKeys}>
        {sections.map(({ component, name }, i) => (
          <Collapse.Panel header={<span style={{ fontWeight: '500' }}>{name}</span>} key={i}>
            {component}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  )
}

const DataComp = ({ data, verticalSpacing, fontSize }) =>
  !_isEmpty(data) && (
    <ProductData
      data={data}
      verticalSpacing={verticalSpacing}
      columns={[
        {
          dataIndex: 'title',
          key: 'title',
          render: title => (
            <span style={{ fontWeight: 'bold', color: '#6D6D6D', fontSize: `${fontSize}px` }}>{title}</span>
          ),
        },
        {
          dataIndex: 'value',
          key: 'value',
          render: title => <span style={{ fontSize: `${fontSize}px` }}>{title}</span>,
        },
      ]}
    />
  )

export const ButtonComp = ({ buttons, isPreview, setAction, productId, verticalSpacing, batchId, preventAction }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const connectPageId = useSelector(state => _get(state, 'buildTool.connectPageId', ''))
  const assetId = useSelector(state => _get(state, 'asset.id', ''))
  return (
    !_isEmpty(buttons) && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          margin: `${verticalSpacing} 0`,
        }}
      >
        {buttons.map(
          ({
            backgroundColor,
            width,
            height,
            color,
            fontSize,
            fontWeight,
            fontFamily,
            borderRadius,
            boxShadowBlur,
            boxShadowSpread,
            name,
            action,
            key,
            externalLink,
          }) => (
            <PostItButton
              key={key}
              backgroundColor={backgroundColor}
              width={`${width}px`}
              height={`${height}px`}
              color={color}
              borderRadius={borderRadius}
              boxShadowBlur={boxShadowBlur}
              boxShadowSpread={boxShadowSpread}
              fontSize={`${fontSize}px`}
              fontWeight={fontWeight}
              fontFamily={fontFamily}
              onClick={() => {
                if (preventAction) {
                  return
                }
                if (action === buttonActions.ExternalLink) {
                  const target = isPreview ? '_blank' : '_self'
                  window.open(externalLink, target)
                  return
                }
                dispatch(setButtonClickKey(key))
                dispatch(setProductId(productId))
                dispatch(setBatchId(batchId))
                if (isPreview) {
                  setAction(action)
                } else {
                  history.push(`/tools/page/${action}?connectPageId=${connectPageId}&assetId=${assetId}`)
                }
              }}
            >
              <div style={{ margin: 'auto' }}>{name}</div>
            </PostItButton>
          )
        )}
      </div>
    )
  )
}

const ActionComponent = ({ action, props }) => {
  const { buttonClickKey, configData } = props
  const buttons = flattenButtons(configData)
  const foundBtn = buttons.find(btn => btn.key === buttonClickKey)
  switch (action) {
    case buttonActions.CustomForm: {
      const customFormProps = { ...props, ..._get(foundBtn, 'customForm', {}) }
      return (
        <CustomForm
          {...customFormProps}
          banner={{
            width: _get(configData, 'productBannerWidth', ''),
            height: _get(configData, 'productBannerHeight', ''),
            url: _get(configData, 'productBannerUrl', ''),
          }}
        />
      )
    }
    case buttonActions.VideoPlaylist: {
      const playlist = _get(foundBtn, 'videoPlaylist', [])
      const videoProps = _get(foundBtn, 'videoProps', videoPropsInitialState)
      return <VideoPlaylist playlist={playlist} videoProps={videoProps} />
    }
    case buttonActions.PictureList: {
      const pictureList = _get(foundBtn, 'pictureList', [])
      const pictureProps = _get(foundBtn, 'pictureProps', picturePropsInitialState)
      return <PictureViewer {...props} pictureList={pictureList} pageProps={pictureProps} />
    }
    case buttonActions.PageBuilder: {
      const actionPageProps = {
        items: _get(foundBtn, 'customPageItems', []),
        verticalSpacing: _get(configData, 'verticalSpacing', 10),
        isPreview: true,
        setAction: props.setAction,
        productId: _get(configData, 'productId', ''),
        batchId: _get(configData, 'batchId', ''),
      }
      return <ActionPage {...actionPageProps} />
    }
    default:
      return null
  }
}

export const Primary1Page = ({
  formData = {},
  productData = {},
  isValid,
  isPreview,
  action,
  setAction,
  width,
  height,
}) => {
  const {
    productBannerUrl,
    productBannerWidth,
    productBannerHeight,
    productImageUrl,
    productImageWidth,
    productImageHeight,
    enableProductName,
    productNameFontSize,
    productNameFontWeight,
    productNameColor,
    productNameFontFamily,
    enablePCA,
    pcaFontSize,
    pcaFontWeight,
    pcaColor,
    pcaFontFamily,
    pcaValidMessage,
    pcaInvalidMessage,
    additionalTextData,
    selectedData,
    dataFontSize,
    buttons,
    buttonFlip,
    batchId,
  } = formData
  const { name } = productData
  const foundSelectedData = findSelectedDataInProduct({ productData, selectedData })
  const buttonClickKey = useSelector(state => _get(state, 'buildTool.buttonClickKey', ''))
  const productId = _get(productData, 'productId', '')
  const verticalSpacing = `${formData.verticalSpacing}px`
  const buttonCompProps = {
    productId,
    batchId,
    buttons,
    isPreview,
    setAction,
    verticalSpacing,
  }
  const dataCompProps = {
    data: foundSelectedData,
    verticalSpacing,
    fontSize: dataFontSize,
  }
  const pcaProps = {
    fontSize: `${pcaFontSize}px`,
    fontWeight: `${pcaFontWeight}`,
    color: pcaColor,
    isValid,
    verticalSpacing,
    validMessage: pcaValidMessage,
    invalidMessage: pcaInvalidMessage,
    fontFamily: pcaFontFamily,
  }

  return (
    <Fragment>
      {!_isEmpty(formData) && action && (
        <ActionComponent
          action={action}
          props={{
            configData: formData,
            productData,
            assetData: mockAssetData,
            goBack: () => setAction(null),
            isPreview,
            buttonClickKey,
            width,
            height,
            setAction,
          }}
        />
      )}
      {!_isEmpty(formData) && !action && (
        <div>
          <Image url={productBannerUrl} name='Banner Image' width={productBannerWidth} height={productBannerHeight} />
          <Image
            url={productImageUrl}
            name='Product Image'
            width={productImageWidth}
            height={productImageHeight}
            marginTop={verticalSpacing}
          />
          {enableProductName && (
            <ProductName
              name={name}
              fontSize={`${productNameFontSize}px`}
              color={productNameColor}
              fontWeight={`${productNameFontWeight}`}
              verticalSpacing={verticalSpacing}
              fontFamily={productNameFontFamily}
            />
          )}
          {enablePCA && <PCATag {...pcaProps} showTag={false} />}
          {additionalTextData.map(
            ({ key, inputValue, fontSizeValue, fontWeightValue, fontColorValue, fontFamilyValue }) => (
              <div
                key={key}
                style={{
                  textAlign: 'center',
                  marginTop: verticalSpacing,
                  fontSize: fontSizeValue,
                  fontWeight: fontWeightValue,
                  color: fontColorValue,
                  fontFamily: fontFamilyValue,
                }}
              >
                {inputValue}
              </div>
            )
          )}
          {buttonFlip ? (
            <Fragment>
              <ButtonComp {...buttonCompProps} />
              <DataComp {...dataCompProps} />
            </Fragment>
          ) : (
            <Fragment>
              <DataComp {...dataCompProps} />
              <ButtonComp {...buttonCompProps} />
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  )
}
