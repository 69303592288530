import React from 'react'
import PropTypes from 'prop-types'
import { Table, Divider } from 'antd'
import config from '../config'

const defaultColumns = [
  {
    dataIndex: 'name',
    key: 'name',
    render: name => <strong>{name}</strong>,
  },
  {
    dataIndex: 'description',
    key: 'description',
  },
]

const ProductData = ({ data, columns, verticalSpacing, infoText, infoColor }) => (
  <div style={{ margin: `${verticalSpacing} 0`, textAlign: 'center' }}>
    {infoText && <span style={{ fontWeight: 'bold', color: infoColor }}>{infoText}</span>}
    <Divider style={{ margin: '0' }} />
    <Table
      size='small'
      columns={columns}
      dataSource={data}
      showHeader={false}
      pagination={{ hideOnSinglePage: true }}
      style={{ margin: 'auto', width: '90%' }}
    />
  </div>
)

ProductData.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  infoText: PropTypes.string,
  infoColor: PropTypes.string,
}

ProductData.defaultProps = {
  data: [],
  columns: defaultColumns,
  verticalSpacing: config.verticalSpacing,
  infoText: '',
  infoColor: '#7ac142',
}

export default ProductData
