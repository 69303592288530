import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Collapse, Button, Tooltip } from 'antd'
import { generateRandomString } from '../lib/utils'
import { CloseCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { arrayMove } from 'react-sortable-hoc'
import { initialButtonState, ButtonFormOnly } from '../lib/ButtonBuilder'

const ButtonBuilder = ({ buttons, setButtons, buildState }) => (
  <>
    <div style={{ marginBottom: '10px', textAlign: 'center' }}>
      <Button
        onClick={() =>
          setButtons([
            ...buttons,
            { ...initialButtonState, key: generateRandomString(), name: `Button ${buttons.length + 1}` },
          ])
        }
      >
        + Button
      </Button>
    </div>
    {!_isEmpty(buttons) && (
      <Collapse>
        {buttons.map((button, i) => {
          return (
            <Collapse.Panel
              style={{ backgroundColor: button.backgroundColor }}
              key={button.key}
              header={button.name}
              extra={
                <div onClick={e => e.stopPropagation()}>
                  {i !== 0 && (
                    <Tooltip title='Move Up'>
                      <ArrowUpOutlined
                        style={{ fontSize: '1.5em', color: '#b5b5b5' }}
                        onClick={() => setButtons(arrayMove(buttons, i, i - 1))}
                      />
                    </Tooltip>
                  )}
                  {i !== buttons.length - 1 && (
                    <Tooltip title='Move Down'>
                      <ArrowDownOutlined
                        style={{ fontSize: '1.5em', marginLeft: '10px', color: '#b5b5b5' }}
                        onClick={() => setButtons(arrayMove(buttons, i, i + 1))}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title='Remove Button'>
                    <CloseCircleOutlined
                      style={{ fontSize: '1.5em', marginLeft: '40px', color: '#b5b5b5' }}
                      onClick={() => setButtons(buttons.filter(b => b.key !== button.key))}
                    />
                  </Tooltip>
                </div>
              }
            >
              <ButtonFormOnly
                buildState={buildState}
                state={{ ...initialButtonState, ...button }}
                setState={state =>
                  setButtons(
                    buttons.map(b => {
                      if (b.key === button.key) {
                        return {
                          ...b,
                          ...state,
                        }
                      }
                      return b
                    })
                  )
                }
              />
            </Collapse.Panel>
          )
        })}
      </Collapse>
    )}
  </>
)

export default ButtonBuilder
