import BlueDiamonSupportForm from '../../connect-pages/BlueDiamond/SupportForm'
import ResMedRegisterForm from '../../connect-pages/ResMed/RegisterForm'
import ResMedRequestServiceForm from '../../connect-pages/ResMed/RequestServiceForm'
import PhilipsRegisterForm from '../../connect-pages/Philips/RegisterForm'
import PhilipsRequestServiceForm from '../../connect-pages/Philips/RequestServiceForm'
import BDRegisterForm from '../../connect-pages/BD/RegisterForm'
import BDRequestServiceForm from '../../connect-pages/BD/RequestServiceForm'

export default {
  blueDiamondSupport: BlueDiamonSupportForm,
  resMedRegisterForm: ResMedRegisterForm,
  resMedRequestServiceForm: ResMedRequestServiceForm,
  philipsRegisterForm: PhilipsRegisterForm,
  philipsRequestServiceForm: PhilipsRequestServiceForm,
  bDRegisterForm: BDRegisterForm,
  bDRequestServiceForm: BDRequestServiceForm,
}
