import React from 'react'
import CenterContainer from '../../components/CenterContainer'
import TestComponent from '../../connect-pages/ResMed'

export const testData = {
  asset: {
    assetId: '123',
    latitude: 33.761,
    longitude: -84.375,
    product: {
      name: 'ResMed AirSense 10 CPAP machine',
      propertiesMap: {
        'Catalog Number': '378928',
        'Serial Number': '23171262131',
        'Batch Code': '1223405',
        'Device Number': '53',
      },
    },
  },
}

const Test = ({ isValid, data }) => {
  data = testData
  return (
    <CenterContainer>
      <TestComponent isValid={isValid} data={data} />
    </CenterContainer>
  )
}

export default Test
