import React, { useRef, useState } from 'react'
import { Row, Col, Carousel, Divider } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import _get from 'lodash/get'

// const wait = ms => new Promise(res => setTimeout(() => res(true), ms))
const mobileTypes = ['iphone', 'android']
const marginTop = 20
const arrowMarginTop = '100px'

const determineMobile = () => {
  const userAgent = _get(navigator, 'userAgent', '').toLowerCase()
  return mobileTypes.some(type => userAgent.includes(type))
}

const PictureViewer = ({ pictureList, pageProps }) => {
  const title = _get(pageProps, 'title', '').trim()
  const carouselEl = useRef(null)
  const imageRef = useRef(null)
  // const [descriptionMargin, setDescriptionMargin] = useState(0)
  const [description, setDescription] = useState(_get(pictureList, '[0].description', ''))

  // useEffect(() => {
  //   if (imageRef.current) {
  //     determineDescriptionMargin()
  //   }
  // }, [imageRef]) // eslint-disable-line

  // const determineDescriptionMargin = async (retries = 10) => {
  //   const imageHeight = _get(imageRef, 'current.clientHeight')
  //   const antCol = document.getElementsByClassName('ant-col')[0]
  //   if ((!imageHeight || !antCol) && retries > 0) {
  //     await wait(5)
  //     return determineDescriptionMargin(retries - 1)
  //   }
  //   const colHeight = antCol.clientHeight
  //   const descriptionMargin = colHeight - imageHeight - 20
  //   console.log('colHeight:', colHeight)
  //   console.log('imageHeight:', imageHeight)
  //   setDescriptionMargin(descriptionMargin)
  // }
  const isMobile = determineMobile()

  return (
    <div style={{ height: '100vh' }}>
      {title && (
        <React.Fragment>
          <div style={{ fontWeight: '500', fontSize: '1.3em', textAlign: 'center', marginTop }}>{title}</div>
          <Divider style={{ marginTop, marginBottom: '0' }} />
        </React.Fragment>
      )}
      {description && <div style={{ marginTop, textAlign: 'center', fontWeight: '500' }}>{description}</div>}
      <Row>
        {!isMobile && (
          <Col flex={'5%'}>
            <LeftOutlined style={{ marginTop: arrowMarginTop }} onClick={() => carouselEl.current.prev()} />
          </Col>
        )}
        <Col flex={'100%'} style={{ maxWidth: isMobile ? '100%' : '90%' }}>
          <Carousel
            afterChange={i => setDescription(_get(pictureList, `[${i}].description`, ''))}
            style={{ marginTop }}
            ref={carouselEl}
            dots={false}
          >
            {pictureList
              .filter(picture => !!picture.url)
              .map(({ url, key }) => (
                <img ref={imageRef} key={key} src={url} alt={`${title}-${key}`} />
              ))}
          </Carousel>
        </Col>
        {!isMobile && (
          <Col flex={'5%'}>
            <RightOutlined
              style={{ marginTop: arrowMarginTop, float: 'right' }}
              onClick={() => carouselEl.current.next()}
            />
          </Col>
        )}
      </Row>
      {/* {description && (
        <div style={{ marginTop: `-${descriptionMargin}px`, textAlign: 'center', fontWeight: '500' }}>
          {description}
        </div>
      )} */}
    </div>
  )
}

export default PictureViewer
