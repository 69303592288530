import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

// Import Reducers
import asset from './asset'
import instructions from './instructions'
import nutrition from './nutrition'
import form from './form'
import recipes from './recipes'
import extra from './extra'
import buildTool from './buildTool'

const rootReducer = combineReducers({ asset, buildTool, extra, form, instructions, nutrition, recipes })
const middleWare = applyMiddleware(thunk)

export default () => createStore(rootReducer, composeWithDevTools(middleWare))
