import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import useSetState from '../../../hooks/useSetState'
import PictureList from './PictureList'
import _isEmpty from 'lodash/isEmpty'

export const picturePropsInitialState = { title: '' }

const PictureBuilder = ({ onSave, pictureList: propsPictureList, pageProps: propsPageProps }) => {
  const [visible, setVisible] = useState(false)
  const [pictureList, setPictureList] = useState([])
  const [pageProps, setPageProps] = useSetState(picturePropsInitialState)
  const closeModal = () => setVisible(false)

  useEffect(() => {
    if (!_isEmpty(propsPictureList)) {
      setPictureList(propsPictureList)
    }
  }, [propsPictureList])

  useEffect(() => {
    if (!_isEmpty(propsPageProps)) {
      setPageProps(propsPageProps)
    }
  }, [propsPageProps]) // eslint-disable-line

  return (
    <div>
      <Modal
        closable={false}
        visible={visible}
        onCancel={closeModal}
        onOk={() => {
          onSave(pictureList, pageProps)
          closeModal()
        }}
        okText='Save'
        width={'900px'}
      >
        <PictureList
          pictureList={pictureList}
          setPictureList={setPictureList}
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </Modal>
      <Button type='dashed' onClick={() => setVisible(!visible)}>
        Picture Builder
      </Button>
    </div>
  )
}

export default PictureBuilder
