import React from 'react'
import Logo from '../Logo'
import { Row, Card } from 'antd'
import { useHistory } from 'react-router-dom'
import { blueDiamondRecipeKeys, blueDiamondRecipeMap } from './Recipes'

const blue = 'rgb(5, 99, 193)'

const titleStyle = { fontSize: '1.1em', fontWeight: 'bold', textAlign: 'center', marginTop: '30px', color: blue }
const dinnerRecipes = [
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondChickenStrips],
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAsparagusSpears],
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs],
]
const dipRecipes = [
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAlmondHummus],
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondArtichokeDip],
  blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAvocadoDip],
]

const RecipeRow = ({ recipes }) => {
  const history = useHistory()
  return (
    <Row justify='space-around' style={{ marginTop: '10px' }}>
      {recipes.map(({ img, name, key }) => (
        <Card
          onClick={() => history.push(`/recipes/${key}`)}
          bodyStyle={{ padding: '5px' }}
          style={{ width: '25%' }}
          cover={<img alt={name} src={require(`../../../assets/bd-recipes/${img}`)} />}
          hoverable
          key={key}
        >
          <div style={{ textAlign: 'center', fontWeight: '600', color: blue }}>{name}</div>
        </Card>
      ))}
    </Row>
  )
}

const Recipes = () => {
  return (
    <div>
      <Logo />
      <div style={titleStyle}>Dinner Recipes Using Smokehouse® Nut-Thins</div>
      <RecipeRow recipes={dinnerRecipes} />
      <div style={titleStyle}>Recipes To Dip Your Smokehouse® Nut-Thins Into</div>
      <RecipeRow recipes={dipRecipes} />
    </div>
  )
}

export default Recipes
