import React, { useEffect } from 'react'
import { Input, Button, Divider } from 'antd'
import useSetState from '../../../hooks/useSetState'
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import _isEmpty from 'lodash/isEmpty'

export const generateInitialState = () => ({ url: '', description: '', key: Math.random().toString() })

const Item = ({ initialState, onDelete, onChange, keyValue: key }) => {
  const [state, setState] = useSetState(initialState)

  useEffect(() => {
    onChange(state, key)
  }, [state]) // eslint-disable-line

  const { url, description } = state
  return (
    <div style={{ marginBottom: '10px' }}>
      <Input
        style={{ width: '47%' }}
        prefix={<LinkOutlined />}
        value={url}
        onChange={({ target }) => setState({ url: target.value })}
        placeholder='Picture URL'
      />
      <Input
        style={{ width: '47%', marginLeft: '10px' }}
        value={description}
        onChange={({ target }) => setState({ description: target.value })}
        placeholder='Description (Optional)'
      />
      <DeleteOutlined
        style={{
          float: 'right',
          color: '#b5b5b5',
          fontSize: '1.5em',
          marginTop: '5px',
          marginLeft: '5px',
          cursor: 'pointer',
        }}
        onClick={() => onDelete(key)}
      />
    </div>
  )
}

const PictureList = ({ pictureList, setPictureList, pageProps, setPageProps }) => {
  useEffect(() => {
    if (_isEmpty(pictureList)) {
      setPictureList([generateInitialState()])
    }
  }, []) // eslint-disable-line

  return (
    <div>
      <div>
        <span>Title (Optional)</span>
        <Input value={pageProps.title} onChange={({ target }) => setPageProps({ title: target.value })} />
      </div>
      <Divider />
      {pictureList.map(item => (
        <Item
          initialState={item}
          key={item.key}
          keyValue={item.key}
          onChange={(item, key) =>
            setPictureList(
              pictureList.map(p => {
                if (key === p.key) {
                  return item
                }
                return p
              })
            )
          }
          onDelete={key => setPictureList(pictureList.filter(p => p.key !== key))}
        />
      ))}
      <Button style={{ marginTop: '10px' }} onClick={() => setPictureList([...pictureList, generateInitialState()])}>
        + Picture
      </Button>
    </div>
  )
}

export default PictureList
