import React from 'react'
import PropTypes from 'prop-types'

const ProductLogo = ({ backgroundColor, img, url, height, width }) => {
  const src = url ? url : img ? require(`../assets/${img}`) : ''
  return (
    <div style={{ backgroundColor, width: '100%', height, textAlign: 'center' }}>
      <img src={src} alt='Logo Banner' style={{ width, height }} />
    </div>
  )
}

ProductLogo.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  img: PropTypes.string,
  height: PropTypes.string.isRequired,
}

ProductLogo.defaultProps = {
  img: '',
  backgroundColor: 'inherit',
  height: '75px',
  width: '100%',
}

export default ProductLogo
