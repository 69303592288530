import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import VideoPlaylist from './VideoPlaylist'
import useSetState from '../../../hooks/useSetState'
import _isEmpty from 'lodash/isEmpty'

export const videoPropsInitialState = { selectedColor: '#0071B9' }

const VideoBuilder = ({ onSave, playlist: propsPlaylist, pageProps: propsPageProps }) => {
  const [visible, setVisible] = useState(false)
  const [playlist, setPlaylist] = useState([])
  const [pageProps, setPageProps] = useSetState(videoPropsInitialState)
  const closeModal = () => setVisible(false)

  useEffect(() => {
    if (!_isEmpty(propsPlaylist)) {
      setPlaylist(propsPlaylist)
    }
  }, [propsPlaylist])

  useEffect(() => {
    if (!_isEmpty(propsPageProps)) {
      setPageProps(propsPageProps)
    }
  }, [propsPageProps]) // eslint-disable-line

  return (
    <div>
      <Modal
        closable={false}
        visible={visible}
        onCancel={closeModal}
        onOk={() => {
          onSave(playlist, pageProps)
          closeModal()
        }}
        okText='Save'
        width={'900px'}
      >
        <VideoPlaylist
          playlist={playlist}
          setPlaylist={setPlaylist}
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </Modal>
      <Button type='dashed' onClick={() => setVisible(!visible)}>
        Video Builder
      </Button>
    </div>
  )
}

export default VideoBuilder
