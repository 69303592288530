import React, { useState, useMemo } from 'react'
import { Modal, Button, Radio, Divider } from 'antd'
import ItemBuilder from './ItemBuilder'
import ActionPage from './ActionPage'

const ActionPageBuilder = ({ items, setItems, buildState }) => {
  const [visible, setVisible] = useState(false)
  const [radio, setRadio] = useState('items')
  const originalItems = useMemo(() => items, []) // eslint-disable-line

  return (
    <div style={{ margin: '10px 0 10px 0' }}>
      <Modal
        closable={false}
        visible={visible}
        onCancel={() => {
          setItems(originalItems)
          setVisible(false)
        }}
        onOk={() => setVisible(false)}
        okText='Save'
        width={'900px'}
      >
        <div style={{ textAlign: 'center' }}>
          <Radio.Group buttonStyle='solid' value={radio} onChange={({ target }) => setRadio(target.value)}>
            <Radio.Button value='items'>Items</Radio.Button>
            <Radio.Button value='preview'>Preview</Radio.Button>
          </Radio.Group>
        </div>
        <Divider />
        {radio === 'items' && <ItemBuilder items={items} setItems={setItems} buildState={buildState} />}
        {radio === 'preview' && (
          <ActionPage
            items={items}
            verticalSpacing={buildState?.verticalSpacing}
            isPreview={true}
            preventAction={true}
          />
        )}
      </Modal>
      <Button type='dashed' onClick={() => setVisible(!visible)}>
        Custom Page Builder
      </Button>
    </div>
  )
}

export default ActionPageBuilder
