import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Consumer from './Consumer'
import Logistics from './Logistics'
import NotFound from './NotFound'
import Test from './Test'
import Instructions from './Instructions'
import extractSearchParams from '../utils/extractSearchParams'
import Nutrition from './Nutrition'
import Form from './Form'
import Recipes from './Recipes'
import SingleRecipe from './Recipes/Recipe'
import Extra from './Extra'
import ToolsBuild from './Tools/Build'
import ToolsLogin from './Tools/Login'
import ToolsPage from './ToolsPage'
import { useSelector } from 'react-redux'
import 'antd/dist/antd.css'

const App = () => {
  const location = useLocation()
  const searchParams = extractSearchParams(location.search)
  const { assetid = '', valid = 'true' } = searchParams
  const isValid = valid === 'true'
  const isAuthenticatedBuildTool = useSelector(state => state.buildTool.isAuthenticated)
  const props = {
    isValid,
    assetId: assetid,
    isPreview: location.pathname === '/tools/build',
  }

  return (
    <Switch>
      <Route exact path='/consumer'>
        <Consumer {...props} />
      </Route>
      <Route exact path='/logistics'>
        <Logistics {...props} />
      </Route>
      <Route exact path='/instructions'>
        <Instructions {...props} />
      </Route>
      <Route exact path='/nutrition'>
        <Nutrition />
      </Route>
      <Route exact path='/form'>
        <Form />
      </Route>
      <Route exact path='/recipes'>
        <Recipes />
      </Route>
      <Route path='/recipes/:recipe'>
        <SingleRecipe />
      </Route>
      <Route exact path='/test'>
        <Test {...props} />
      </Route>
      <Route exact path='/extra'>
        <Extra {...props} />
      </Route>
      <Route exact path='/tools/build'>
        {isAuthenticatedBuildTool ? <ToolsBuild {...props} /> : <ToolsLogin {...props} />}
      </Route>
      <Route exact path='/tools/page/:id'>
        <ToolsPage {...props} />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default App
