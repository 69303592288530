const SET_EXTRA_KEY = 'SET_EXTRA_KEY'

export const setExtraKey = (key = '') => dispatch => dispatch({ type: SET_EXTRA_KEY, key })

const initialState = {
  key: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EXTRA_KEY:
      return { ...state, key: action.key }
    default:
      return state
  }
}
