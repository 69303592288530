import React, { Fragment } from 'react'
import Logo from '../Logo'
import ProductImage from '../../../components/ProductImage'
import ProductData from '../../../components/ProductData'
import ProductName from '../../../components/ProductName'

const verticalSpacing = '10px'
const assetData = [
  { name: 'Brew Date', key: 'Brew Date', description: '10/07/20' },
  { name: 'Delivered on', key: 'Delivered on', description: '	10/11/20' },
  { name: 'Best By Date', key: 'Best By Date', description: '	11/07/20' },
  { name: 'ABV %', key: 'ABV %', description: '5%' },
  { name: 'IBU', key: 'IBU', description: '19.4' },
  { name: 'SRM', key: 'SRM', description: '2.3' },
  { name: 'Distributor', key: 'Distributor', description: "King's Road Distributing" },
  { name: 'Keg #', key: 'Keg #', description: '524' },
]

const LannisterBeer = () => {
  return (
    <Fragment>
      <Logo />
      <ProductImage verticalSpacing={verticalSpacing} img='lannister-product.jpg' height='150px' width='' />
      <ProductName
        verticalSpacing={verticalSpacing}
        name='Lannister Gold 1/2 bbl keg'
        color='#a8343e'
        fontWeight='600'
        fontSize='1.5em'
      />
      <ProductData verticalSpacing={verticalSpacing} data={assetData} />
    </Fragment>
  )
}

export default LannisterBeer
