import React from 'react'
import PropTypes from 'prop-types'

const CenterContainer = ({ children, maxWidth, style: propsStyle = {} }) => {
  const style = { display: 'block', maxWidth, margin: 'auto', height: '100vh', ...propsStyle }
  return <div style={style}>{children}</div>
}

CenterContainer.propTypes = {
  maxWidth: PropTypes.string.isRequired,
}

CenterContainer.defaultProps = {
  maxWidth: '600px',
}

export default CenterContainer
