import React, { useState, useEffect } from 'react'
import { List } from 'antd'

const VideoPlaylist = ({ data, color }) => {
  const [src, setSrc] = useState('')

  useEffect(() => {
    setSrc(data[0].src)
  }, []) // eslint-disable-line

  return (
    <div>
      <iframe
        id='blahh'
        width='100%'
        height='300'
        src={src}
        frameBorder='0'
        allow='autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Instruction Videos'
      ></iframe>
      <div style={{ width: '100%', height: '160px', overflow: 'scroll', cursor: 'pointer' }}>
        <List
          size='small'
          itemLayout='horizontal'
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div
                    onClick={() => setSrc(item.src)}
                    style={{ lineHeight: '50px', color: src === item.src ? color : 'inherit' }}
                  >
                    {item.title}
                  </div>
                }
                avatar={
                  <img
                    onClick={() => setSrc(item.src)}
                    src={require(`../assets/${item.thumb}`)}
                    alt='lkj'
                    style={{ width: '100px' }}
                  />
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  )
}

export default VideoPlaylist
