import CS_06 from '../../assets/jabra_instructions/CS_06.png'
import CS_11 from '../../assets/jabra_instructions/CS_11.png'
import CS_14 from '../../assets/jabra_instructions/CS_14.png'
import CS_15 from '../../assets/jabra_instructions/CS_15.png'

import DA_06 from '../../assets/jabra_instructions/DA_06.png'
import DA_11 from '../../assets/jabra_instructions/DA_11.png'
import DA_14 from '../../assets/jabra_instructions/DA_14.png'
import DA_15 from '../../assets/jabra_instructions/DA_15.png'

import DE_06 from '../../assets/jabra_instructions/DE_06.png'
import DE_11 from '../../assets/jabra_instructions/DE_11.png'
import DE_14 from '../../assets/jabra_instructions/DE_14.png'
import DE_15 from '../../assets/jabra_instructions/DE_15.png'

import EN_06 from '../../assets/jabra_instructions/EN_06.png'
import EN_11 from '../../assets/jabra_instructions/EN_11.png'
import EN_14 from '../../assets/jabra_instructions/EN_14.png'
import EN_15 from '../../assets/jabra_instructions/EN_15.png'

import ES_06 from '../../assets/jabra_instructions/ES_06.png'
import ES_11 from '../../assets/jabra_instructions/ES_11.png'
import ES_14 from '../../assets/jabra_instructions/ES_14.png'
import ES_15 from '../../assets/jabra_instructions/ES_15.png'

import ESMX_06 from '../../assets/jabra_instructions/ESMX_06.png'
import ESMX_11 from '../../assets/jabra_instructions/ESMX_11.png'
import ESMX_14 from '../../assets/jabra_instructions/ESMX_14.png'
import ESMX_15 from '../../assets/jabra_instructions/ESMX_15.png'

import FR_06 from '../../assets/jabra_instructions/FR_06.png'
import FR_11 from '../../assets/jabra_instructions/FR_11.png'
import FR_14 from '../../assets/jabra_instructions/FR_14.png'
import FR_15 from '../../assets/jabra_instructions/FR_15.png'

import FRCA_06 from '../../assets/jabra_instructions/FRCA_06.png'
import FRCA_11 from '../../assets/jabra_instructions/FRCA_11.png'
import FRCA_14 from '../../assets/jabra_instructions/FRCA_14.png'
import FRCA_15 from '../../assets/jabra_instructions/FRCA_15.png'

import IT_06 from '../../assets/jabra_instructions/IT_06.png'
import IT_11 from '../../assets/jabra_instructions/IT_11.png'
import IT_14 from '../../assets/jabra_instructions/IT_14.png'
import IT_15 from '../../assets/jabra_instructions/IT_15.png'

import JA_06 from '../../assets/jabra_instructions/JA_06.png'
import JA_11 from '../../assets/jabra_instructions/JA_11.png'
import JA_14 from '../../assets/jabra_instructions/JA_14.png'
import JA_15 from '../../assets/jabra_instructions/JA_15.png'

import KO_06 from '../../assets/jabra_instructions/KO_06.png'
import KO_11 from '../../assets/jabra_instructions/KO_11.png'
import KO_14 from '../../assets/jabra_instructions/KO_14.png'
import KO_15 from '../../assets/jabra_instructions/KO_15.png'

import NL_06 from '../../assets/jabra_instructions/NL_06.png'
import NL_11 from '../../assets/jabra_instructions/NL_11.png'
import NL_14 from '../../assets/jabra_instructions/NL_14.png'
import NL_15 from '../../assets/jabra_instructions/NL_15.png'

import PL_06 from '../../assets/jabra_instructions/PL_06.png'
import PL_11 from '../../assets/jabra_instructions/PL_11.png'
import PL_14 from '../../assets/jabra_instructions/PL_14.png'
import PL_15 from '../../assets/jabra_instructions/PL_15.png'

import PT_06 from '../../assets/jabra_instructions/PT_06.png'
import PT_11 from '../../assets/jabra_instructions/PT_11.png'
import PT_14 from '../../assets/jabra_instructions/PT_14.png'
import PT_15 from '../../assets/jabra_instructions/PT_15.png'

import PTBR_06 from '../../assets/jabra_instructions/PTBR_06.png'
import PTBR_11 from '../../assets/jabra_instructions/PTBR_11.png'
import PTBR_14 from '../../assets/jabra_instructions/PTBR_14.png'
import PTBR_15 from '../../assets/jabra_instructions/PTBR_15.png'

import RU_06 from '../../assets/jabra_instructions/RU_06.png'
import RU_11 from '../../assets/jabra_instructions/RU_11.png'
import RU_14 from '../../assets/jabra_instructions/RU_14.png'
import RU_15 from '../../assets/jabra_instructions/RU_15.png'

import TR_06 from '../../assets/jabra_instructions/TR_06.png'
import TR_11 from '../../assets/jabra_instructions/TR_11.png'
import TR_14 from '../../assets/jabra_instructions/TR_14.png'
import TR_15 from '../../assets/jabra_instructions/TR_15.png'

import ZHCN_06 from '../../assets/jabra_instructions/ZHCN_06.png'
import ZHCN_11 from '../../assets/jabra_instructions/ZHCN_11.png'
import ZHCN_14 from '../../assets/jabra_instructions/ZHCN_14.png'
import ZHCN_15 from '../../assets/jabra_instructions/ZHCN_15.png'

import ZHTW_06 from '../../assets/jabra_instructions/ZHTW_06.png'
import ZHTW_11 from '../../assets/jabra_instructions/ZHTW_11.png'
import ZHTW_14 from '../../assets/jabra_instructions/ZHTW_14.png'
import ZHTW_15 from '../../assets/jabra_instructions/ZHTW_15.png'

const languageInstructionMap = {
  cs: {
    one: CS_06,
    two: CS_11,
    three: CS_14,
    four: CS_15,
  },
  da: {
    one: DA_06,
    two: DA_11,
    three: DA_14,
    four: DA_15,
  },
  de: {
    one: DE_06,
    two: DE_11,
    three: DE_14,
    four: DE_15,
  },
  en: {
    one: EN_06,
    two: EN_11,
    three: EN_14,
    four: EN_15,
  },
  es: {
    one: ES_06,
    two: ES_11,
    three: ES_14,
    four: ES_15,
  },
  esmx: {
    one: ESMX_06,
    two: ESMX_11,
    three: ESMX_14,
    four: ESMX_15,
  },
  fr: {
    one: FR_06,
    two: FR_11,
    three: FR_14,
    four: FR_15,
  },
  frca: {
    one: FRCA_06,
    two: FRCA_11,
    three: FRCA_14,
    four: FRCA_15,
  },
  it: {
    one: IT_06,
    two: IT_11,
    three: IT_14,
    four: IT_15,
  },
  ja: {
    one: JA_06,
    two: JA_11,
    three: JA_14,
    four: JA_15,
  },
  ko: {
    one: KO_06,
    two: KO_11,
    three: KO_14,
    four: KO_15,
  },
  nl: {
    one: NL_06,
    two: NL_11,
    three: NL_14,
    four: NL_15,
  },
  pl: {
    one: PL_06,
    two: PL_11,
    three: PL_14,
    four: PL_15,
  },
  pt: {
    one: PT_06,
    two: PT_11,
    three: PT_14,
    four: PT_15,
  },
  ptbr: {
    one: PTBR_06,
    two: PTBR_11,
    three: PTBR_14,
    four: PTBR_15,
  },
  ru: {
    one: RU_06,
    two: RU_11,
    three: RU_14,
    four: RU_15,
  },
  tr: {
    one: TR_06,
    two: TR_11,
    three: TR_14,
    four: TR_15,
  },
  zhcn: {
    one: ZHCN_06,
    two: ZHCN_11,
    three: ZHCN_14,
    four: ZHCN_15,
  },
  zhtw: {
    one: ZHTW_06,
    two: ZHTW_11,
    three: ZHTW_14,
    four: ZHTW_15,
  },
}

export default languageInstructionMap
