const languageMap = {
  cs: {
    key: 'cs',
    name: 'Czech',
    native: 'čeština',
  },
  de: {
    key: 'de',
    name: 'German',
    native: 'Deutsch',
  },
  da: {
    key: 'da',
    name: 'Danish',
    native: 'dansk',
  },
  en: {
    key: 'en',
    name: 'English',
    native: 'English',
  },
  es: {
    key: 'es',
    name: 'Spanish',
    native: 'Español',
  },
  esmx: {
    key: 'esmx',
    name: 'Spanish (Mexico)',
    native: 'Español (México)',
  },
  fr: {
    key: 'fr',
    name: 'French',
    native: 'français',
  },
  frca: {
    key: 'frca',
    name: 'French (Canada)',
    native: 'français (Canada)',
  },
  it: {
    key: 'it',
    name: 'Italian',
    native: 'Italiano',
  },
  ja: {
    key: 'ja',
    name: 'Japanese',
    native: '日本語',
  },
  ko: {
    key: 'ko',
    name: 'Korean',
    native: '한국어',
  },
  nl: {
    key: 'nl',
    name: 'Dutch',
    native: 'Nederlands',
  },
  pl: {
    key: 'pl',
    name: 'Polish',
    native: 'język polski',
  },
  pt: {
    key: 'pt',
    name: 'Portuguese',
    native: 'Português',
  },
  ptbr: {
    key: 'ptbr',
    name: 'Portuguese (Brazil)',
    native: 'Português (Brasil)',
  },
  ru: {
    key: 'ru',
    name: 'Russian',
    native: 'русский',
  },
  tr: {
    key: 'tr',
    name: 'Turkish',
    native: 'Türkçe',
  },
  zhcn: {
    key: 'zhcn',
    name: 'Chinese (Simplified)',
    native: '中文 (简体)',
  },
  zhtw: {
    key: 'zhtw',
    name: 'Chinese (Traditional)',
    native: '中文 (簡體)',
  },
}

export const getBrowserLanguage = () => {
  const navLang = navigator.language || 'en'
  return navLang
    .toLowerCase()
    .split('')
    .filter(char => char !== '-')
    .join('')
}

export const getDefaultInstructionLanguage = () => {
  const browserLanguage = getBrowserLanguage()
  return languageMap[browserLanguage] ? browserLanguage : 'en'
}

export default languageMap
