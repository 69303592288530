import React from 'react'
import PropTypes from 'prop-types'
import config from '../config'

const ProductImage = ({ img, url, height, width, verticalSpacing }) => {
  const src = url ? url : img ? require(`../assets/${img}`) : ''
  return (
    <div style={{ textAlign: 'center', marginTop: verticalSpacing }}>
      <img src={src} alt='Product' style={{ height, width }} />
    </div>
  )
}

ProductImage.propTypes = {
  img: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

ProductImage.defaultProps = {
  img: '',
  height: '100px',
  width: '100px',
  verticalSpacing: config.verticalSpacing,
}

export default ProductImage
