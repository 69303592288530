import React, { useState, useEffect, useMemo, useRef } from 'react'
import languageMap, { getDefaultInstructionLanguage } from './languageMap'
import languageInstructionsMap from './languageInstructionsMap'
import languageInstructionMap from './languageInstructionsMap'
import config from '../../config'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Select, Row, Col, Carousel } from 'antd'
import './Jabra.css'
const { Option } = Select

const arrowWidth = 58
const arrowStyle = { fontSize: '2em' }
const arrowColFlex = 0.1

const instructionsMap = { ...languageMap }
Object.keys(languageInstructionsMap).forEach(key => {
  instructionsMap[key].instructions = languageInstructionMap[key]
})

const Instructions = ({ verticalSpacing = config.verticalSpacing }) => {
  const [lang, setLang] = useState('en')
  const [imgWidth, setImgWidth] = useState(395)
  const [imgTop, setImgTop] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (imgWidth <= 350 && imgWidth > 300) {
      setImgTop(5)
    } else if (imgWidth <= 300 && imgWidth > 240) {
      setImgTop(10)
    } else if (imgWidth <= 240) {
      setImgTop(15)
    } else {
      setImgTop(0)
    }
  }, [imgWidth])

  useEffect(() => {
    setLang(getDefaultInstructionLanguage())
    handleImgWidth(containerEl.current.clientWidth)
  }, [])

  const handleImgWidth = bodyWidth => {
    const bodyMinusArrows = bodyWidth - arrowWidth
    if (bodyMinusArrows >= 395) {
      setImgWidth(395)
    } else {
      setImgWidth(bodyMinusArrows)
    }
  }

  window.addEventListener('resize', () => handleImgWidth(containerEl.current.clientWidth))

  const { instructions } = useMemo(() => instructionsMap[lang], [lang])
  const carouselEl = useRef(null)
  const containerEl = useRef(null)

  return (
    <div ref={containerEl}>
      <div style={{ width: '100%', height: '25px' }} />
      <div style={{ textAlign: 'center', padding: '0 32px' }}>
        <div style={{ marginBottom: '5px', fontWeight: '600', fontSize: '1.1em' }}>Select your language</div>
        <Select value={lang} onChange={v => setLang(v)} style={{ width: '100%' }}>
          {Object.values(instructionsMap).map(({ key, native }) => (
            <Option value={key}>{native}</Option>
          ))}
        </Select>
      </div>
      <div style={{ margin: `${verticalSpacing} 0` }}>
        <Row justify='space-around' align='middle'>
          <Col flex={arrowColFlex}>
            <LeftOutlined style={arrowStyle} onClick={() => carouselEl.current.prev()} />
          </Col>
          <Col flex={10}>
            <div style={{ margin: 'auto', width: `${imgWidth}px` }}>
              <Carousel ref={carouselEl} dots={false} beforeChange={(from, to) => setPage(to + 1)}>
                {Object.keys(instructions).map(key => (
                  <div key={key}>
                    <img
                      src={instructions[key]}
                      alt={`page-${key}`}
                      style={{ width: `${imgWidth}px`, marginTop: `${imgTop}px` }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </Col>
          <Col flex={arrowColFlex}>
            <RightOutlined style={arrowStyle} onClick={() => carouselEl.current.next()} />
          </Col>
        </Row>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{`Page ${page}`}</div>
      </div>
    </div>
  )
}

export default Instructions
