import React from 'react'
import Logo from './Logo'
import { Form, Input, Select, Row, message as antdMessage } from 'antd'
import PostItButton from '../../components/PostItButton'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import extractAssetData from '../../utils/extractAssetData'
import { registerAsset } from '../../api'
const { Option } = Select
const { Item } = Form

const marginTop = '20px'
const red = 'rgb(201, 45, 57)'

const SupportForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const data = useSelector(({ asset: { data } }) => data)

  const handleSubmit = async () => {
    await form.validateFields()
    const { fName, lName, email, inquiry, message } = form.getFieldsValue()
    const {
      name: assetName,
      data: assetData,
      assetId,
      location: { latitude, longitude },
    } = extractAssetData({ data, keys: ['Lot Code', 'Best If Used By Date', 'UPC', 'Active Recalls'] })

    const propertiesMap = {
      'User Inquiry': inquiry,
      'User Message': message,
      'Scan Date/Time': new Date().toString(),
      'Scan Lat/Long': `${latitude}, ${longitude}`,
      'Product Name': assetName,
    }

    Object.keys(assetData).forEach(key => {
      const { name, description } = assetData[key]
      propertiesMap[name] = description
    })

    const dataToRegister = { name: `${fName} ${lName}`, email, assetId, propertiesMap }
    console.log('Submitting:', dataToRegister)
    registerAsset(dataToRegister)
    antdMessage.success({
      content: 'Successfully Sent Message!',
      duration: 4,
    })
    return history.goBack()
  }

  return (
    <div>
      <Logo />
      <div style={{ textAlign: 'center', color: red, marginTop }}>
        We have all the information we need about the box of Smokehouse® Nut-Thins in your hands. Let us know a little
        about you and what you are inquiring about - we look forward to connecting with you!
      </div>
      <Form form={form} style={{ margin: `${marginTop} auto 0 auto`, width: '90%' }}>
        <Item name='fName' rules={[{ required: true, message: 'Please enter your first name' }]}>
          <Input placeholder='First Name' />
        </Item>
        <Item name='lName' rules={[{ required: true, message: 'Please enter your last name' }]}>
          <Input placeholder='Last Name' />
        </Item>
        <Item name='email' rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
          <Input placeholder='Email' />
        </Item>
        <Item name='inquiry' rules={[{ required: true, message: 'Please select an inquiry' }]}>
          <Select placeholder='What is your inquiry regarding?'>
            <Option value='Product Praise/Testimonial'>Product Praise/Testimonial</Option>
            <Option value='Product Complaint'>Product Complaint</Option>
            <Option value='Product Inquiry'>Product Inquiry</Option>
            <Option value='Nutrional Inquiry'>Nutrional Inquiry</Option>
            <Option value='Coupon Request'>Coupon Request</Option>
            <Option value='Product Feedback'>Product Feedback</Option>
            <Option value='Where to Locate Product'>Where to Locate Product</Option>
          </Select>
        </Item>
        <Item name='message' rules={[{ required: true, message: 'Please enter a message' }]}>
          <Input placeholder='Your Message' />
        </Item>
      </Form>
      <Row justify='center'>
        <PostItButton backgroundColor={red} onClick={handleSubmit}>
          <span style={{ fontSize: '1.1em' }}>{'Send Message'}</span>
        </PostItButton>
      </Row>
    </div>
  )
}

export default SupportForm
