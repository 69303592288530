import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'
import _truncate from 'lodash/truncate'
import { Button, Collapse, Tooltip } from 'antd'
import { generateRandomString } from '../lib/utils'
import FontPicker from '../lib/FontPicker'
import ImagePicker from '../lib/ImagePicker'
import { CloseCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { arrayMove } from 'react-sortable-hoc'
import ButtonBuilder from './ButtonBuilder'

const determineDisplayText = ({ type, item } = {}) => {
  let prefix = _capitalize(type)
  let displayText = ''
  if (type === 'text') {
    displayText = _get(item, 'inputValue') || ''
  }
  if (type === 'image') {
    displayText = _get(item, 'url') || ''
  }
  if (type === 'buttons') {
    prefix = 'Button Group'
    const buttons = _get(item, 'buttons', [])
    buttons.forEach((button, i) => {
      displayText += _get(button, 'name', '')
      if (i !== buttons.length - 1) {
        displayText += ', '
      }
    })
  }
  return `${prefix} > ${_truncate(displayText, { length: 70 })}`
}

const ItemBuilder = ({ items, setItems, buildState }) => {
  const setField = (key, field, value) =>
    setItems(
      items.map(item => {
        if (item.key === key) {
          if (typeof field === 'object') {
            return {
              ...item,
              ...field,
            }
          } else {
            return {
              ...item,
              [field]: value,
            }
          }
        }
        return item
      })
    )
  const removeItem = key => setItems(items.filter(item => item.key !== key))

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() => setItems([...items, { type: 'text', key: generateRandomString(), inputValue: '' }])}
        >
          + Text
        </Button>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() => setItems([...items, { type: 'image', key: generateRandomString() }])}
        >
          + Image
        </Button>
        <Button
          type='dashed'
          style={{ margin: '5px' }}
          onClick={() => setItems([...items, { type: 'buttons', key: generateRandomString(), buttons: [] }])}
        >
          + Button Group
        </Button>
      </div>
      {!_isEmpty(items) && (
        <Collapse>
          {items.map((item, i) => {
            const { type, key } = item
            return (
              <Collapse.Panel
                key={key}
                header={determineDisplayText({ type, item })}
                extra={
                  <div onClick={e => e.stopPropagation()}>
                    {i !== 0 && (
                      <Tooltip title='Move Up'>
                        <ArrowUpOutlined
                          style={{ fontSize: '1.5em', color: '#b5b5b5' }}
                          onClick={() => setItems(arrayMove(items, i, i - 1))}
                        />
                      </Tooltip>
                    )}
                    {i !== items.length - 1 && (
                      <Tooltip title='Move Down'>
                        <ArrowDownOutlined
                          style={{ fontSize: '1.5em', marginLeft: '10px', color: '#b5b5b5' }}
                          onClick={() => setItems(arrayMove(items, i, i + 1))}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title='Remove Item'>
                      <CloseCircleOutlined
                        style={{ fontSize: '1.5em', marginLeft: '40px', color: '#b5b5b5' }}
                        onClick={() => removeItem(key)}
                      />
                    </Tooltip>
                  </div>
                }
              >
                {type === 'text' && (
                  <FontPicker
                    inputPlaceholder='Add Text'
                    inputValue={item.inputValue}
                    inputOnChange={value => setField(key, 'inputValue', value)}
                    fontSizeValue={item.fontSizeValue}
                    fontSizeOnChange={value => setField(key, 'fontSizeValue', value)}
                    fontWeightValue={item.fontWeightValue}
                    fontWeightOnChange={value => setField(key, 'fontWeightValue', value)}
                    fontColorValue={item.fontColorValue}
                    fontColorOnChange={value => setField(key, 'fontColorValue', value)}
                    fontFamilyValue={item.fontFamilyValue || 'Raleway'}
                    fontFamilyOnChange={value => setField(key, 'fontFamilyValue', value)}
                  />
                )}
                {type === 'image' && (
                  <ImagePicker
                    images={_get(buildState, 'productImages', [])}
                    url={item.url}
                    urlOnChange={value => setField(key, 'url', value)}
                    width={item.width}
                    widthOnChange={value => setField(key, 'width', value)}
                    height={item.height}
                    heightOnChange={(height, { keepRatio, width }) => setField(key, { height, keepRatio, width })}
                    keepRatio={item.keepRatio}
                    keepRatioOnChange={value => setField(key, 'keepRatio', value)}
                  />
                )}
                {type === 'buttons' && (
                  <ButtonBuilder
                    buttons={item.buttons}
                    setButtons={value => setField(key, 'buttons', value)}
                    buildState={buildState}
                  />
                )}
              </Collapse.Panel>
            )
          })}
        </Collapse>
      )}
    </>
  )
}

export default ItemBuilder
