import React from 'react'
import CenterContainer from '../../components/CenterContainer'
import { useParams } from 'react-router-dom'
import recipeMap from './recipeMap'

const Recipe = () => {
  const { recipe = '' } = useParams()
  const Component = recipeMap[recipe]
  return <CenterContainer>{Component && <Component />}</CenterContainer>
}

export default Recipe
