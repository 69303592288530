import React, { Fragment } from 'react'
import { Table } from 'antd'
import greyjoyProduct from '../../../assets/greyjoy-product.jpg'

const imgHeight = '4em'

const Restaurants = () => {
  return (
    <Fragment>
      <iframe
        src='https://www.google.com/maps/d/embed?mid=1SeFIngqExgG0AvS0KQfmAiNJhfTQfDsz'
        width='100%'
        height='300'
        title='Greyjoy Restaurants Locations'
      ></iframe>
      <div style={{ textAlign: 'center', display: 'flex', lineHeight: imgHeight }}>
        <div style={{ color: '#a8343e', fontSize: '1.3em', fontWeight: '600', marginLeft: 'auto', marginRight: '5px' }}>
          Restaurants Serving
        </div>
        <img
          src={greyjoyProduct}
          alt='Greyjoy Beer'
          style={{ height: imgHeight, float: 'left', marginRight: 'auto' }}
        />
      </div>
      <Table
        size='small'
        pagination={{ pageSize: 30, hideOnSinglePage: true }}
        columns={[
          {
            title: 'Location Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: val => <a href={`https://maps.google.com/?q=${val}`}>{val}</a>,
          },
        ]}
        dataSource={[
          {
            name: 'The Vortex Bar & Grill',
            key: 'The Vortex Bar & Grill',
            address: '878 Peachtree St NE, Atlanta, GA 30309',
          },
          {
            name: 'The Porter Beer Bar',
            key: 'The Porter Beer Bar',
            address: '1156 Euclid Ave NE, Atlanta, GA 30307',
          },
          {
            name: 'Northside Tavern',
            key: 'Northside Tavern',
            address: '1058 Howell Mill Rd NW, Atlanta, GA 30318',
          },
          {
            name: 'Ticonderoga Club',
            key: 'Ticonderoga Club',
            address: '99 Krog St NE W, Atlanta, GA 30307',
          },
          {
            name: 'The Highlander',
            key: 'The Highlander',
            address: '931 Monroe Dr NE ste c-101, Atlanta, GA 30308',
          },
          {
            name: 'Battle and Brew',
            key: 'Battle and Brew',
            address: '5920 Roswell Rd a120, Sandy Springs, GA 30328',
          },
          {
            name: 'Ship & Anchor Pub',
            key: 'Ship & Anchor Pub',
            address: 'E361, 4022, 5975 Roswell Rd NE, Sandy Springs, GA 30328',
          },
          {
            name: 'The Brass Tap',
            key: 'The Brass Tap',
            address: '4505 Ashford Dunwoody Rd NE, Atlanta, GA 30346',
          },
        ]}
      />
    </Fragment>
  )
}

export default Restaurants
