import React, { Fragment } from 'react'
import Logo from './Logo'
import ProductImage from '../../components/ProductImage'
import PCATag from '../../components/PCATag'
import ProductData from '../../components/ProductData'
import ProductName from '../../components/ProductName'
import PostItButtons from '../../components/PostItButtons'
import extractAssetData from '../../utils/extractAssetData'
import BD_GIFT_SHOP from '../../assets/bd-gift-shop.png'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setNutritionMapName } from '../../store/nutrition'
import { setFormMapName } from '../../store/form'
import { setRecipesMapName } from '../../store/recipes'

const GiftShopButton = () => (
  <div style={{ color: 'rgb(1, 70, 140)', fontSize: '0.7em' }}>
    <span style={{ lineHeight: '20px' }}>{'BUY MORE @'}</span>
    <img src={BD_GIFT_SHOP} alt='blah' style={{ width: '70px', height: '45px' }} />
  </div>
)

const verticalSpacing = '10px'

const BlueDiamond = ({ data, isValid }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { name: assetName, data: assetData } = extractAssetData({
    data,
    keys: ['Lot Code', 'Best If Used By Date', 'UPC'],
  })

  return (
    <Fragment>
      <Logo />
      <ProductImage img='bd-product.png' height='123px' verticalSpacing={verticalSpacing} />
      <ProductName verticalSpacing={verticalSpacing} name={assetName} color='rgb(196, 14, 21)' />
      <PCATag isValid={isValid} />
      <ProductData
        verticalSpacing={verticalSpacing}
        infoText="This specific product's information:"
        infoColor='rgb(246, 139, 26)'
        data={[...assetData, { name: 'Active Recalls', key: 'Active Recalls', description: 'None' }]}
      />
      <PostItButtons
        buttons={[
          {
            backgroundColor: 'rgb(5, 99, 193)',
            name: 'Recipe and Pairing Ideas',
            onClick: () => {
              dispatch(setRecipesMapName('blueDiamondNutThinsRecipes'))
              history.push('/recipes')
            },
          },
          {
            backgroundColor: 'rgb(246, 139, 26)',
            name: 'Nutrition Information',
            onClick: () => {
              dispatch(setNutritionMapName('blueDiamondNutThins'))
              history.push('/nutrition')
            },
          },
          {
            backgroundColor: 'rgb(153, 210, 242)',
            name: <GiftShopButton />,
            onClick: () => window.open('https://bluediamondstore.com/', '_self'),
          },
          {
            backgroundColor: 'rgb(201, 45, 57)',
            name: 'Customer Support',
            onClick: () => {
              dispatch(setFormMapName('blueDiamondSupport'))
              history.push('/form')
            },
          },
        ]}
        height='90px'
        width='82px'
        padding='0px'
      />
    </Fragment>
  )
}

export default BlueDiamond
