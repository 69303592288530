import React from 'react'
import _noop from 'lodash/noop'
import { Table } from 'antd'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'
import './draggableTable.css'

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />)
const SortableItem = sortableElement(props => <tr style={{ zIndex: '9999' }} {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)

const buildColumns = (columns = []) => [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  ...columns.map(col => ({ ...col, className: 'drag-visible' })),
]

const DraggableTable = ({ data = [], setData = _noop, columns = [], rowKey = 'index', ...props }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(data), oldIndex, newIndex)
        .filter(el => !!el)
        .map((obj, index) => ({ ...obj, index }))
      setData(newData)
    }
  }

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex(x => x.index === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const DraggableContainer = props => (
    <SortableContainer useDragHandle helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  )

  return (
    <Table
      {...props}
      pagination={false}
      columns={buildColumns(columns)}
      dataSource={data}
      rowKey={rowKey}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  )
}

export default DraggableTable
