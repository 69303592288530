import { useState } from 'react'

const useSetState = (initialState = {}) => {
  const [state, setUseState] = useState(initialState)

  const setState = newState =>
    setUseState(prevState => ({
      ...prevState,
      ...newState,
    }))

  return [state, setState]
}

export default useSetState
