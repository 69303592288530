import React from 'react'
import { useSelector } from 'react-redux'
import map from './map'
import CenterContainer from '../../components/CenterContainer'
import { useLocation } from 'react-router-dom'

const Recipes = () => {
  const location = useLocation()
  const queryKey = location.search.split('?key=')[1]
  const key = useSelector(({ extra: { key } }) => key)
  const Component = map[key] || map[queryKey]
  return <CenterContainer>{Component && <Component />}</CenterContainer>
}

export default Recipes
