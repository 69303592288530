import React, { useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Modal, Button, Table, Tooltip } from 'antd'
import { appendKeyIndexToData } from '../lib/utils'
import DraggableTable from './DraggableTable'

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Value',
    dataIndex: 'value',
  },
]

const DataBuilder = ({ productData, selectedRows, setSelectedRows }) => {
  const [visible, setVisible] = useState(false)
  const customDataFields = appendKeyIndexToData(_get(productData, 'propertiesMap.customDataFields', []))
  const hasData = !_isEmpty(customDataFields)

  return (
    <div>
      <Modal footer={null} visible={visible} onCancel={() => setVisible(false)} width='90%'>
        <h2 style={{ textAlign: 'center' }}>Available Data</h2>
        <Table
          size='small'
          columns={columns}
          dataSource={customDataFields}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            onSelect: (record, selected, selectedRows) => setSelectedRows(selectedRows),
            onSelectAll: (selected, selectedRows) => setSelectedRows(selectedRows),
            selectedRowKeys: selectedRows.map(({ key }) => key),
          }}
        />
        <br />
        <h2 style={{ textAlign: 'center' }}>Sort Selected Data</h2>
        <DraggableTable
          data={selectedRows}
          setData={setSelectedRows}
          columns={[
            {
              title: 'Title',
              dataIndex: 'title',
            },
            {
              title: 'Value',
              dataIndex: 'value',
            },
          ]}
        />
      </Modal>
      <Tooltip title={hasData ? null : 'No data available'}>
        <Button style={{ margin: '5px 0' }} type='primary' onClick={() => setVisible(!visible)} disabled={!hasData}>
          Pick Data
        </Button>
      </Tooltip>
    </div>
  )
}

export default DataBuilder
