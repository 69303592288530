import axios from './axios'
import _get from 'lodash/get'

export const fetchAsset = assetId => axios.get(`/assets/${assetId}`)

export const fetchBatchAssets = batchId => axios.get(`/batches/${batchId}/assets`)

export const createAssetAction = ({ assetId, action, latitude = 34.068, longitude = -84.196 }) =>
  axios.post(`/assets/${assetId}/action`, {
    action,
    latitude,
    longitude,
  })

export const registerAsset = ({ name, email, assetId, propertiesMap }) =>
  axios({
    baseURL: 'https://demoapi.locatorx.com/lx-demoapi/api',
    url: '/registrations',
    method: 'post',
    // TODO: add assetId here if this is ever used for production (will only allow 1 registration per user)
    // assetId,
    data: { name, email, ...(propertiesMap && { propertiesMap }) },
  })

const formatConnectPageData = res =>
  _get(res, 'connectPages', []).map(({ connectPageId, name, configMap }) => ({
    ...configMap,
    name,
    id: connectPageId,
  }))

export const fetchBuildConfigs = async () => axios.get('/connectPages').then(formatConnectPageData)

export const createBuildConfig = async configMap =>
  axios.post('/connectPages', { name: configMap.connectPageName, configMap })

export const updateBuildConfig = async configMap => axios.put(`/connectPages/${configMap.id}`, { configMap })

export const deleteBuildConfig = async id => axios.delete(`/connectPages/${id}`)
