import React, { useState } from 'react'
import { Modal, Button, Radio, Divider } from 'antd'
import useSetState from '../../../hooks/useSetState'
import ItemBuilder from './ItemBuilder'
import TextBuilder from './TextBuilder'
import CustomForm from './CustomForm'
import ButtonBuilder from './ButtonBuilder'

const defaultFontProps = {
  fontColor: 'inherit',
  fontSize: 14,
  fontWeight: 400,
  fontFamily: 'Raleway',
}

const initialState = {
  items: [],
  labelProps: defaultFontProps,
  title: 'Form Title',
  titleProps: {
    ...defaultFontProps,
    fontWeight: 600,
  },
  text: 'Form Description',
  textProps: defaultFontProps,
  button: 'Submit',
  buttonProps: {
    ...defaultFontProps,
    boxShadowBlur: 4,
    boxShadowSpread: 0,
    backgroundColor: '',
    width: 100,
    height: 100,
  },
}

const FormBuilder = ({ formState = {}, onSave }) => {
  const [visible, setVisible] = useState(false)
  const [radio, setRadio] = useState('items')
  const [state, setState] = useSetState({
    ...initialState,
    ...formState,
  })

  const { items, labelProps, text, textProps, title, titleProps, button, buttonProps } = state

  const handleSave = () => {
    onSave(state)
    setVisible(false)
  }

  return (
    <div style={{ margin: '10px 0 10px 0' }}>
      <Modal
        closable={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSave}
        okText='Save'
        width={'900px'}
      >
        <div style={{ textAlign: 'center' }}>
          <Radio.Group buttonStyle='solid' value={radio} onChange={({ target }) => setRadio(target.value)}>
            <Radio.Button value='items'>Items</Radio.Button>
            <Radio.Button value='text'>Description</Radio.Button>
            <Radio.Button value='button'>Button</Radio.Button>
            <Radio.Button value='preview'>Preview</Radio.Button>
          </Radio.Group>
        </div>
        <Divider />
        {radio === 'items' && <ItemBuilder setState={setState} items={items} labelProps={labelProps} />}
        {radio === 'text' && (
          <TextBuilder text={text} textProps={textProps} title={title} titleProps={titleProps} setState={setState} />
        )}
        {radio === 'button' && <ButtonBuilder button={button} buttonProps={buttonProps} setState={setState} />}
        {radio === 'preview' && (
          <CustomForm
            items={items}
            labelProps={labelProps}
            text={text}
            textProps={textProps}
            title={title}
            titleProps={titleProps}
            button={button}
            buttonProps={buttonProps}
          />
        )}
      </Modal>
      <Button type='dashed' onClick={() => setVisible(!visible)}>
        Custom Form Builder
      </Button>
    </div>
  )
}

export default FormBuilder
