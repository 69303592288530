import React, { Fragment } from 'react'
import { Input } from 'antd'
import FontPicker from '../lib/FontPicker'

const labelStyle = { fontWeight: 'bold' }

const TextBuilder = ({ setState, text, textProps, title, titleProps }) => (
  <Fragment>
    <div style={labelStyle}>Form Title</div>
    <FontPicker
      inputValue={title}
      inputOnChange={title => setState({ title })}
      fontSizeValue={titleProps.fontSize}
      fontSizeOnChange={fontSize => setState({ titleProps: { ...titleProps, fontSize } })}
      fontWeightValue={titleProps.fontWeight}
      fontWeightOnChange={fontWeight => setState({ titleProps: { ...titleProps, fontWeight } })}
      fontColorValue={titleProps.fontColor}
      fontColorOnChange={fontColor => setState({ titleProps: { ...titleProps, fontColor } })}
      fontFamilyValue={titleProps.fontFamily}
      fontFamilyOnChange={fontFamily => setState({ titleProps: { ...titleProps, fontFamily } })}
    />
    <div style={{ ...labelStyle, marginTop: '20px' }}>Form Description</div>
    <Input.TextArea
      value={text}
      rows={5}
      onChange={({ target }) => setState({ text: target.value })}
      style={{ marginBottom: '10px' }}
    />
    <FontPicker
      fontSizeValue={textProps.fontSize}
      fontSizeOnChange={fontSize => setState({ textProps: { ...textProps, fontSize } })}
      fontWeightValue={textProps.fontWeight}
      fontWeightOnChange={fontWeight => setState({ textProps: { ...textProps, fontWeight } })}
      fontColorValue={textProps.fontColor}
      fontColorOnChange={fontColor => setState({ textProps: { ...textProps, fontColor } })}
      fontFamilyValue={textProps.fontFamily}
      fontFamilyOnChange={fontFamily => setState({ textProps: { ...textProps, fontFamily } })}
    />
  </Fragment>
)

export default TextBuilder
