import React, { Fragment } from 'react'
import Logo from '../Logo'
import ProductImage from '../../../components/ProductImage'
import ProductData from '../../../components/ProductData'
import ProductName from '../../../components/ProductName'
import PostItButtons from '../../../components/PostItButtons'
import { setExtraKey } from '../../../store/extra'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const verticalSpacing = '10px'
const assetData = [
  { name: 'Brew Date', key: 'Brew Date', description: '10/07/20' },
  { name: 'Best By Date', key: 'Best By Date', description: '	11/07/20' },
  { name: 'ABV %', key: 'ABV %', description: '5%' },
  { name: 'Type', key: 'Type', description: 'Lager' },
]

const GreyjoyBeer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <Fragment>
      <Logo />
      <ProductImage verticalSpacing={verticalSpacing} img='greyjoy-beer.png' height='150px' width='' />
      <ProductName
        verticalSpacing={verticalSpacing}
        name='Greyjoy Beer - 12oz longneck'
        fontWeight='600'
        fontSize='1.2em'
      />
      <ProductData verticalSpacing={verticalSpacing} data={assetData} />
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Where to find more Greyjoy Beer:</div>
      <PostItButtons
        verticalSpacing={verticalSpacing}
        fontSize='1.1em'
        buttons={[
          {
            backgroundColor: '#214931',
            name: <div style={{ margin: 'auto' }}>Retail Locations</div>,
            onClick: () => {
              dispatch(setExtraKey('RetailLocations'))
              history.push('/extra?key=RetailLocations')
            },
          },
          {
            backgroundColor: '#a8343e',
            name: <div style={{ margin: 'auto' }}>Restaurants</div>,
            onClick: () => {
              dispatch(setExtraKey('Restaurants'))
              history.push('/extra?key=Restaurants')
            },
          },
        ]}
      />
    </Fragment>
  )
}

export default GreyjoyBeer
