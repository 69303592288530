import React from 'react'

const blue = 'rgb(5, 99, 193)'

export const blueDiamondRecipeKeys = {
  blueDiamondChickenStrips: 'blueDiamondChickenStrips',
  blueDiamondAsparagusSpears: 'blueDiamondAsparagusSpears',
  blueDiamondTurkeyMeatballs: 'blueDiamondTurkeyMeatballs',
  blueDiamondAlmondHummus: 'blueDiamondAlmondHummus',
  blueDiamondArtichokeDip: 'blueDiamondArtichokeDip',
  blueDiamondAvocadoDip: 'blueDiamondAvocadoDip',
}

export const blueDiamondRecipeMap = {
  [blueDiamondRecipeKeys.blueDiamondChickenStrips]: {
    name: 'Nutty Ranch Chicken Strips',
    key: blueDiamondRecipeKeys.blueDiamondChickenStrips,
    img: 'ranchChicken.jpg',
  },
  [blueDiamondRecipeKeys.blueDiamondAsparagusSpears]: {
    name: 'Crunchy Asparagus Spears',
    key: blueDiamondRecipeKeys.blueDiamondAsparagusSpears,
    img: 'crunchyAsparagus.jpg',
  },
  [blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs]: {
    name: 'Sundried Tomato Turkey Meatballs',
    key: blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs,
    img: 'turkeyMeatballs.jpg',
  },
  [blueDiamondRecipeKeys.blueDiamondAlmondHummus]: {
    name: 'Smokehouse Almond Hummus',
    key: blueDiamondRecipeKeys.blueDiamondAlmondHummus,
    img: 'almondHummus.jpg',
  },
  [blueDiamondRecipeKeys.blueDiamondArtichokeDip]: {
    name: 'Artichoke Heart Dip',
    key: blueDiamondRecipeKeys.blueDiamondArtichokeDip,
    img: 'artichokeDip.jpg',
  },
  [blueDiamondRecipeKeys.blueDiamondAvocadoDip]: {
    name: 'Avocado and Bean Dip',
    key: blueDiamondRecipeKeys.blueDiamondAvocadoDip,
    img: 'avocadoDip.jpg',
  },
}

const Recipe = ({ ingredients = [], instructions = [], name = '', img }) => {
  const Image = require(`../../../assets/bd-recipes/${img}`)
  return (
    <div
      style={{
        height: '100vh',
        background: `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), center/100% 100% no-repeat url(${Image})`,
        overflow: 'scroll',
      }}
    >
      <div style={{ color: blue, width: '85%', margin: 'auto', padding: '20px 0' }}>
        <div style={{ fontSize: '1.3em', fontWeight: '600' }}>{name}</div>
        <div style={{ margin: '10px 0 0 30px' }}>
          <ul>
            {ingredients.map((ingredient, i) => (
              <li key={`${i}-ingredient`}>{ingredient}</li>
            ))}
          </ul>
        </div>
        <ul>
          {instructions.map((instruction, i) => (
            <li key={`${i}-instruction`}>{instruction}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const BlueDiamondChickenStrips = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondChickenStrips].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondChickenStrips].img}
    ingredients={[
      '1 (4.25-oz.) box Blue Diamond Smokehouse Nut-Thins',
      '1/4 cup Blue Diamond Smokehouse Almonds, finely chopped',
      '1 (1-oz.) packet dry Ranch Dressing Mix',
      '1/3 cup light ranch dressing',
      '1 tablespoon melted butter',
      '1 1/4 lbs. chicken tenders or 1 1/2-inch cubes boneless, skinless chicken breast',
      'Olive oil cooking spray',
    ]}
    instructions={[
      'Preheat oven to 400°F and spray a baking sheet with nonstick cooking spray. Place Blue Diamond Nut-Thins in a gallon-size bag and fold over the top. Crush with a rolling pin until chips are in tiny pieces. Add Blue Diamond Almonds and dry ranch dressing mix to bag and shake well to mix.',
      'Stir together ranch dressing and butter in another medium bowl. Dip each chicken tender in ranch dressing and shake off excess. Roll in chip mixture, pressing into the surface to coat well; place on prepared baking sheet. Coat with nonstick cooking spray and bake for 10 minutes. Coat with cooking spray again and cook for 10 minutes more. Makes 4 to 6 servings.',
      'Quick tip: Any of the coating mixture that falls off the chicken after cooking is great sprinkled over a tossed green salad or mashed potatoes.',
    ]}
  />
)

const BlueDiamondAsparagusSpears = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAsparagusSpears].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAsparagusSpears].img}
    ingredients={[
      '1 (1 lb.) bunch thin to medium fresh asparagus spears',
      '50 Blue Diamond Smokehouse Nut-Thins',
      '1/4 cup grated Parmesan cheese',
      '1 1/2 teaspoons garlic salt',
      '3/4 teaspoon McCormick Smokehouse Pepper',
      '2 eggs',
      '1 tablespoon spicy brown mustard',
      '1/2 cup flour',
    ]}
    instructions={[
      'Prep time: 20 minutes',
      'Cook time: 15 to 20 minutes',
      'Preheat oven to 425°F and line a baking sheet with foil. Place Blue Diamond Smokehouse Nut-Thins in a medium bag; pound with a meat mallet or use a rolling pin to make coarse crumbs. Place in a shallow dish with Parmesan, garlic salt and pepper. Beat eggs with mustard and place in another shallow dish. Place flour in a third shallow dish. Trim tough ends from asparagus and rinse; roll asparagus in flour until well coated. Dip each in egg, making sure all flour is covered, then place in dish with crumb mixture. Cover with crumbs and press lightly to coat well; place on baking sheet. Coat liberally with cooking spray and cook 15 minutes for thinner and 20 minutes for thicker asparagus, coating with cooking spray several times during cooking. Dust with additional Parmesan cheese, if desired.',
      'Makes 6 appetizer servings.',
    ]}
  />
)

const BlueDiamondTurkeyMeatballs = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs].img}
    ingredients={[
      ' 40 Blue Diamond Smokehouse Nut-Thins',
      '1 (20-oz.) package lean ground turkey',
      '1/2 cup minced red onion',
      '1/3 cup minced sun-dried tomatoes (preferably smoked)',
      '1/3 cup grated Parmesan cheese',
      '1/3 cup marinara sauce',
      '1 tablespoon dried Italian herb seasoning or basil',
      '1 teaspoon garlic salt',
    ]}
    instructions={[
      'Prep time: 15 minutes',
      'Cook time: 25 minutes',
      'Preheat oven to 400°F and spray a baking sheet with nonstick cooking spray. Place Blue Diamond Nut-Thins in a bag and crush with a rolling pin or meat mallet to make fine crumbs. Place in a large bowl with remaining ingredients and mix well with your hands. Shape into 24 meatballs and place on prepared baking sheet. Cook for 25 minutes. Serve over hot cooked pasta with marinara sauce, if desired.',
      'Makes 6 to 8 servings.',
    ]}
  />
)

const BlueDiamondAlmondHummus = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAlmondHummus].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAlmondHummus].img}
    ingredients={[
      '  1 (15-oz.) can garbanzo beans, rinsed and drained',
      '1/2 cup Blue Diamond Smokehouse Almonds, plus extra for topping',
      '1/2 cup fat-free plain yogurt (preferably Greek)',
      '1/3 cup lemon juice',
      '1/4 cup tahini sauce*',
      '1 tsp. ground cumin',
      '2 cloves garlic',
      'Blue Diamond Almond Smokehouse Nut-Thins',
    ]}
    instructions={[
      'Prep time: 15 minutes',
      'Puree all ingredients except Nut-Thins in a food processor until very smooth. Serve with Nut-Thins. Makes 2 3/4 cups.',
      '*The thickness of tahini varies with brands. If tahini is very thick, add a few more tablespoons of yogurt to thin hummus',
    ]}
  />
)

const BlueDiamondArtichokeDip = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondArtichokeDip].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondArtichokeDip].img}
    ingredients={[
      '1 can of artichoke hearts (9 oz.)',
      '2 tablespoons olive oil',
      '1 large onion, chopped',
      '1 10oz. bag frozen spinach - bit size pieces',
      '1 cup dry white wine',
      '4 oz. softened gluten-free cream cheese',
      '2/3 cup shredded mozzarella',
      'Dash of pepper',
      'Blue Diamond Smokehouse Nut-Thins',
    ]}
    instructions={[
      'Cut up artichokes after draining them. In large pot, sauté onions in olive oil until translucent. Add spinach and artichokes and cook about 5 minutes. Add wine, then cream cheese. Placed in 1 quart dish, top with mozzarella bake in preheated oven at 350° for 5 minutes until brown and bubble. Makes 30 servings. Serve with Blue Diamond Smokehouse Nut-Thins.',
    ]}
  />
)

const BlueDiamondAvocadoDip = () => (
  <Recipe
    name={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAvocadoDip].name}
    img={blueDiamondRecipeMap[blueDiamondRecipeKeys.blueDiamondAvocadoDip].img}
    ingredients={[
      '1 (14-ounce) can kidney beans, rinsed and drained, divided',
      '1 (14-ounce) can chickpeas, rinsed and drained, divided',
      '2 avocados, peeled, pitted and diced',
      '1/2 cup finely chopped red onion',
      '1/2 cup slivered almonds, roasted (roasting instructions)',
      '1/4 cup olive oil',
      '3 tablespoons soy-based “sour cream”',
      '1 tablespoon white wine vinegar',
      '1/4 teaspoon salt',
      '1/4 teaspoon pepper',
      'Hot sauce to taste',
    ]}
    instructions={[
      'Combine about half of kidney beans and about half of chickpeas in a food processor or blender, and pulse until mashed but still chunky. Combine the rest of the ingredients with the bean mixture above until well mixed. Serve with Blue Diamond Smokehouse Nut-Thins',
    ]}
  />
)

export const blueDiamondRecipeComponentMap = {
  [blueDiamondRecipeKeys.blueDiamondChickenStrips]: BlueDiamondChickenStrips,
  [blueDiamondRecipeKeys.blueDiamondAsparagusSpears]: BlueDiamondAsparagusSpears,
  [blueDiamondRecipeKeys.blueDiamondTurkeyMeatballs]: BlueDiamondTurkeyMeatballs,
  [blueDiamondRecipeKeys.blueDiamondAlmondHummus]: BlueDiamondAlmondHummus,
  [blueDiamondRecipeKeys.blueDiamondArtichokeDip]: BlueDiamondArtichokeDip,
  [blueDiamondRecipeKeys.blueDiamondAvocadoDip]: BlueDiamondAvocadoDip,
}
