import React from 'react'
import ConnectPage from '../../components/ConnectPageV2'
import RegistrationForm from '../../components/RegistrationForm'
import extractAssetData from '../../utils/extractAssetData'
import { registerAsset } from '../../api'
import { setInstructions } from '../../store/instructions'
import { useDispatch } from 'react-redux'
import _capitalize from 'lodash/capitalize'

const logo = {
  img: 'evenflo-logo.png',
  backgroundColor: '#fff',
  height: '60px',
  width: '150px',
}

const green = '#7ac142'
const teal = '#00b1b0'
const purple = 'rgb(93,46,96)'
const darkGray = 'rgb(70,76,93)'

const Evenflo = ({ data, isValid }) => {
  const dispatch = useDispatch()
  const {
    name: assetName,
    data: assetData,
    assetId,
    location: { latitude, longitude },
  } = extractAssetData({
    data,
    keys: ['Model Number', 'Date of Manufacture', 'Color', 'Intended for sale at:'],
  })
  const props = {
    forms: {
      registration: {
        Form: RegistrationForm,
        buttonStyle: { backgroundColor: teal, border: 'none' },
        onSubmit: ({ name, email }) => {
          const propertiesMap = {
            'Scan Date/Time': new Date().toString(),
            'Scan Lat/Long': `${latitude}, ${longitude}`,
            'Product Name': assetName,
          }
          Object.keys(assetData).forEach(key => {
            const { name, description } = assetData[key]
            propertiesMap[_capitalize(name)] = description
          })
          const dataToRegister = { name, email, assetId, propertiesMap }
          console.log('Registration Submitted:', dataToRegister)
          return registerAsset(dataToRegister)
        },
        description: `We have all the information we need about your ${assetName}. To complete your registration, just submit your name and email and you're all set.`,
      },
    },
    logo,
    image: {
      img: 'evenflo-product.jpg',
      height: '150px',
      width: '150px',
    },
    name: assetName,
    buttons: {
      postIt: true,
      color: { first: purple, second: teal },
      first: {
        name: 'Instructions and Videos',
        key: 'instructions',
        type: 'instructions',
        btnClick: () =>
          dispatch(
            setInstructions({
              logo,
              video: {
                data: [
                  {
                    src: 'https://www.youtube.com/embed/xz2croenFqM',
                    title: 'Install Rear Facing with Seatbelt',
                    thumb: 'evenflo-thumb/install.jpg',
                  },
                  {
                    src: 'https://www.youtube.com/embed/Ni4umv6ADwc',
                    title: 'Install Front-Facing with LATCH',
                    thumb: 'evenflo-thumb/install.jpg',
                  },
                  {
                    src: 'https://www.youtube.com/embed/m2tqdnntXCE',
                    title: 'Install Rear Facing with LATCH',
                    thumb: 'evenflo-thumb/install.jpg',
                  },
                  {
                    src: 'https://www.youtube.com/embed/XozYLNiXc8w',
                    title: 'Install No Back Booster Mode',
                    thumb: 'evenflo-thumb/install.jpg',
                  },
                ],
                color: teal,
              },
              manual: {
                btnText: 'Instruction Manual',
                mapName: 'evenflo',
                postIt: true,
                color: { first: green, second: darkGray },
              },
              support: {
                btnText: 'Book Appointment with Technician',
                link: 'https://evenflo.appointlet.com/',
              },
            })
          ),
      },
      second: {
        name: (
          <span style={{ margin: 'auto' }}>
            Register <br /> Your <br /> Product
          </span>
        ),
        key: 'registration',
        type: 'form',
      },
    },
    details: assetData,
    isValid,
    assetId,
    verticalSpacing: '5px',
    dataText: "Your car seat's information:",
    dataColor: '#7ac142',
  }
  return <ConnectPage {...props} />
}

export default Evenflo
