const SET_FORM = 'SET_FORM'

export const setFormMapName = (mapName = '') => dispatch => dispatch({ type: SET_FORM, mapName })

const initialState = {
  mapName: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM:
      return { ...state, mapName: action.mapName }
    default:
      return state
  }
}
