import templateComponents from '../Tools/templateComponents'
import _find from 'lodash/find'

export const determineTemplateConfig = ({ productId = '', configList = [], batchId = '' } = {}) => {
  const matchingProductList = configList.filter(config => config.productId === productId)
  if (matchingProductList.length === 1 || !batchId) {
    return matchingProductList[0] || {}
  }
  return _find(matchingProductList, { batchId }) || {}
}

export default (configData = {}) => {
  const foundTemplate = templateComponents[configData.templateId]
  return foundTemplate ? foundTemplate.page : null
}
