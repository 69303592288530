import React from 'react'
import PropTypes from 'prop-types'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import config from '../config'

const ProductForm = ({ onSubmit, onClose, title, description, Form, buttonStyle, verticalSpacing }) => (
  <div style={{ margin: `${verticalSpacing} auto`, width: '95%' }}>
    <ArrowLeftOutlined style={{ fontSize: '1.6em', marginBottom: verticalSpacing }} onClick={onClose} />
    <Card title={title} size='small' style={{ textAlign: 'center' }}>
      <p>{description}</p>
    </Card>
    <Form onSubmit={onSubmit} buttonStyle={buttonStyle} />
  </div>
)

ProductForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  form: PropTypes.element.isRequired,
  buttonStyle: PropTypes.object.isRequired,
  verticalSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

ProductForm.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: '',
  description: '',
  form: <div>Product Form</div>,
  buttonStyle: {},
  verticalSpacing: config.verticalSpacing,
}

export default ProductForm
