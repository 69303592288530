import React, { Fragment } from 'react'
import Logo from './Logo'
import { Form, Input, message, Row } from 'antd'
import PostItButton from '../../components/PostItButton'
import { registerAsset } from '../../api'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import extractAssetData from '../../utils/extractAssetData'
import { dataKeys } from './'

const verticalSpacing = '25px'

const RegisterForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const data = useSelector(({ asset: { data } }) => data)

  const handleSubmit = async () => {
    await form.validateFields()
    const { name, number } = form.getFieldsValue()
    const {
      name: assetName,
      data: assetData,
      assetId,
      location: { latitude, longitude },
    } = extractAssetData({ data, keys: dataKeys })

    const propertiesMap = {
      'Phone Number': number,
      'Scan Date/Time': new Date().toString(),
      'Scan Lat/Long': `${latitude}, ${longitude}`,
      'Product Name': assetName,
    }

    Object.keys(assetData).forEach(key => {
      const { name, description } = assetData[key]
      propertiesMap[name] = description
    })

    const dataToRegister = { name, assetId, propertiesMap }
    registerAsset(dataToRegister)

    message.success({
      content: 'Successfully Requested Service!',
      duration: 4,
      style: { marginTop: '87vh' },
    })
    return history.goBack()
  }

  return (
    <Fragment>
      <Logo />
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: verticalSpacing }}>{'Request Service'}</div>
        <div style={{ marginTop: verticalSpacing, padding: '5px' }}>
          {
            'We have all the information we need about your CPAP machine - now just give us your name and phone number and we will contact you within 30 minutes to schedule a service on your ResMed AirSense 10 CPAP machine'
          }
        </div>
      </div>
      <Form form={form} style={{ margin: `${verticalSpacing} auto 0 auto`, width: '95%' }}>
        <Form.Item name='name' rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input placeholder='Name' size='large' />
        </Form.Item>
        <Form.Item name='number' rules={[{ required: true, message: 'Please enter a phone number' }]}>
          <Input placeholder='Phone Number' size='large' />
        </Form.Item>
      </Form>
      <Row justify='center' style={{ marginTop: '20px' }}>
        <PostItButton backgroundColor={'#e41d38'} onClick={handleSubmit}>
          <span style={{ fontSize: '1.3em', margin: 'auto' }}>{'Submit'}</span>
        </PostItButton>
      </Row>
    </Fragment>
  )
}

export default RegisterForm
