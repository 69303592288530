import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

const ProductModal = ({ onClose, onSubmit, footer, open, content, title, bodyStyle, width }) => {
  const props = {
    title,
    onOk: onSubmit,
    onCancel: onClose,
    visible: open,
    centered: true,
    ...(footer !== undefined && { footer }),
    bodyStyle,
    width,
  }
  return <Modal {...props}>{content}</Modal>
}

ProductModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  bodyStyle: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
}

ProductModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  content: <div>Modal Content</div>,
  title: '',
  bodyStyle: { height: '80vh', padding: '0' },
  width: '520px',
}

export default ProductModal
